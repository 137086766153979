/*eslint-disable*/
import React from 'react';
import cogoToast from 'cogo-toast';
import {searchBlog} from 'src/utils/Api';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Page from 'src/components/Page';
import global from 'src/utils/global';
import './blog.css';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: theme.spacing(3),
      overflow: 'hidden'
    },
    boxContainer: {
      marginLeft: '5vw',
      marginRight: '5vw',
      marginBottom: 50
    },
    titleContainer: {
      marginTop: 40
    },
    contentContainer: {
      marginTop: 20
    },
    buttonContainer: {
      marginTop: 40
    },
    avatar: {
      width: 150,
      height: 150
    },
    card: {
      maxWidth: 345,
    },
    media: {
 
      width: "100%"
    },


  }));
const UserViewBlog = () => {
    const params = useParams();
    const [blogData, setBlogData] = React.useState();
    const classes = useStyles();

    React.useEffect(()=>{
      // console.log("slug",params.slug)
        searchBlog({slug:params.slug}).then((res)=>{
            // console.log('res', res)
            setBlogData({title:res.data.title,content:res.data.content,image:res.data.image})
        }).catch((err)=>{
            console.log('catcheerr', err)
        })
    },[])
    function createMarkup(data) {
        return { __html: data };
    }
    return (
            <Page
                className={classes.root}
                title="View Blogs"
            >
                <div className="container">
                    <div className="row" style={{maxWidth:833, display:'block', margin:'auto'}}>
                        {blogData  ?
                        <Card className='blog-card' onClick={()=>{handleCard(item.title,item.id)}}>
                        <CardActionArea>
                          <img className={classes.media} src={`${global.serverUrl}upload/blog/${blogData?.image}`} />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                            {blogData?.title}
                            </Typography>
                            <Typography component="p" dangerouslySetInnerHTML={createMarkup(blogData?.content)}>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                            
                         : <p>Something Went Wrong</p>}

                    </div>
                </div>
            </Page>
    );
}

export default UserViewBlog;
