/* eslint-disable prefer-const */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/* eslint-disable */

import Axios from 'axios';
import cogoToast from 'cogo-toast';
import { id } from 'date-fns/esm/locale';
import global from 'src/utils/global';

Axios.defaults.baseURL = `${global.serverUrl}`;

export async function signUp(userInfo) {
  const DATA = await Axios.post('signup', userInfo).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function signIn(userInfo) {
  const DATA = await Axios.post('signin', userInfo).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function adminSignIn(userInfo) {
  const DATA = await Axios.post('adminsignin', userInfo).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function getUserData() {
  const DATA = await Axios.post('getuserdata').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function toggleUserStateApi(data) {
  const DATA = await Axios.post('toggleUserStatus', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function getTransactionData() {
  const DATA = await Axios.post('gettransactions').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function resendVerifyCode(data) {
  const DATA = await Axios.post('resendverifycode', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function getContactData() {
  const DATA = await Axios.post('getcontactdata').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function emailVerify(data) {
  const DATA = await Axios.post('emailverify', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function getNewContactData() {
  const DATA = await Axios.post('getnewcontactdata').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function getDashboardInfo() {
  const DATA = await Axios.post('getDashboardInfo').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

export async function newQuiz(data) {
  const DATA = await Axios.post('newquiz', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data;
  }).catch((err) => {
    cogoToast.warn(err, { position: 'bottom-right' });
  });
  return DATA;
}
export async function getQuizById(id) {
  const DATA = await Axios.post('getbyid', id).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}
export async function getQuizList(id) {
  const DATA = await Axios.post('getquizlist', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getQuizPageApi(query) {
  console.log(query);
  const DATA = await Axios.post('getquizpage', query).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function searchQuizApi(query) {
  console.log(query);
  const DATA = await Axios.post('searchquiz', query).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function searchColApi(query) {
  console.log(query);
  const DATA = await Axios.post('searchcol', query).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function updateQuizData(data) {
  const DATA = await Axios.post('updatequiz', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}
export async function imageUpload(file) {
  const formData = new FormData();
  formData.append('file', file);
  const res = await Axios.post('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    },
  });
  return res;
} 
export async function deleteQuiz(id) {
  const DATA = await Axios.post('deletequiz', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

// =============Collection alalal

export async function newCollection(data) {
  const DATA = await Axios.post('newcollection', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data;
  }).catch((err) => {
    cogoToast.warn(err, { position: 'bottom-right' });
  });
  return DATA;
}

export async function deleteColApi(id) {
  const DATA = await Axios.post('deleteCol', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getCollectionList(id) {
  console.log(id);
  const DATA = await Axios.post('getcollist', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getColPageApi(data) {
  console.log(data);
  const DATA = await Axios.post('getcolpage', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getColById(id) {
  const DATA = await Axios.post('getcolbyid', id).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}
export async function updateColQuiz(data) {
  const DATA = await Axios.post('updatequizlist', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data.data;
  });
  return DATA;
}
export async function updateColData(data) {
  const DATA = await Axios.post('updatecol', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data.data;
  });
  return DATA;
}

export async function sendContactAnswer(data) {
  const DATA = await Axios.post('sendcontactanswer', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data.data;
  });
  return DATA;
}
// ============= Class sectioin
export async function newClass(data) {
  const DATA = await Axios.post('newclass', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data;
  }).catch((err) => {
    cogoToast.warn(err, { position: 'bottom-right' });
  });
  return DATA;
}

export async function deleteClass(id) {
  const DATA = await Axios.post('deleteclass', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getClassList(id) {
  const DATA = await Axios.post('getclasslist', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getClassListAll(id) {
  const DATA = await Axios.post('getclasslistall', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function getClassById(id) {
  const DATA = await Axios.post('getclassbyid', id).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}
export async function updateClassData(data) {
  const DATA = await Axios.post('updateclass', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    cogoToast.warn('Add Failed', { position: 'bottom-right' });
    return res.data.data;
  });
  return DATA;
}
export async function getStudentById(id) {
  const DATA = await Axios.post('getstubyid', id).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}
export async function updateProfile(data) {
  const DATA = await Axios.post('updateprofile', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success('Profile updated successfully', { position: 'top-right' });
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}

export async function searchClassApi(data) {
  const DATA = await Axios.post('searchclass', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

//  ======================  chats api

export async function getUsers(data) {
  const DATA = await Axios.post('getusers', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}

export async function getMessageApi(data) {
  const DATA = await Axios.post('getmessage', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}

export async function readMsgApi(data) {
  const DATA = await Axios.post('readmesage', data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}

export async function membershipUpgradeApi(data) {
  const DATA = await Axios.post('membershipUpgrade', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function joinMemApi(data) {
  const DATA = await Axios.post('joinmem', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function requestContact(data) {
  const DATA = await Axios.post('contact', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function playedGame(data) {
  const DATA = await Axios.post('playedgame', data, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function removeUserApi(data) {
  const DATA = await Axios.post('removeuser', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function resetPassword(data) {
  const DATA = await Axios.post('resetPassword', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function sendVcode(data) {
  const DATA = await Axios.post('sendVcode', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function emailVerifyFP(data) {
  const DATA = await Axios.post('emailVerifyFP', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}
// My New Functions
export async function getquizAll(data) {
  const DATA = await Axios.post('get-all-quizes', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}
export async function deletequizAll(id) {
  const DATA = await Axios.post('delete-quize', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}
export async function quizSummary(data) {
  const DATA = await Axios.post('get-quize-summary', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}
export async function quizPlayers(data) {
  const DATA = await Axios.post('get-quize-players', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}
export async function quizQuestions(data) {
  const DATA = await Axios.post('get-quiz-question-info', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

export async function testDownTime(data) {
  const DATA = await Axios.post('test', data, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).then((res) => {
    console.log('axios+++', res)

    return res;
  });
  return DATA;
}
//Admin Latest Usage
export async function quizUsageInfo(data) {
  const DATA = await Axios.post('quiz-usage-info', data, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).then((res) => {

    return res;
  });
  return DATA;
}

//Admin Add Plan
export async function adminPlan(data) {
  const DATA = await Axios.post('add-plan', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success('Add Plan successfully', { position: 'top-right' });
      return res.data.data;
    }
    cogoToast.danger('Server Error Occured', { position: 'top-right' });

    return res.data.data;
  });
  return DATA;
}

//Admin Get Plan

export async function adminGetPlan() {
  const DATA = await Axios.post('get-plans').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

//Admin Edit Plan

export async function adminEditPlan(id) {
  const DATA = await Axios.post('edit-plan', id).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}




//Admin Update Plan

export async function adminUpdatePlan(data) {
  const DATA = await Axios.post('update-plan', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success(' Plan Update successfully', { position: 'top-right' });
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}

//Delete Get Plan

export async function adminDeletePlan(id) {
  const DATA = await Axios.post('delete-plan', id).then((res) => {
    if (res.status === 200) {
      cogoToast.success(' Plan Deleted successfully', { position: 'top-right' });
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

//Cancel Plan

export async function cancelPlan(data) {
  const DATA = await Axios.post('cancel-plan', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success(' Cancel Plan Successfully', { position: 'top-right' });
      return res.data;
    }
    return res.data;
  });

  return DATA;
}

//Check Current User Plan
export async function currentUSerPlan(id) {
  const DATA = await Axios.post('current-user-plan', id).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

//Membership Upgrade By Admin
export async function membershipUpgradeAdmin(data) {
  const DATA = await Axios.post('membership-upgrade-by-admin', data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

//----------------------------Training Videos --------------------------------------- //
//Admin Add Training Video
export async function adminAddTraining(data) {
  const DATA = await Axios.post('add-training', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success('Add Training Video successfully', { position: 'top-right' });
      return res.data;
    }
    cogoToast.danger('Server Error Occured', { position: 'top-right' });

    return res.data;
  });
  return DATA;
}

//Admin Get Training Videos

export async function adminGetTraining() {
  const DATA = await Axios.get('get-traininglist').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

//Admin Get Training Videos For User

export async function adminGetTrainingUser() {
  const DATA = await Axios.get('get-traininglist-user').then((res) => {
    if (res.status === 200) {
      // console.log("reesdse",res.data)
      return res.data;
      
    }
   
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

//Admin Edit Training Videos

export async function adminEditTraining(id) {
  const DATA = await Axios.post('edit-training', id).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    }
    return res.data.data;
  });
  return DATA;
}

//Admin Update Training Vidos

export async function adminUpdateTraining(data) {
  const DATA = await Axios.post('update-training', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success(' Training Video Update successfully', { position: 'top-right' });
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

//Delete Training Video

export async function adminDeleteTraining(data) {
  console.log(data)
  const DATA = await Axios.post('delete-training', data).then((res) => {
    console.log("rerererer:,")
    if (res.status === 200) {
      cogoToast.success(' Training Video Deleted successfully', { position: 'top-right' });
     return res.message;
      
    }
    return res.data;
  });
  return DATA;
}

//---------------------------------BLOGS------------------------//

export async function blogImageUpload(file) {
  const formData = new FormData();
  formData.append('file', file);
  const res = await Axios.post('/blog-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    },
  });
  return res;
} 

//Add Blog 

export async function addBlog(data) {
  const DATA = await Axios.post('add-blog', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success('Added Blog successfully', { position: 'top-right' });
      return res.data;
    }
    cogoToast.danger('Server Error Occured', { position: 'top-right' });

    return res.data;
  });
  return DATA;
}

//get Blog by Id
// export async function getBlogById(id) {
//   const DATA = await Axios.post(`get-blog`,id).then((res) => {
//     if (res.status === 200) {
//       return res.data;
//     }
//     return res.data;
//   });
//   return DATA;
// }


// search blog 
export async function searchBlog(data) {
  const DATA = await Axios.post(`get-blog`,data).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  });
  return DATA;
}

// delete blog 

export async function deleteBlog(id) {
  console.log(id)
  const DATA = await Axios.post(`delete-blog/${id}`).then((res) => {
    // console.log("rerererer:,")
    if (res.status === 200) {
      cogoToast.success(' Blog Deleted successfully', { position: 'top-right' });
      // console.log("resettsfdf vbhdh", res.message)
      return res.message;

    }
    console.log("no response")
    return res.data;
  });
  return DATA;
}

// get all blogs

export async function getBlog() {
  const DATA = await Axios.get('get-allblog').then((res) => {
    // console.log('apiblog', res)
    if (res.status === 200) {
      return res.data;
    }
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
  return DATA;
}

// update Blogs

export async function updateBlog(data) {
  const DATA = await Axios.post('update-blog', data).then((res) => {
    if (res.status === 200) {
      cogoToast.success(' Blog Update successfully', { position: 'top-right' });
      return res.data;
    }
    return res.data;
  });
  return DATA;
}