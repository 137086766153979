import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/brainaly_new_logo-removebg-preview.png"
      {...props}
      style={{ height: '120px' }}
    />
  );
};
export default Logo;
