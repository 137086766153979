/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormHelperText,
    Link,
    TextField,
    Typography,
    makeStyles,
    Card,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    MenuItem,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from 'src/components/Page';
import QuestionSummary from 'src/views/allstudrecords/StudQuestionSummary';
import { getquizAll, deletequizAll } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import {
    Table,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardText,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    Collapse,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    Input,
    TabContent,
    TabPane,
} from 'reactstrap';
import { Delete } from 'react-feather';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    formControl: {
        width: '100%'
    },
}));
const hostContext = React.createContext();
const Studallrecord = (props) => {
    const classes = useStyles();
    const [getquiz, setQuiz] = React.useState([]);
    const [host, setHost] = React.useState({})
    const getData = async () => {
        //Date Format
        function dateFormat(str) {
            var months = {
                '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
                '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
                '11': 'November', '12': 'December',
            }
            var date = new Date(str),
                // day = ("0" + (date.getDate()+ 1)).slice(-2);
                day = ("0" + date.getDate()).slice(-2);

            var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                monthNumber = months[mnth];

            return [day, monthNumber, date.getFullYear(),].join("-");
        }

        //Time Function
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
        // 'date': dateFormat(data['created_at'].split('T')[0]) + tConvert(data['created_at'].split('T')[1].split('.')[0])
        var host_id = JSON.parse(localStorage.getItem('brainaly_user'));
        console.log('host_id', host_id.userId)
        await getquizAll({ id: host_id.userId }).then((res) => {
            console.log(res, 'getQuizz');
            var data = res.data;
            var quiz_filter = data ? data.map(data => ({
                'select': false,
                'id': data['id'],
                'host_id': data['host_id'],
                'quiz_id': data['quiz_id'],
                'game_id': data['game_id'],
                'mode': data['mode'],
                'no_of_players': data['no_of_players'],
                'no_of_question': data['no_of_question'],
                'quize_name': data['quize_name'],
                'host_name': data['host_name'],
                'created_at': dateFormat(data['created_at'].split('T')[0]) + ' ' + tConvert(data['created_at'].split('T')[1].split('.')[0].split(':')[0] + ':' + data['created_at'].split('T')[1].split('.')[0].split(':')[1])

            })) : 'No Quiz'
            console.log("gamelist", quiz_filter);
            localStorage.setItem('host', JSON.stringify(quiz_filter));
            setQuiz([...quiz_filter]);
            var quizz_filter = data ? data.map(data => ({
                'quize_name': data['quize_name'],
                'host_name': data['host_name'],
                'created_at': dateFormat(data['created_at'].split('T')[0]) + ' ' + tConvert(data['created_at'].split('T')[1].split('.')[0].split(':')[0] + ':' + data['created_at'].split('T')[1].split('.')[0].split(':')[1])

            })) : 'No Quiz'
            setHost([...quizz_filter]); //not in used
        }).catch((err) => {
            console.log(err, 'error');
        });
    };

    async function allQuizDelete() {
        var array_ids = [];
        getquiz.forEach(d => {
            if (d.select) {
                array_ids.push(d.id);
            }
        });
        console.log('array_ids', array_ids)
        await deletequizAll({ id: array_ids }).then((res) => {
            console.log(res, 'response');
            alert('Delete Successfully')
            getData();
        }).catch((err) => {
            console.log(err, 'quizdelerror');
        });
    };
    React.useEffect(() => {
        console.log(host, 'intial')
        getData();
    }, [props]);

    return (



        <Page
            className={classes.root}
            title="Student Brainaly Reports"
        >
            <hostContext.Provider value={"Santos"}>
                {/* <QuestionSummary /> */}
            </hostContext.Provider>
            <div width="100%" className="sc-kstrdz sc-hBEYos fZENZC dWjUC" data-functional-selector="report-list__page" height="fit-content">
                <div width="100%" className="sc-kstrdz dOdMcc">

                    <div className="sc-kjNQVE gVhhSJ">
                        <div width="100%" data-functional-selector="reports-list__search-field" className="sc-kstrdz sc-hBEYos bjPvep dWjUC">
                            {/* <div className="sc-eZuTdo BaPLM"><input placeholder="Search" id="search" className="sc-eTLWQi cLqsro" aria-label="Type and search term to find relevant report in the reports table: " dir="auto" value="" /></div> */}
                        </div>
                        <button onClick={() => { allQuizDelete() }} type="button" aria-label="Move a report to trash." data-functional-selector="reports-list__bin" className="sc-iBPRYJ mKjvP sc-hpErZF hVGSDz">
                            <span className="sc-eCssSg cAELPS trash">
                                <svg id="move-to-bin" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                    <path d="M9,24 C9,25.103 9.897,26 11,26 L21,26 C22.103,26 23,25.103 23,24 L23,12 L9,12 L9,24 Z M11,14 L21,14 L21.001,24 L11,24 L11,14 Z M19,9 L25,9 L25,11 L7,11 L7,9 L13,9 L13,7 L19,7 L19,9 Z M13,16 L13,22 L15,22 L15,16 L13,16 Z M17,16 L17,22 L19,22 L19,16 L17,16 Z" style={{ fill: 'rgb(217, 27, 27)' }} />
                                </svg>
                            </span>
                            <span className="sc-fubCfw irPtrP">Trash</span>
                        </button>
                    </div>
                    <div className="sc-kstrdz sc-hBEYos sc-jDEoIF gFMnBB dWjUC kSrRDV">
                        <ul className="sc-eSNMKe gNRSAT sc-fUSoCb kragIp" data-functional-selector="reports-list__result-type" role="tabpanel">
                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                <button type="button" color="#6E6E6E" role="tab" aria-selected="true" data-functional-selector="allReports active" aria-label="Display table with all reports: 16 reports  ." className="sc-iAjKSQ gicwA-d">
                                    <span className="sc-hKizKw ceBZw">
                                        <div className="sc-bYEvPH jafyqc">All ({getquiz ? getquiz.length : ''})</div>
                                    </span>
                                    {/* <span className="sc-dRKXJR dkNZJN"></span> */}
                                </button>
                            </li>
                            {/* <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                        <button type="button" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="liveGame" aria-label="Display table only with live game reports: 16 reports  ." className="sc-iAjKSQ ggwJDg">
                            <span className="sc-hKizKw ceBZw">
                              <div className="sc-bYEvPH jafyqc">Live games ({ getquiz? getquiz.length: ''})</div>
                            </span>
                            <span className="sc-dRKXJR dkNZJN"></span>
                        </button>
                      </li> */}
                        </ul>
                    </div>
                    <div className="table-responsive h-100" data-simplebar>
                        <Table className="table mb-0 ">
                            <thead>
                                <tr>
                                    <th><input type="checkbox"
                                        onChange={e => {
                                            let value = e.target.checked;
                                            setQuiz(
                                                getquiz ? getquiz.map(d => {
                                                    d.select = value;
                                                    return d;
                                                }) : ''
                                            )
                                        }}
                                        className="sc-ieSyQn hfuvEk sc-jaFUVv juCcDs" /></th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Game Mode</th>
                                    <th>No.of Players</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getquiz.length == 0 ? <td className='no-reports'><tr>No Reports</tr></td> : getquiz.map((quiz) => (
                                        <>
                                            <tr key={quiz.id}>
                                                <td><input type="checkbox" checked={quiz.select}
                                                    onChange={e => {
                                                        let value = e.target.checked;
                                                        setQuiz(
                                                            getquiz ? getquiz.map(sd => {
                                                                if (sd.id === quiz.id) {
                                                                    sd.select = value;
                                                                }
                                                                return sd;
                                                            }) : ''
                                                        )
                                                    }}

                                                    className="sc-ieSyQn hfuvEk sc-jaFUVv juCcDs" /></td>
                                                <td style={{ color: "black" }}>
                                                    <RouterLink to={"/student/reports/question_summary/" + quiz.id}>
                                                        {/* <QuestionSummary host={host}/> */}
                                                        {quiz.quize_name}
                                                    </RouterLink>
                                                </td>
                                                <td>{quiz.created_at}</td>
                                                <td>{quiz.mode == 1 ? 'Live' : 'OffLine'}</td>
                                                <td>{quiz.no_of_players}</td>
                                                <Button type='submit' style={{ display: "none" }} color="success" variant="outlined" onClick={() => { DeleteQuiz(quiz.id) }}>Delete</Button>
                                            </tr>
                                        </>
                                    )).reverse()
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Studallrecord;
export { hostContext };

