/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography
} from '@material-ui/core';
import Bounce from 'react-reveal/Bounce';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw'
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 40
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    height: 470,
    minWidth: 150,
    overflow: 'hidden',
  },
  stuContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 100
  }
}));

const VirtualSection = () => {
  const classes = useStyles();
  return (
    <Box
      component="div"
      mt={15}
      className={classes.boxContainer}
      id="best-banner"
    >
<Grid
        container
        spacing={7}
        style={{ justifyContent: 'center' }}
      >
        <Grid
          xl={10}
          lg={12}
          md={12}
          xs={12}
          item
        >
          <Grid xs={12} container direction="column" spacing={2} >
            <Grid item xs>
              <div className="learning-head virtual-head">

                <h3 className="second-box-heading" >Features of Virtual Classroom</h3>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} spacing={2} style={{ display: "flex", flexWrap: 'wrap', justifyContent: "center", marginTop: 30 }} className="virtual">
            
            <Grid item xs={12} sm={4} md={4} lg={3} className="gamified-quizzes" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/duplicate.png" alt="Image" width="100%" fluid />
              </div>
              <Grid item>
                <div className="gamified-quizzes virtual-content">
                  <p className="my-3"><strong>Breakout Rooms </strong></p>
                  Create breakout rooms for small groups or individual study. Provide a space for students to collaborate and engage with each other.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="multiple-choice" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/resolution.png" alt="Image" width="100%" />
              </div>

              <Grid item>
                <div className="multiple-choice virtual-content">
                  <p className="my-3"><strong>Low, medium and high-resolution video options</strong></p>
                  We offer low, medium, and high-resolution video options to suit all levels of WIFI bandwidth. Whether you're looking for a crystal clear HD experience or a lower resolution that won't eat up all your data, we've got you covered.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="remote-instruction" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
              {/* <ScreenShareIcon style={{ fontSize: 96 }} width='100%'/>      */}
              {/* <FontAwesomeIcon style={{ fontSize: 96 }} icon="fa-solid fa-desktop" />
              <FontAwesomeIcon icon={faDesktop} /> */}
              <img src="home/sharing.png" alt="Image" width="100%" />

                     </div>
              <Grid item>
                <div className="remote-instruction virtual-content">
                  <p className="my-3">
                  <strong>Screen share </strong> 
                  </p>
                  Keep students engaged with our user-friendly screen sharing tool.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3}className="remote-instruction" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/polling.png" alt="Image" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction virtual-content">
                  <p className="my-3"><strong>Polling </strong></p>
                  Quick and simple polls can help boost participation from students. By using polls, students can have a say in what topics are covered in class. This can help make learning more relevant and engaging for them.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="gamified-quizzes" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/white-bb.png" alt="Image" width="100%" fluid />
              </div>
              <Grid item>
                <div className="gamified-quizzes virtual-content">
                  <p className="my-3"><strong>Whiteboard </strong></p>
                  Use a whiteboard to annotate slides and highlight important content. This is a great way to engage your audience and make sure that everyone is on the same page.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="multiple-choice" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/videoconference.png" alt="Image" width="100%" />
              </div>

              <Grid item>
                <div className="multiple-choice virtual-content">
                  <p className="my-3"><strong>Multi-user whiteboard for student engagement </strong></p>
                  Our multi-user whiteboards are a great way to engage students in the classroom. By allowing multiple students to collaborate on a single whiteboard, you can create a truly interactive learning environment.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="remote-instruction" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/upload-file.png" alt="Image" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction virtual-content">
                  <p className="my-3"><strong>Easily upload documents </strong> </p>
                  Uploading presentations to Brainaly is quick and easy. You can upload PDFs, Powerpoint, Word, Excel, text, and images with ease.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3}className="remote-instruction" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/feedback.png" alt="Image" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction virtual-content">
                  <p className="my-3"><strong>Public and private chat </strong> </p>
                  Public and private chat is available in Brainaly. This allows users to connect with others who are discussing similar topics, or just chat with colleagues one-on-one.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="gamified-quizzes" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/class.png" alt="Image" width="100%" fluid />
              </div>
              <Grid item>
                <div className="gamified-quizzes virtual-content">
                  <p className="my-3"><strong>Hand-raising feature </strong> </p>
                  A hand-raising feature is a fantastic way to get audience participation. It's a great way to make sure that everyone is engaged and involved in the presentation.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="multiple-choice" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/satisfaction.png" alt="Image" width="100%" />
              </div>

              <Grid item>
                <div className="multiple-choice virtual-content">
                  <p className="my-3"><strong>Emojis to capture student feedback </strong></p>
                  Emojis are a great way to get student feedback. By using emojis, students can express how they feel about a certain topic or issue without feeling shy or embarrassed. This also allows for quick and easy feedback that can be used to improve the classroom experience for everyone involved.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3} className="remote-instruction" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/recording1.png" alt="Image" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction virtual-content">
                  <p className="my-3"><strong>Recordings </strong>  </p>
                  Record sessions without having to install any other software or browser plugins. This makes it easier than ever to create high quality recordings
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3}className="remote-instruction" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                <img src="home/presentation.png" alt="Image" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction virtual-content">
                  <p className="my-3"><strong>Session analytics </strong> </p>
                  Brainaly session analytics can help you determine how effective your classes and meetings are. By analyzing data such as participant engagement and meeting length, you can make changes to improve the overall quality of your sessions.
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VirtualSection;
