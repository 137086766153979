/* eslint-disable */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  AppBar,
  // Badge,
  Button,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Logo from 'src/components/Logo';
import global from 'src/utils/global';
import Dropdown from 'react-bootstrap/Dropdown';
import StoreContext from 'src/context/index';

const useStyles = makeStyles(() => ({
  
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  topBar: {
    paddingLeft: 30,
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    // background: 'linear-gradient(180deg, rgba(246,242,242,1) 10%, rgba(255,255,255,1) 51%, rgba(235,231,231,1) 100%)',
     backgroundColor: '#0C003C', 
    boxShadow: '0px 2px 2px -1px rgba(0,0,0,0.75)'
  },
  linkStyel: {
    color: '#fff',
    fontSize: 22,
    fontWeight: 'bold',
    marginLeft: 20
  }
}));

const TopBar = ({
  
  className,
  onMobileNavOpen,
  ...rest
}) => {


  const myRef = React.useRef(null)
  const scollToRef = React.useRef();
  const { setStore } = React.useContext(StoreContext);


  const classes = useStyles();
  const gotoPlay = () => {
    localStorage.removeItem('brainaly_game');
    window.open(global.gamePageUrl, '_black');
  };
  // const [notifications] = useState([]);

  return (
    <AppBar
      className={clsx(classes.topBar, className)}
      elevation={0}
      {...rest}
      style={{ background: '#0C003C' }}
    >
      <Toolbar        
>
        <a className='logo-img' href="/">
          <Logo />
        </a>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <Button
            style={{ backgroundColor: '#3e1acf' }}
            color="primary"
            onClick={gotoPlay}
            size="large"
            className={clsx(classes.linkStyel, className)}
            variant="contained"
          >
            Play
          </Button>
          <RouterLink
            to="/pricing"
            className={clsx(classes.linkStyel, className)}
          >
            Pricing
          </RouterLink>
          <RouterLink
            // className={clsx(classes.linkStyel, className)}
            to="/"
          >
            <Dropdown style={{ backgroundColor: '#0C003C'}}>
              <Dropdown.Toggle id="dropdown-basic" className={clsx(classes.linkStyel, className)} style={{ backgroundColor: '#0C003C', border: 'none' }}>
                Features
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ backgroundColor: '#0C003C'}}>
                <Dropdown.Item style={{ color: '#ffff'}}  onClick={()=>{setStore({scroll:1})}} >Gamification Software</Dropdown.Item>
                <Dropdown.Item style={{ color: '#ffff'}} onClick={()=>{setStore({scroll:2})}}>Virtual Classroom</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </RouterLink>
          <RouterLink
            className={clsx(classes.linkStyel, className)}
            to="/signup"
          >
            Sign up
          </RouterLink>
          {/* <RouterLink
            className={clsx(classes.linkStyel, className)}
            to="/signin"
          >
            Sign In
          </RouterLink> */}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
