import React from 'react';

// set the defaults
const ProfileContext = React.createContext({
  store: [
  ],
  setData: () => {}
});

export default ProfileContext;
