/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormHelperText,
    Link,
    TextField,
    Typography,
    makeStyles,
    Card,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    MenuItem,
    List,
    Tabs,
    Tab,
    AppBar,
} from '@material-ui/core';
import {
    ShoppingBag as ShoppingBagIcon,
    Users as UsersIcon
} from 'react-feather';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from 'src/components/Page';
import { hostContext } from 'src/views/allrecords/AllRecord';
import cogoToast from 'cogo-toast';
import TeacherGamePlay from 'src/views/allrecords/TeacherGamePlay';
import {
    Table,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardText,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    Collapse,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    Input,
    TabContent,
    TabPane,
    Paper,
} from 'reactstrap';
import { quizSummary, quizPlayers, quizQuestions } from 'src/utils/Api';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    formControl: {
        width: '100%'
    },
}));

const QuestionSummary = (props) => {
    const [summary, setSummary] = React.useState([]);
    const [getsummary, getSummary] = React.useState([]);
    const [time, Set_time] = React.useState();
    const [quiz_id, setQuiz_id] = React.useState();
    const [modalval, setModalval] = React.useState(props.show);

    const [questions, setQuestions] = React.useState([]);
    const [players, setPlayers] = React.useState([]);
    const classes = useStyles();
    const location = useLocation();
    const skvalue = React.useContext(hostContext)
    //destructuring pathname from location
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const setModal = (value) => {
        setModalval(value)
      };
    const hostData = async () => {
        var get_id = splitLocation[4];
        var data = JSON.parse(localStorage.getItem('host'));
        var result = data.filter((item) => {
            return get_id == item['id'] ?? item
        });
        setSummary([...result])
        console.log('filresult', result)
        setQuiz_id(result[0].quiz_id)
    }
    const quiz_summary = async () => {
        //Date Format
        function dateFormat(str) {
            var months = {
                '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
                '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
                '11': 'November', '12': 'December',
            }
            var date = new Date(str),
                // day = ("0" + (date.getDate()+ 1)).slice(-2);
                day = ("0" + date.getDate()).slice(-2);

            var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                monthNumber = months[mnth];

            return [day, monthNumber, date.getFullYear(),].join("-");
        }

        //Time Function
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
        var get_id = splitLocation[4];
        await quizSummary({ id: get_id }).then((res) => {
            var data = res.data;
            Set_time(res.total_time)
            var smry_filter = data ? data.map(smry => ({
                'id': smry['id'],
                'quiz_id': smry['quiz_id'],
                'host_id': smry['host_id'],
                'game_id': smry['game_id'],
                'mode': smry['mode'],
                'no_of_players': smry['no_of_players'],
                'no_of_question': smry['no_of_question'],
                'correct_answer_percentage': smry['correct_answer_percentage'],
                'created_at': dateFormat(smry['created_at'].split('T')[0]) + ' ' + tConvert(smry['created_at'].split('T')[1].split('.')[0].split(':')[0] + ':' + smry['created_at'].split('T')[1].split('.')[0].split(':')[1]),
            })) : ''
            console.log(smry_filter, 'summaryPlayers');
            getSummary([...smry_filter])
        }).catch((err) => {
            console.log(err, 'quizSummaryerror');
        });
    }
    const quiz_players = async () => {
        //Date Format
        function dateFormat(str) {
            var months = {
                '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
                '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
                '11': 'November', '12': 'December',
            }
            var date = new Date(str),
                // day = ("0" + (date.getDate()+ 1)).slice(-2);
                day = ("0" + date.getDate()).slice(-2);

            var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                monthNumber = months[mnth];

            return [day, monthNumber, date.getFullYear(),].join("-");
        }

        //Time Function
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
        var get_id = splitLocation[4]
        await quizPlayers({ id: get_id }).then((res) => {
            var data = res.data;
            var quiz_filter = data ? data.map(players => ({
                'id': players['id'],
                'quiz_id': players['quiz_id'],
                'game_list_id': players['game_list_id'],
                'user_id': players['user_id'],
                'nick_name': players['user_name'],
                'total_correct_answer': players['total_correct_answer'],
                'total_correct_answer_percentage': players['total_correct_answer_percentage'],
                'total_score': players['total_score'],
                'unanswered': players['unanswered'],
                'user_info': players['user_info'],
                'unanswered': players['unanswered'],
                'created_at': dateFormat(players['created_at'].split('T')[0]) + ' ' + tConvert(players['created_at'].split('T')[1].split('.')[0].split(':')[0] + ':' + players['created_at'].split('T')[1].split('.')[0].split(':')[1]),
            })) : ''
            console.log(quiz_filter, 'quizPlayers');
            setPlayers([...quiz_filter])
        }).catch((err) => {
            console.log(err, 'quizPlayerserror');
        });
    }
    const quiz_questions = async () => {
        //Date Format
        function dateFormat(str) {
            var months = {
                '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
                '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
                '11': 'November', '12': 'December',
            }
            var date = new Date(str),
                // day = ("0" + (date.getDate()+ 1)).slice(-2);
                day = ("0" + date.getDate()).slice(-2);

            var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                monthNumber = months[mnth];

            return [day, monthNumber, date.getFullYear(),].join("-");
        }

        //Time Function
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
        var get_id = splitLocation[4]
        await quizQuestions({ id: get_id }).then((res) => {
            var quiz_question = res.data;
            console.log(quiz_question, 'quizQuestions');
            setQuestions([...quiz_question])
        }).catch((err) => {
            console.log(err, 'quizQuestionsserror');
        });
    }
    React.useState(() => {
        console.log(skvalue, 'valueeeee');
        quiz_summary();
        quiz_players();
        quiz_questions();
        hostData();
    }, [])

    return (
        <Page
            className={classes.root}
            title="Teacher Brainaly Summary"
        >
            {/* <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        All Records
      </Box> */}
            <div className="sc-izaQbr gytZrp" id="main-content">
                <div className="sc-gPTkgY jAYNyi">
                    <div className="sc-gPTkgY jAYNyi" aria-hidden="false">
                        <div>
                            <main className="sc-fNFVIZ hxQRHK">
                                <div width="1" className="sc-kstrdz sc-hBEYos gvzVaF dWjUC">
                                    <div className="sc-hTsBph cTvoQZ">
                                        <div className="sc-kstrdz sc-hBEYos fZENZC dWjUC" width="100%" height="fit-content">
                                            <div className="sc-bDCewv hhngOi">
                                                <section className="sc-cFPsUu bztCDK">
                                                    <div className="sc-stgHA gabcHZ">
                                                        <h5 data-functional-selector="report-summary-header__reports-subtitle" className="sc-dzDlLZ fMObBc">Report</h5>
                                                        <h2 data-functional-selector="report-summary-header__reports-title" className="sc-jNwxvY RLUoc">
                                                            <button type="submit" aria-label="Rename report. Current report name. Dosage Review" data-functional-selector="report-summary-header__rename-button" className="sc-iBPRYJ kQjigY sc-hpSTqv fBfcAo">
                                                                <span className="sc-eCssSg dDNlwv dDNlwvone">
                                                                    {/* <svg id="edit-reports-title" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                                    <path
                                                                        d="M23.4091683,8.594 C24.1731683,9.359 24.1731683,10.693 23.4091683,11.458 L22.6901683,12.178 L19.8241683,9.313 L20.5451683,8.594 C21.3101683,7.83 22.6441683,7.829 23.4091683,8.594 L23.4091683,8.594 Z M8.37616832,23.626 L8.97116832,21.252 L10.7501683,23.033 L8.37616832,23.626 Z M18.4111683,10.728 L21.2741683,13.591 L12.7061683,22.159 L9.84316832,19.296 L18.4111683,10.728 Z M24.8211683,7.179 C24.0611683,6.418 23.0501683,6 21.9741683,6 C20.9001683,6 19.8881683,6.418 19.1281683,7.179 L7.71916832,18.587 C7.71916832,18.587 7.71816832,18.59 7.71716832,18.591 C7.63616832,18.673 7.49716832,18.889 7.45616832,19.052 L6.03016832,24.757 C5.94416832,25.097 6.04516832,25.458 6.29316832,25.706 L6.29416832,25.707 L6.29616832,25.708 C6.54416832,25.956 6.90416832,26.057 7.24516832,25.972 L12.9491683,24.545 C13.1121683,24.504 13.3291683,24.365 13.4101683,24.284 C13.4111683,24.283 13.4141683,24.282 13.4141683,24.282 L24.8231683,12.873 C25.5831683,12.113 26.0021683,11.102 26.0011683,10.027 C26.0011683,8.951 25.5831683,7.941 24.8231683,7.18 L24.8211683,7.179 Z"
                                                                        style={{ fill: "rgb(51, 51, 51)"}}
                                                                    ></path>
                                                                </svg> */}
                                                                </span>
                                                            </button>
                                                        </h2>
                                                        {/* <div className="sc-dYzDLp UqdGH">Report options</div> */}
                                                        <div className="sc-kmsPvo cCKVkA">
                                                            <div className="sc-wyIyg iVugHJ onboarding-reports">
                                                                <div className="sc-iRpACI gSFmGa sc-iiqTaX lmMISc" aria-haspopup="true">
                                                                    <button
                                                                        type="button"
                                                                        id="action-menu__toggle-report-action-menu__29ac8a87-2ece-47e7-8877-d1f41014e57e_1637756315167"
                                                                        aria-label="Action menu of Dosage Review report."
                                                                        aria-expanded="false"
                                                                        data-functional-selector="report-action-menu__toggle"
                                                                        className="sc-iBPRYJ mKjvP sc-eYRYCd hRiOnA"
                                                                    >
                                                                        {/* <span className="sc-eCssSg cAELPS cAELPSone" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                                            <svg id="action" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                                                <path
                                                                                    d="M16,10 C17.1045695,10 18,9.1045695 18,8 C18,6.8954305 17.1045695,6 16,6 C14.8954305,6 14,6.8954305 14,8 C14,9.1045695 14.8954305,10 16,10 Z M16,18 C17.1045695,18 18,17.1045695 18,16 C18,14.8954305 17.1045695,14 16,14 C14.8954305,14 14,14.8954305 14,16 C14,17.1045695 14.8954305,18 16,18 Z M16,26 C17.1045695,26 18,25.1045695 18,24 C18,22.8954305 17.1045695,22 16,22 C14.8954305,22 14,22.8954305 14,24 C14,25.1045695 14.8954305,26 16,26 Z"
                                                                                    style={{ fill: "rgb(51, 51, 51)" }}
                                                                                ></path>
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sc-kstrdz sc-hBEYos fMrbYi dWjUC">
                                                        {summary.map((summary) => <>

                                                            <h2 className="sc-jNwxvY bLhQJy">
                                                                {summary.quize_name}
                                                                {/* <button type="button" aria-label="Rename report. Current report name. Dosage Review" data-functional-selector="report-summary-header__rename-button" className="sc-iBPRYJ kQjigY sc-hpSTqv fBfcAo">
                                                                <span className="sc-eCssSg dDNlwv dDNlwvsone">
                                                                <svg id="edit-reports-title" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                    <path
                                                                    d="M23.4091683,8.594 C24.1731683,9.359 24.1731683,10.693 23.4091683,11.458 L22.6901683,12.178 L19.8241683,9.313 L20.5451683,8.594 C21.3101683,7.83 22.6441683,7.829 23.4091683,8.594 L23.4091683,8.594 Z M8.37616832,23.626 L8.97116832,21.252 L10.7501683,23.033 L8.37616832,23.626 Z M18.4111683,10.728 L21.2741683,13.591 L12.7061683,22.159 L9.84316832,19.296 L18.4111683,10.728 Z M24.8211683,7.179 C24.0611683,6.418 23.0501683,6 21.9741683,6 C20.9001683,6 19.8881683,6.418 19.1281683,7.179 L7.71916832,18.587 C7.71916832,18.587 7.71816832,18.59 7.71716832,18.591 C7.63616832,18.673 7.49716832,18.889 7.45616832,19.052 L6.03016832,24.757 C5.94416832,25.097 6.04516832,25.458 6.29316832,25.706 L6.29416832,25.707 L6.29616832,25.708 C6.54416832,25.956 6.90416832,26.057 7.24516832,25.972 L12.9491683,24.545 C13.1121683,24.504 13.3291683,24.365 13.4101683,24.284 C13.4111683,24.283 13.4141683,24.282 13.4141683,24.282 L24.8231683,12.873 C25.5831683,12.113 26.0021683,11.102 26.0011683,10.027 C26.0011683,8.951 25.5831683,7.941 24.8231683,7.18 L24.8211683,7.179 Z"
                                                                    style={{ fill: 'rgb(51, 51, 51)' }}
                                                                    />
                                                                </svg>
                                                                </span>
                                                                </button> */}
                                                            </h2>
                                                        </>)}
                                                    </div>
                                                    <ul className="sc-eSNMKe gNRSAT sc-fUSoCb kJIzVF sc-dYzDLp UqdGH" data-functional-selector="tabs" role="tabpanel">
                                                        <RouterLink to={"/teacher/reports/question_summary/" + splitLocation[4]}>
                                                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" aria-label="Summary: An overview of game results." className={splitLocation[3] === "question_summary" ? "active_navbar" : "deflt_navbar"}>
                                                                    <span className="sc-hKizKw ceBZw">Summary</span>
                                                                    <span className="sc-dRKXJR dkNZJN"></span>
                                                                </button>
                                                            </li>
                                                        </RouterLink>
                                                        <RouterLink to={"/teacher/reports/question_players/" + splitLocation[4]}>
                                                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" aria-label="Players: Display the results of players." className={splitLocation[3] === "question_players" ? "active_navbar" : "deflt_navbar"}>
                                                                    <span className="sc-hKizKw ceBZw">Players({players ? players.length : ''})</span>
                                                                </button>
                                                            </li>
                                                        </RouterLink>
                                                        <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                            <RouterLink to={"/teacher/reports/question_list/" + splitLocation[4]}>
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="questions" aria-label="Questions: Display the overview of 7 questions." className={splitLocation[3] === "question_list" ? "active_navbar" : "deflt_navbar"}>
                                                                    <span className="sc-hKizKw ceBZw">Questions({questions ? questions.length : ''})</span>
                                                                </button>
                                                            </RouterLink>
                                                        </li>
                                                        {/* <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                      <RouterLink to="/records/question_feedback">
                                                        <button type="submit" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="feedback" aria-label="Feedback: Display feedback from players." className={splitLocation[3] === "question_feedback" ? "active_navbar" : "deflt_navbar"}>
                                                            <span className="sc-hKizKw ceBZw">Feedback</span>
                                                        </button>
                                                      </RouterLink>
                                                    </li> */}
                                                    </ul>
                                                </section>

                                                <section data-functional-selector="report-summary-header__right-panel" className="sc-hOPlpL dXIcKz">
                                                    {/* <div className="sc-impOMm egUcsT"></div> */}
                                                    {summary.map((summary) => <>
                                                        <div data-functional-selector="report-summary-header__game-type" className="sc-liZemx dcdvzA">
                                                            {summary.mode == 0 ? 'Offline' : 'Live'}
                                                            {/* <img
                                                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMi4yNSIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDMpIj4KICAgICAgICA8bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+CiAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMyNjg5MEMiIGQ9Ik05LjI4MSA5LjI4MWg4LjQzOHY2LjE4OGEyLjI1IDIuMjUgMCAwIDEtMi4yNSAyLjI1SDkuMjhWOS4yOHoiIG1hc2s9InVybCgjYikiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjRkZBNjAyIiBkPSJNLjI4MSA5LjI4MUg4LjcydjguNDM4SDIuNTNhMi4yNSAyLjI1IDAgMCAxLTIuMjUtMi4yNVY5LjI4eiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMxMzY4Q0UiIGQ9Ik05LjI4MS4yODFoNi4xODhhMi4yNSAyLjI1IDAgMCAxIDIuMjUgMi4yNVY4LjcySDkuMjhWLjI4eiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNFMjFCM0MiIGQ9Ik0yLjUzMS4yODFIOC43MlY4LjcySC4yOFYyLjUzYTIuMjUgMi4yNSAwIDAgMSAyLjI1LTIuMjV6IiBtYXNrPSJ1cmwoI2IpIi8+CiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTEuNjQ0IDExLjY0NGgzLjM5djMuMzloLTMuMzl6TTQuNjYgMTUuNDExYTIuMDcyIDIuMDcyIDAgMSAxIDAtNC4xNDMgMi4wNzIgMi4wNzIgMCAwIDEgMCA0LjE0M3pNMTEuMDggNC42NjFsMi4yNi0yLjI2IDIuMjYgMi4yNi0yLjI2IDIuMjZ6TTYuNzMyIDYuMjczSDIuNTlsMi4wNzItMy4zMDd6IiBtYXNrPSJ1cmwoI2IpIi8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE3LjQzOCIgaGVpZ2h0PSIxNy40MzgiIHg9Ii4yODEiIHk9Ii4yODEiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTYzIiBtYXNrPSJ1cmwoI2IpIiByeD0iMi4yNSIvPgogICAgPC9nPgo8L3N2Zz4K"
                                                        className="sc-kMyZBz bcbmhN"
                                                    /> */}
                                                        </div>
                                                        <div data-functional-selector="report-summary-header__challenge-started" className="sc-liZemx dcdvzA">{summary.created_at}</div>
                                                        <div data-functional-selector="report-summary-header__username" className="sc-liZemx dcdvzA">Hosted by {summary.host_name}</div>
                                                    </>)}
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-izaQbr gytZrp" id="summary">
                                    <div data-functional-selector="report-detail-summary" className="sc-kstrdz sc-hBEYos kLItpH dWjUC">
                                        <div className="sc-kstrdz sc-hBEYos fZENZC dWjUC" width="100%" height="fit-content">
                                            <section className="sc-pXTW bCjDED">
                                                <div className="sc-fbSvIT gSPUpO">
                                                    <div data-functional-selector="report-summary__accuracy" className="sc-kstrdz sc-hBEYos sc-fmAPIY jlvGrj dWjUC gtvUqe">
                                                        <div className="sc-dfsAIE jycLXo">
                                                        {getsummary.length == 0 ? <div className='no-sumreport'> No Records</div> : getsummary.map((qtsummary) => <>
                                                            <div height="150" data-functional-selector="overview-accuracy-icon" className="sc-ftEBqf zPDEp">
                                                                <CircularProgressbar
                                                                    value={qtsummary.correct_answer_percentage ? qtsummary.correct_answer_percentage.toFixed(0) : 0}
                                                                    styles={buildStyles({
                                                                        textColor: "red",
                                                                        pathColor: "green",
                                                                        trailColor: "red"
                                                                    })}

                                                                />
                                                            </div>
                                                            <div className="sc-kstrdz sc-hBEYos sc-gTEgmx jlvGrj dWjUC cdCpbI">
                                                                <span data-functional-selector="overview-accuracy-icon-score" className="sc-duJdIj gaWAjV">{qtsummary.correct_answer_percentage ? qtsummary.correct_answer_percentage.toFixed(0)+'%' : 0}</span>
                                                                <span className="sc-kVThDm iUwMDM">correct</span>
                                                            </div>
                                                            </>)}
                                                        </div>
                                                    </div>
                                                    <div className="sc-jaMvmR eigNYj">
                                                        <h3 className="sc-boJvfu jLMRum">Practice makes perfect!</h3>
                                                        <p className="sc-fcVaJn eewtsG">Play again and let the same group improve their score or see if new players can beat this result.</p>
                                                        <div aria-expanded="false"><button onClick={() => { setModal(true) }} aria-haspopup="true" className="sc-cxFLnm hmCvLU sc-kXKmDQ eHgWFI" type="button" data-functional-selector="report-summary__play-again-btn">Play again  </button></div>
                                                        <TeacherGamePlay modalval={modalval} setModal={setModal} quiz_id={quiz_id}/>         
                                                    </div>
                                                </div>
                                                {/* {console.log('connnnn', summary)} */}
                                                <div className="sc-kstrdz sc-hBEYos sc-gzLGwR jlvGrj dWjUC lelkyD">
                                                    <div data-functional-selector="report-detail-summary__statistic-card" className="sc-cTFjtc goLWea">
                                                        {summary.length == 0 ? <div className='no-sumreport'> No Records</div> : summary.map((summary) => <>
                                                            <div data-functional-selector="game-summary__stats" className="sc-jomzkj fammEf">
                                                                <span className="sc-emKbvm jGWhti">
                                                                    <div className="sc-kstrdz kcaouQ">
                                                                        <span className="sc-eCssSg cAELPS cAELPSone">
                                                                            <svg id="stat-icon-playersCount" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path
                                                                                    d="M16,16 C13.2385763,16 11,13.7614237 11,11 C11,8.23857625 13.2385763,6 16,6 C18.7614237,6 21,8.23857625 21,11 C21,13.7614237 18.7614237,16 16,16 Z M25,24.3125 L7,24.3125 C7,20.2739178 11.0294373,17 16,17 C20.9705627,17 25,20.2739178 25,24.3125 Z"
                                                                                    style={{ fill: 'rgb(134, 76, 191' }}
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className="sc-jlisTE emaiyJ">Players</div>
                                                                    <div className="sc-dpASdi eFiKnH"><span data-functional-selector="game-summary__stats__players-count" className="sc-cXphjF clzirn">{summary.no_of_players ? summary.no_of_players : 0}</span></div>
                                                                </span>
                                                                <span className="sc-emKbvm jGWhti">
                                                                    <div className="sc-kstrdz kcaouQ">
                                                                        <span className="sc-eCssSg cAELPS cAELPSone">
                                                                            <svg id="stat-icon-questions" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path
                                                                                    d="M16 6c5.52 0 10 4.48 10 10s-4.48 10-10 10S6 21.52 6 16 10.48 6 16 6zm1 14h-2v2h2v-2zm-1-10c-2.21 0-4 1.79-4 4h2l.006-.149A2.007 2.007 0 0116 12c1.1 0 2 .9 2 2 0 2-3 1.75-3 5h2l.012-.242C17.218 16.728 20 16.408 20 14c0-2.21-1.79-4-4-4z"
                                                                                    style={{ fill: 'rgb(19, 104, 206' }}
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className="sc-jlisTE emaiyJ">Questions</div>
                                                                    <div className="sc-dpASdi eFiKnH"><span data-functional-selector="game-summary__stats__questions-count" className="sc-cXphjF clzirn">{summary.no_of_question ? summary.no_of_question : 0}</span></div>
                                                                </span>
                                                                <span className="sc-emKbvm jGWhti">
                                                                    <div className="sc-kstrdz kcaouQ">
                                                                        <span className="sc-eCssSg cAELPS cAELPSone">
                                                                            <svg id="stat-icon-timer" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path d="M16 6c5.52 0 10 4.48 10 10s-4.48 10-10 10S6 21.52 6 16 10.48 6 16 6zm1 5h-2v7h2v-7z" style={{ fill: 'rgb(10, 163, 163' }} />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className="sc-jlisTE emaiyJ">Time</div>
                                                                    <div className="sc-dpASdi eFiKnH"><span className="sc-cXphjF clzirn">{time} sec</span></div>
                                                                </span>
                                                            </div>
                                                        </>)}

                                                    </div>
                                                    {/* <div className="sc-bYNSva SucHY">
                                                        <div className="sc-jCHqmA cTeTtR">
                                                            <div display="flex" className="sc-kstrdz sc-ePLZZz jolUGF imMlHJ">
                                                                <div className="sc-kstrdz sc-NguZk kcaouQ cesfqb"></div>
                                                                <div className="sc-kstrdz goOiQg">
                                                                    <div className="sc-ldFlJy eYJoEh" aria-expanded="false"><button type="button" data-functional-selector="report-summary__show-podium-btn" className="sc-cxFLnm hmCvLU">View podium</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    {/* <div className="sc-kstrdz sc-hBEYos fZENZC dWjUC" width="100%" height="fit-content">
<section className="sc-fAONIj ieHfHN">
    <div className="sc-hVhHhZ fbHYEk">
        <div display="flex" className="sc-kstrdz sc-kyDzLY giKBQh gGDXgp">
            <div height="100%" data-functional-selector="difficult-questions-summary-card" className="sc-kstrdz sc-hBEYos TEzcL dWjUC">
                <div className="sc-kstrdz sc-hBEYos sc-eFStDw jlvGrj dWjUC egRpdn">
                    <h3 className="sc-iKlIap iEVWxx">Difficult questions</h3>
                    <div data-functional-selector="difficult-questions-summary-card__count" className="sc-dIJkls abCgf">(0)</div>
                    <div className="sc-kstrdz iSpVPD" width="auto" color="" aria-expanded="false">
                        <span className="sc-eCssSg cAELPS cAELPSone">
                            <svg id="summary-card-tooltip" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                <path
                                    d="M15,22 L17,22 L17,20 L15,20 L15,22 Z M16,6 C10.48,6 6,10.48 6,16 C6,21.52 10.48,26 16,26 C21.52,26 26,21.52 26,16 C26,10.48 21.52,6 16,6 Z M16,24 C11.59,24 8,20.41 8,16 C8,11.59 11.59,8 16,8 C20.41,8 24,11.59 24,16 C24,20.41 20.41,24 16,24 Z M16,10 C13.79,10 12,11.79 12,14 L14,14 C14,12.9 14.9,12 16,12 C17.1,12 18,12.9 18,14 C18,16 15,15.75 15,19 L17,19 C17,16.75 20,16.5 20,14 C20,11.79 18.21,10 16,10 Z"
                                    style={{ fill: 'rgb(110, 110, 110' }}
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="sc-eMunwL iAfucE">
                    <div width="100%" height="100%" className="sc-kstrdz sc-hBEYos dACzMH dWjUC">
                        <div data-functional-selector="difficult-questions__empty" className="sc-kstrdz sc-hBEYos sc-jhcxUd jlvGrj dWjUC exKMRQ">Great job! No one found any questions too challenging.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="sc-zmpTm dIShuW">
        <div className="sc-kstrdz sc-kyDzLY ebbjrJ gGDXgp">
            <div height="100%" data-functional-selector="need-help-summary-card" className="sc-kstrdz sc-hBEYos TEzcL dWjUC">
                <div className="sc-kstrdz sc-hBEYos sc-eFStDw jlvGrj dWjUC egRpdn">
                    <a
                        className="sc-gsTCUz giQMyB"
                        data-functional-selector=""
                        href="/user-reports/live-game/4a64ad87-42cf-42c6-80a5-13c39b2d5a95/29ac8a87-2ece-47e7-8877-d1f41014e57e/1637756315167/players/?tab=needHelp&amp;sortBy=averageAccuracy"
                    >
                        <h3 className="sc-iKlIap hMPKEx">Need help</h3>
                    </a>
                    <div data-functional-selector="need-help-summary-card__count" className="sc-dIJkls abCgf">(2)</div>
                    <div className="sc-kstrdz iSpVPD" width="auto" color="" aria-expanded="false">
                        <span className="sc-eCssSg cAELPS cAELPSone">
                            <svg id="summary-card-tooltip" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                <path
                                    d="M15,22 L17,22 L17,20 L15,20 L15,22 Z M16,6 C10.48,6 6,10.48 6,16 C6,21.52 10.48,26 16,26 C21.52,26 26,21.52 26,16 C26,10.48 21.52,6 16,6 Z M16,24 C11.59,24 8,20.41 8,16 C8,11.59 11.59,8 16,8 C20.41,8 24,11.59 24,16 C24,20.41 20.41,24 16,24 Z M16,10 C13.79,10 12,11.79 12,14 L14,14 C14,12.9 14.9,12 16,12 C17.1,12 18,12.9 18,14 C18,16 15,15.75 15,19 L17,19 C17,16.75 20,16.5 20,14 C20,11.79 18.21,10 16,10 Z"
                                    style={{ fill: 'rgb(110, 110, 110' }}
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="sc-eMunwL iAfucE">
                    <div className="sc-kstrdz sc-hBEYos bDrQBU dWjUC" height="100%" data-functional-selector="need-help__content">
                        <div className="sc-kstrdz sc-hBEYos sc-kcnPTm bbAtDI dWjUC hSgzTn">
                            <span className="sc-gLScgl eIOtBY">dsad</span>
                            <div className="sc-kstrdz sc-LsLCG jlvGrj hfzqIX">
                                <div height="30" data-functional-selector="progress-icon" className="sc-ftEBqf EwReZ">
                                 
                                </div>
                            </div>
                            <div data-functional-selector="need-help__accuracy" className="sc-kstrdz sc-bKpEro jlvGrj kTOydt">0%</div>
                        </div>
                        <div className="sc-kstrdz sc-hBEYos sc-kcnPTm bbAtDI dWjUC hSgzTn">
                            <span className="sc-gLScgl eIOtBY">fds</span>
                            <div className="sc-kstrdz sc-LsLCG jlvGrj hfzqIX">
                                <div height="30" data-functional-selector="progress-icon" className="sc-ftEBqf EwReZ">
                                </div>
                            </div>
                            <div data-functional-selector="need-help__accuracy" className="sc-kstrdz sc-bKpEro jlvGrj kTOydt">14%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sc-kstrdz sc-kyDzLY ebbjrJ gGDXgp">
            <div height="100%" data-functional-selector="unfinished-players-summary-card" className="sc-kstrdz sc-hBEYos TEzcL dWjUC">
                <div className="sc-kstrdz sc-hBEYos sc-eFStDw jlvGrj dWjUC egRpdn">
                    <a
                        className="sc-gsTCUz giQMyB"
                        data-functional-selector=""
                        href="/user-reports/live-game/4a64ad87-42cf-42c6-80a5-13c39b2d5a95/29ac8a87-2ece-47e7-8877-d1f41014e57e/1637756315167/players/?tab=unfinished&amp;sortBy=unansweredCount&amp;reverse=true"
                    >
                        <h3 className="sc-iKlIap hMPKEx">Didn't finish</h3>
                    </a>
                    <div data-functional-selector="unfinished-players-summary-card__count" className="sc-dIJkls abCgf">(2)</div>
                    <div className="sc-kstrdz iSpVPD" width="auto" color="" aria-expanded="false">
                        <span className="sc-eCssSg cAELPS cAELPSone">
                            <svg id="summary-card-tooltip" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                <path
                                    d="M15,22 L17,22 L17,20 L15,20 L15,22 Z M16,6 C10.48,6 6,10.48 6,16 C6,21.52 10.48,26 16,26 C21.52,26 26,21.52 26,16 C26,10.48 21.52,6 16,6 Z M16,24 C11.59,24 8,20.41 8,16 C8,11.59 11.59,8 16,8 C20.41,8 24,11.59 24,16 C24,20.41 20.41,24 16,24 Z M16,10 C13.79,10 12,11.79 12,14 L14,14 C14,12.9 14.9,12 16,12 C17.1,12 18,12.9 18,14 C18,16 15,15.75 15,19 L17,19 C17,16.75 20,16.5 20,14 C20,11.79 18.21,10 16,10 Z"
                                    style={{ fill: 'rgb(110, 110, 110' }}
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="sc-eMunwL iAfucE">
                    <div className="sc-kstrdz sc-hBEYos bDrQBU dWjUC" height="100%" data-functional-selector="unfinished-players__content">
                        <div className="sc-kstrdz sc-hBEYos sc-gqZFg dLFCLI dWjUC hxgbph">
                            <span className="sc-jnAlnW tBwHk">fds</span>
                            <div data-functional-selector="unfinished-players__unanswered-count" className="sc-kstrdz sc-iyyBTX jlvGrj hRGBEb">6</div>
                        </div>
                        <div className="sc-kstrdz sc-hBEYos sc-gqZFg dLFCLI dWjUC hxgbph">
                            <span className="sc-jnAlnW tBwHk">dsad</span>
                            <div data-functional-selector="unfinished-players__unanswered-count" className="sc-kstrdz sc-iyyBTX jlvGrj hRGBEb">6</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
                                        </div> */}
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default QuestionSummary;
