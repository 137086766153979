/* eslint-disable */
import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Button,
  Card,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import clsx from 'clsx';
import { Flip, Bounce } from 'react-awesome-reveal';
import { Elements } from '@stripe/react-stripe-js';
import cogoToast from 'cogo-toast';
import { loadStripe } from '@stripe/stripe-js';
import global from 'src/utils/global';
import authChecker from 'src/utils/authHelper';
import Page from 'src/components/Page';
import { membershipUpgradeAdmin, adminGetPlan, currentUSerPlan, cancelPlan } from 'src/utils/Api';
import { useNavigate, useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  rootChat: {
    padding: 15,
    marginTop: 9,
    marginBottom: 70,
    height: 600,
    overflowY: 'scroll'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  welcome: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: theme.spacing(3),
    height: 800,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  msgInputContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: -50
  },
  membershipBtn: {
    marginTop: 20,
  },
  membershipcancelBtn: {
    marginTop: 20,
    backgroundColor: 'red !important',
    color: 'white'
  }
}));


const ManageMembership = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [planID, setPlanID] = React.useState('');
  const [userID, setuserID] = React.useState('');
  const [allPlans, setAllPlans] = React.useState('');
  const [userPlan, setUserPlan] = React.useState('');
  const stripePromise = loadStripe(global.stripePKey);
  const isUserID = params.user_id | 0;

  React.useEffect(() => {
    adminGetPlan().then((res) => {
      console.log('All plans', res)
      setAllPlans(res.data)
    })
    //Get Current User Plan 
    console.log('isUserID', isUserID)
    currentUSerPlan({ id: isUserID }).then((res) => {
      console.log('getCurrentUserPlans', res)
      setUserPlan(res.plan_data)
    })
  }, [dialogOpen, cancelDialogOpen]);
  const handleClose = () => {
    setDialogOpen(false);
    setCancelDialogOpen(false);

  };
    //Cancel Model
    const cancelOpen = (plan_id) => {
      console.log('type', plan_id)
      setPlanID(plan_id);
      setuserID(isUserID)
      setCancelDialogOpen(true);
    };
  
    //CancelMembership Function
    const cancelMemberShip = () =>{
  
      cancelPlan({ userID, planID }).then((res)=>{
        console.log('ress success', res)
        if(res.code === 200){
          setCancelDialogOpen(false);
        }else{
          setCancelDialogOpen(false);
        }
      }).catch((err)=>{
          console.log('cancel serve err', err)
          // cogoToast.warn(' Network Error Please try again later!', { position: 'top-right' });

      })
  
    }


  const handleOpen = (plan_id) => {
    console.log('upupupup', plan_id)
    setPlanID(plan_id);
    setuserID(isUserID)
    setDialogOpen(true);
  };

  const membershipUpgrade = () => {
    console.log('membershipUpgrade', planID, userID,)
    membershipUpgradeAdmin({ user_id: userID, plan_id: planID }).then((res) => {
      console.log('resUp', res)
      if (res.code == 201) {
        cogoToast.warn(res.msg, { position: 'top-right' });
        setDialogOpen(false);

      } else {
        cogoToast.success(res.msg, { position: 'top-right' });
        setDialogOpen(false);
      }

    }).catch((err) => {
      cogoToast.warn('Network Error Please try again later', { position: 'top-right' })

    })
    setDialogOpen(true);
  };

  const cancelUpgrade = () => {
    console.log('cancel membership')
    setDialogOpen(false);
  };


  return (
    <Page
      className={classes.root}
      title="Manage MemberShip"
    >
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Membership Upgrade</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are You Sure You want to upgarde?.
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={membershipUpgrade} style={{ backgroundColor: '#0C003C', color: 'white' }} variant="success">
            Upgrade
          </Button>
          <Button onClick={cancelUpgrade} style={{ color: 'white', backgroundColor: 'red' }} variant="danger">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cancelDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Membership Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel membership.
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={cancelMemberShip} style={{ backgroundColor: '#0C003C', color: 'white' }} variant="success">
            Yes
          </Button>
          <Button onClick={()=>{setCancelDialogOpen(false)}} style={{ color: 'white', backgroundColor: 'red' }} variant="danger">
            No
          </Button>
        </DialogActions>

      </Dialog>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          alignContent="space-around"
          direction="row"
          justify="center"
        >
          {allPlans.length > 0 ? allPlans.map((item) => (

            <Grid
              key={item.id}
              item
              xl={3}
              lg={3}
              md={3}
              xs={3}
            >
              <Flip left cascade delay={400} duration={700}>
                <Box mt={3}>
                  <Card className={clsx(classes.welcome)}>
                    {
                      userPlan?.plan_id === item.id ? <div className="ribbon ribbon-top-right"><span>Current</span></div> : null
                    }
                    <Bounce delay={1100}>
                      <img src={`${global.serverUrl}upload/${item.plan_img}`} alt="Image" className="membershipMark" />
                    </Bounce>
                    <h1 className="membership-title">{`${item.plan_title}`}</h1>
                    <p className="membership-description">
                      $
                      <span>{`${item.plan_amount}`}</span>

                    </p>
                    <p className="membership-description">{`${item.plan_month} Days`}</p>
                    {

                      userPlan?.plan_id === item.id ? <p className="membership-description">{userPlan.end_date.split('T')[0].substring(0, 10)}</p> : null
                    }
                    {item?.id != 1 ?  <Button style={{ backgroundColor:userPlan?.plan_id === item.id ? 'grey':'#0C003C', color:'white'}} disabled={userPlan?.plan_id === item.id ? true:false} className={classes.membershipBtn} onClick={() => { handleOpen(item.id, item?.plan_title); }}>Upgrade</Button> : null}
                    {item?.id != 1 && userPlan?.plan_id === item.id ?  <Button className={classes.membershipcancelBtn} onClick={() => { cancelOpen(item.id, userPlan?.user_id); }}>Cancel</Button> : null}                       <div>
                      <p className="membership-item">{`${item.plan_quiz} Questions`}</p>
                      <p className="membership-item">{`${item.plan_classes} Classes`}</p>
                      <p className="membership-item">{`${item.plan_collection} Collections`}</p>

                      <p className="membership-item">{`${item.no_of_virtual_participant} Class Participants`}</p>
                      <p className="membership-item">{`${item.class_duration} Class Duration in Minutes`}</p>
                      <p className="membership-item">{`${item.no_of_recordings} Recordings in Minutes`}</p>
                      <p className="membership-item">{`${item.data_transfer} Data Trasfer`}</p>
                      <p className="membership-item">{`${item.storage} Storage`}</p>


                    </div>
                  </Card>
                </Box>
              </Flip>
            </Grid>
          )) : 'No Plans'}
        </Grid>
      </Container>
    </Page>
  );
};

export default ManageMembership;
