/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-var
const global = {
  // serverUrl: 'http://localhost:3001/',
  // serverUrl: 'http://3.98.82.62:8081/',
  // serverUrl: 'http://3.98.82.62:8081/',
  serverUrl: 'https://brainaly.com:8081/',

  // pageNationLimit: 5,
  // gamePageUrl: 'http://localhost:3000/game/play',
  // gamePageUrl: 'https://3.98.82.62/game/play',
  gamePageUrl: 'https://brainaly.com/game/play',
  stripePKey: 'pk_test_51IPvrnIAaIJ9dA25wOEBraCmlnC5wvRVlhji81uUEAatYTa9xmL7d9VTzJXcO3t5Hygt2b8fZjIRekXcTi4rRsOb0070y6LOUk',
  stripeSKey: 'sk_test_51IPvrnIAaIJ9dA25lOk4dDB0RRLvkDYb1LF9pITxtcl67oiVpoHA3tycYgnUn01SDHmg8VAIzhOUaDfxV7JpMN4X00Odeh68UU'
};

export default global;
