/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Card,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CardMedia,
  CircularProgress,
  MenuItem
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { signUp } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 50
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Privacy = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [accountType, setAcctType] = React.useState('teacher');
  const [isLoading, setIsLoading] = React.useState(false);
  const handChangeAccType = (value) => {
    console.log(value.target.value);
    setAcctType(value.target.value);
  };
  const handleClickShowPassword = () => {
    console.log('asdasdasdsd');
  };
  const handleMouseDownPassword = () => {
    console.log('asdasdasd');
  };
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  return (
    <Page
      className={classes.root}
      title="Privacy Policy"
    >
      <Box
        component="div"
        mt={15}
        className='privacy-container'
      >
        <Grid
          container
          spacing={7}
          justify="center"
        >
          <Grid
            xl={8}
            lg={8}
            md={8}
            xs={12}
            item
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justify="center">
                <Grid item>
                  {/* <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '60%', margin: 'auto' }}>
                  <img src="/static/logo.png" alt="logo" width="100%" />
              </div> */}
                  <div className='policy-gdpr' style={{ color: '#F3F3F3', fontSize: 26 }}>
                    Privacy Policy GDPR
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Introduction</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    We are committed to protecting your privacy.
                  </div>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    This privacy notice provides you with details of how we collect and process your personal data through your use of our site <a href="https://www.brainaly.com" className="privacy-text" style={{ textDecoration: 'underline', color: 'rgb(1, 2, 92)' }}>https://www.brainaly.com</a>.
                  </div>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Brainaly is the data controller and we are responsible for your personal data (referred to as “we,” “us” or “our” in this privacy notice).
                  </div>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    This notice was updated on 14 July 2022 to reflect GDPR compliance standards.

                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Contact Details</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Full name of legal entity: <strong>Brainaly</strong>
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Email address: <strong>info@brainaly.com</strong>
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    It is very important that the information we hold about you is accurate and up to date. Please let us know if at any time your personal information changes by emailing us at <a href="info@brainaly.com" className="privacy-text" style={{ textDecoration: 'underline', color: 'rgb(1, 2, 92)' }}>info@brainaly.com</a>.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">How We Collect Your Personal Data</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    We may collect data about you by you providing the data directly to us (for example by filling in forms on our site or by sending us emails).
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    We may automatically collect certain data from you as you use our website by using cookies and similar technologies.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Grid xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
              <Grid item xs className={classes.titleContainer}>
                <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">What Personal Data We Collect And Why We Collect It</div>
              </Grid>
              <Grid >
                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Personal data means any information capable of identifying an individual. It does not include anonymized data.
                </div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">
                  We may process the following categories of personal data about you:</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Communication Data that includes any communication that you send to us whether that be through the contact form on our website, through email, text, social media messaging, social media posting or any other communication that you send us. We process this data for the purposes of communicating with you, for record keeping and for the establishment, pursuance or defense of legal claims. Our lawful ground for this processing is our legitimate interests which in this case are to reply to communications sent to us, to keep records and to establish, pursue or defend legal claims.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Customer Data that includes data relating to any purchases of goods and/or services such as your name, title, billing address, delivery address email address, phone number, contact details, purchase details and your PayPal email. We process this data to supply the goods and/or services you have purchased and to keep records of such transactions. Our lawful ground for this processing is the performance of a contract between you and us and/or taking steps at your request to enter into such a contract.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  User Data that includes data about how you use our website and any online services together with any data that you post for publication on our website or through other online services. We process this data to operate our website and ensure relevant content is provided to you, to ensure the security of our website, to maintain back- ups of our website and/or databases and to enable publication and administration of our website, other online services and business. Our lawful ground for this processing is our legitimate interests which in this case are to enable us to properly administer our website and our business.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Technical Data that includes data about your use of our website and online services such as your IP address, your login data, details about your browser, length of visit to pages on our website, page views and navigation paths, details about the number of times you use our website, time zone settings and other technology on the devices you use to access our website. The source of this data is from our analytics tracking system. We process this data to analyze your use of our website and other online services, to administer and protect our business and website, to deliver relevant website content and advertisements to you and to understand the effectiveness of our advertising. Our lawful ground for this processing is our legitimate interests which in this case are to enable us to properly administer our website and our business and to grow our business and to decide our marketing strategy.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Marketing Data that includes data about your preferences in receiving marketing from us and our third parties and your communication preferences. We process this data to enable you to partake in our promotions such as competitions, prize draws and free giveaways, to deliver relevant website content and advertisements to you and measure or understand the effectiveness of this advertising. Our lawful ground for this processing is our legitimate interests which in this case are to study how customers use our products/services, to develop them, to grow our business and to decide our marketing strategy.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Comments via WordPress – When visitors leave comments on the site we collect the data shown in the comments form, and the visitor’s IP address and browser user agent string to help spam detection. An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">Contact forms</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Our contact form does not store data, but we do keep contact emails on our email server for customer service purposes, but we never use these for marketing purposes.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">Cookies</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">Embedded content from other websites</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">eCommerce Data Collection via WooCommerce</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  We also collect information about you during the checkout process at our store.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">What we collect and store:</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  We also collect information about you during the checkout process at our store.
                  <ul>
                    <li>Products you’ve viewed: we’ll use this to, for example, show you products you’ve recently viewed</li>
                    <li>Location, IP address and browser type: we’ll use this for purposes like estimating taxes and shipping</li>
                  </ul>
                </div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  We’ll also use cookies to keep track of cart contents while you’re browsing our site</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  When you purchase from us, we’ll ask you to provide information including your name, email address, payment details and account information like username and password. We’ll use this information for purposes, such as, to:
                  <ul>
                    <li>Send you information about your account and order</li>
                    <li>Respond to your requests, including refunds and complaints</li>
                    <li>Process payments and prevent fraud</li>
                    <li>Set up your account for our store</li>
                    <li>Comply with any legal obligations we have, such as calculating taxes</li>
                    <li>Improve our store offerings</li>
                    <li>Send you marketing messages, if you choose to receive them</li>
                  </ul>
                </div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  If you create an account, we will store your name and email, which will be used to populate the checkout for future orders.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  We generally store information about you for as long as we need the information for the purposes for which we collect and use it, and we are not legally required to continue to keep it. For example, we will store order information for 6 years for tax and accounting purposes. This includes your name, and email address and order history.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  We will also store comments or reviews, if you choose to leave them.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">Who on our team has access:</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                  Our Administrator can access:
                  <ul>
                    <li>Order information like what was purchased, when it was purchased and where it should be sent, and</li>
                    <li>Customer information like your name and email address.</li>
                  </ul>
                </div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Our team members have access to this information to help fulfill orders, process refunds and support you.</div>

                <div style={{ color: '#000', fontWeight: 'bold' }} className="privacy-text text-heading">Payments:</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text"><strong>Paypal: </strong> We accept payments through PayPal. When processing payments, some of your data will be passed to PayPal, including information required to process or support the payment, such as the purchase total and billing information. Please see the PayPal Privacy Policy for more details.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text"><strong>Stripe: </strong> We also accept payments via Stripe. When processing payments, some of your data will be passed to PayPal, including information required to process or support the payment, such as the purchase total and billing information. Please see the Stripe Privacy Policy for more details.
                  <br />
                  Credit/debit card information is never stored on our site and is only shared with our payment processors in order to complete the transaction in which you are engaging.
                </div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text"><strong>If you fail to provide consent: </strong> Your consent to allow us to process and store your data is optional, but failure to provide such consent for the data collected as described above prevents us from delivering our products, content, and messages to you as mentioned above.</div>

                <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text"><strong>Sensitive Data: </strong> We do not collect any Sensitive Data about you. Sensitive data refers to data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. We do not collect any information about criminal convictions and offences.
                  <br />
                  Where we are required to collect personal data by law, or under the terms of the contract between us and you do not provide us with that data when requested, we may not be able to perform the contract (for example, to deliver goods or services to you). If you don’t provide us with the requested data, we may have to cancel a product or service you have ordered but if we do, we will notify you at the time.
                  <br />
                  We will only use your personal data for a purpose it was collected for or a reasonably compatible purpose if necessary. For more information on this please email us at info@brainaly.com. In case we need to use your details for an unrelated new purpose we will let you know and explain the legal grounds for processing.
                  <br />
                  We may process your personal data without your knowledge or consent where this is required or permitted by law.
                  <br />
                  We do not carry out automated decision making or any type of automated profiling.
                </div>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">How Long We Retain Your Data</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Your Rights Over Your Data</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">GDPR – Right to Erasure: How to ask us to delete your data</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    We may collect data about you by you providing the data directly to us (for example by filling in forms on our site or by sending us emails).
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    We may automatically collect certain data from you as you use our website by using cookies and similar technologies.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Marketing Communications</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Our lawful ground of processing your personal data to send you marketing communications is either your consent or our legitimate interests (namely to grow our business).</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Before we share your personal data with any third party for their own marketing purposes we will get your express consent.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or OR by emailing us at <a href="info@brainaly.com" className="privacy-text" style={{ textDecoration: 'underline', color: 'rgb(1, 2, 92)' }}>info@brainaly.com</a> at any time.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    If you opt out of receiving marketing communications this opt-out does not apply to personal data provided as a result of other transactions, such as purchases, and store account registration.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center">
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Disclosures Of Personal Data To Third Parties</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Some information, such as your IP and your email addresses, may be shared with certain third parties who help us run our website, marketing and email programs. They will only use this information to serve our needs and are prohibited from using it in any other ways.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    <strong>3rd Party Companies We Use Include:</strong>
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Wordfence (WordPress Security Suite)</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Active Campaign (Email Marketing Provider)</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Hostgator (Web hosting program)</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Google (Ad serving and website tracking)</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Visitor comments may be checked through an automated spam detection service (Akismet)</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Service providers who provide IT and system administration services</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

    </Page>
  );
};

export default Privacy;
