/*eslint-disable*/
//----------------------------Import's Here--------------------------//
import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useNavigate, useParams } from "react-router-dom";
import { updateBlog, addBlog,searchBlog, blogImageUpload} from "src/utils/Api";
import { Button } from "react-bootstrap";
import cogoToast from "cogo-toast";
import global from "src/utils/global";
// import * as urlSlug from "url-slug";
//------------------------------------Main Function------------------------------//
function AddBlog() {

    //---------------------------UseStates and other Constant's---------------------//

    let navigate = useNavigate();
    const [title, setTitle] = React.useState();
    const [titleDesc, setTitleDesc] = React.useState();
    const [slug, setSlug] = React.useState();
    const [image, setImage] = React.useState();
    const [previewimage, setPreviewImage] = React.useState();

    const [content, setContent] = React.useState();
    const [isError, setError] = React.useState(null);
    const _id = useParams().id | '';

    //---------------------------------useEffect------------------------//

    React.useEffect(() => {
        if (_id) {
           searchBlog({id:_id}).then((res) => {
                // console.log("GetAllBlogsResponse", res);
                setTitle(res.data.title)
                setTitleDesc(res.data.title_desc)
                setContent(res.data.content)
                setImage(res.data.image)
                setSlug(res.data.slug)
            })
        }
    }, [])

    //-----------------------------------Handler's--------------------------------------//

    //-------------------Title Handler--------------//
    const onImageChange = async (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            const reader = URL.createObjectURL(e.target.files[0]);
            setPreviewImage(reader)
            await blogImageUpload(e.target.files[0]).then((res) => {
                // console.log('response', res)
                setImage(res.data.filename)
            })


        }


    }
    const onChangeValue = (e) => {
        setTitle(
            e.target.value
        );
        const str = e.target.value.toLowerCase();
        // console.log("str",str);
        const ar = str.split(" ");
        // console.log("ar",ar)
        setSlug(ar.join("-"))

    }
    const onChangetitleDesc = (e) => {
        // console.log('eee', e.target.value)
        setTitleDesc(
            e.target.value
        );
    }


    //-----------------Clear handler-------------//
    const handleClear = () => {
        setContent("")
        setTitle('')
        setTitleDesc('')
        setContent('')
        setImage('')
        setPreviewImage('')
    }

    //-----------------Content Handler---------------------//

    const handleContent = (value) => {
        setContent(
            value
        );
    }

    //------------------Preview Handler---------------------//

    const handlePreview = () => {
        // console.log("blogTitle", title)
        localStorage.setItem("blogTitle", title)
        localStorage.setItem("blogTitleDESC", titleDesc)
        localStorage.setItem("blogImage", image)
        localStorage.setItem("blogPreviewImage", previewimage)
        localStorage.setItem("blogContent", content)
        window.open(`${window.location.origin}/blog-preview`);

        // navigate("/blog-preview")
    }

    //-------------------Submit Handler---------------------//

    const handleSubmit = async (event) => {
        // console.log('submit', title)

        event.preventDefault();
        // if(content == ''){
        //     return cogoToast.error('All Fields are Required', { position: 'top-right' });
        // }
        // return false;
        event.persist();

        // console.log("title" + title + "content" + content, 'ferwerewr', titleDesc , "slug",slug)
        if (_id) {
            updateBlog({ id: _id, title: title, title_desc: titleDesc, image: image, content: content, slug: slug }).then((res) => {
                if (res.code === 200) {
                    navigate("/admin/view-blog");
                }
            })
        } else {
            searchBlog({ slug: slug }).then((res) => {
        // console.log('response2222',res)  
              if (res.code !== 200) {
            
                    addBlog({ title: title, title_desc: titleDesc, image: image, content: content, slug: slug }).then((res) => {
                        // console.log('resaddd', res)
                        if (res.code === 200) {
                            navigate("/admin/view-blog");
                        }
                    })
                } else {
                    cogoToast.error("Title already exists", { position: "top-center" });
                }
            })
        }
    }

    //------------------------Return--------------------------//

    return (
        <>
            <div className="addBlog">
                <div className="container">
                    <div className="row">
                        <form onSubmit={handleSubmit} className="update__forms">
                            <div style={{ textAlign: "end" }}>
                                <Button style={{ backgroundColor: "tomato" }} onClick={() => handlePreview()}>Preview</Button>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12" style={{ marginTop: "20px", textAlign: "left" }}>
                                    <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Title</label>
                                    <input type="text" name="title" value={title} onChange={(e) => onChangeValue(e)} className="form-control" required />
                                </div>
                                <div className="form-group col-md-12" style={{ marginTop: "20px", textAlign: "left" }}>
                                    <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Title Description</label>
                                    <textarea row='2' type="text" name="title_desc" value={titleDesc} onChange={(e) => onChangetitleDesc(e)} className="form-control" required />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="form-group col-md-4" style={{ marginTop: "20px", textAlign: "left" }}>
                                        <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Upload Image</label>

                                        <input
                                            type="file"
                                            onChange={(event) => { onImageChange(event); }}
                                            className="form-control"

                                        />
                                    </div>
                                    <div className="form-group col-md-6" style={{ marginTop: "20px", textAlign: "left" }}>
                                        <label style={{ fontWeight: "bold", marginBottom: 10, marginRight: 10 }}>Preview Image</label>

                                        {previewimage ?
                                            <img src={previewimage} height={100} width={100} />

                                            : image ?
                                                <img src={`${global.serverUrl}upload/blog/${image}`} height={100} width={100} />
                                                : null
                                        }

                                    </div>
                                </div>

                                <div className="clearfix"></div>
                                <div className="form-group col-md-12 editor" style={{ marginTop: "20px", textAlign: "left" }}>
                                    <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Content</label>
                                    <EditorToolbar toolbarId={'t1'} />
                                    <ReactQuill
                                        theme="snow"
                                        value={content}
                                        onChange={handleContent}
                                        placeholder={"Write something awesome..."}
                                        modules={modules('t1')}
                                        formats={formats}
                                    />
                                </div>
                                <br />
                                {isError !== null && <div className="errors"> {isError} </div>}
                                <div className="form-group col-sm-12 text-right">
                                    <Button
                                        type="submit"
                                        style={{ backgroundColor: "#0c003c" }}
                                    > Submit  </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-danger"
                                        style={{ marginLeft: "20px" }}
                                        onClick={() => handleClear()}
                                    > Clear </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

//---------------------------------Default Export-----------------------//
export default AddBlog