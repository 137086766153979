/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
/* eslint-disable */

import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
    Box,
    Checkbox,
    Container,
    FormHelperText,
    Link,
    TextField,
    Typography,
    makeStyles,
    Card,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    MenuItem,
    List,
    Tabs,
    Tab,
} from '@material-ui/core';
import { Button, Modal } from 'react-bootstrap';
import {
    ShoppingBag as ShoppingBagIcon,
    Users as UsersIcon
} from 'react-feather';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from 'src/components/Page';
// import QuestionPlayers from 'src/views/allrecords/QuestionPlayers';
import QuestionSummary from 'src/views/allrecords/QuestionSummary';
import QuestionsList from 'src/views/allrecords/QuestionsList';
import QuestionFeedback from 'src/views/allrecords/QuestionFeedback';
import cogoToast from 'cogo-toast';
import { quizPlayers, quizQuestions } from 'src/utils/Api';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        // height: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    formControl: {
        width: '100%'
    },
}));

const QuestionPlayers = () => {
    // const { children, value, index, ...other } = props;
    const [players, setPlayers] = React.useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const [show, setShow] = React.useState(false);
    const [modalValue, setmodalValue] = React.useState([]);
    const [rank, setRank] = React.useState();
    const [questions, setQuestions] = React.useState([]);
    const [summary, setSummary] = React.useState([]);
    //assigning location variable
    const location = useLocation();
    //destructuring pathname from location
    const { pathname } = location;

    const splitLocation = pathname.split("/");

    const hostData = async () => {
        var get_id = splitLocation[4];
        var data = JSON.parse(localStorage.getItem('host'));
        var result = data.filter((item) => {
            return get_id == item['id'] ?? item
        });
        setSummary([...result])
        console.log('filresult', result)
    }

    const handleClose = () => setShow(false);
    const handleShow = (pid, rank) => {
        setRank(rank)
        setmodalValue(pid)
        var result = players.filter((item) => {

            return pid == item['id'] ?? item
        });
        console.log('filresult', result)
        setmodalValue([...result])
        setShow(true)
    };
    const quiz_players = async () => {
        //Date Format
        function dateFormat(str) {
            var months = {
                '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
                '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
                '11': 'November', '12': 'December',
            }
            var date = new Date(str),
                // day = ("0" + (date.getDate()+ 1)).slice(-2);
                day = ("0" + date.getDate()).slice(-2);

            var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                monthNumber = months[mnth];

            return [day, monthNumber, date.getFullYear(),].join("-");
        }

        //Time Function
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
        var get_id = splitLocation[4]
        await quizPlayers({ id: get_id }).then((res) => {
            var data = res.data;
            console.log(data, 'Question_player')
            var quiz_filter = data ? data.map(players => ({
                'id': players['id'],
                'quiz_id': players['quiz_id'],
                'game_list_id': players['game_list_id'],
                'user_id': players['user_id'],
                'nick_name': players['user_name'],
                'total_correct_answer': players['total_correct_answer'],
                'total_correct_answer_percentage': players['total_correct_answer_percentage'],
                'total_score': players['total_score'],
                'unanswered': players['unanswered'],
                'user_info': players['user_info'],
                'unanswered': players['unanswered'],
                'created_at': dateFormat(players['created_at'].split('T')[0]) + ' ' + tConvert(players['created_at'].split('T')[1].split('.')[0].split(':')[0] + ':' + players['created_at'].split('T')[1].split('.')[0].split(':')[1]),
            })) : ''
            console.log(quiz_filter, 'quizPlayers');
            setPlayers([...quiz_filter])
        }).catch((err) => {
            console.log(err, 'quizPlayerserror');
        });
    }
    const quiz_questions = async () => {
        //Date Format
        function dateFormat(str) {
            var months = {
                '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
                '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
                '11': 'November', '12': 'December',
            }
            var date = new Date(str),
                // day = ("0" + (date.getDate()+ 1)).slice(-2);
                day = ("0" + date.getDate()).slice(-2);

            var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                monthNumber = months[mnth];

            return [day, monthNumber, date.getFullYear(),].join("-");
        }

        //Time Function
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
        var get_id = splitLocation[4]
        await quizQuestions({ id: get_id }).then((res) => {
            var quiz_question = res.data;
            console.log(quiz_question, 'quizQuestions');
            setQuestions([...quiz_question])
        }).catch((err) => {
            console.log(err, 'quizQuestionsserror');
        });
    }
    React.useEffect(() => {
        quiz_players();
        quiz_questions();
        hostData();
    }, [])

    return (
        <Page
            className={classes.root}
            title="Brainaly Players"
        >
            {/* <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        All Records
      </Box> */}
            <div className="sc-izaQbr gytZrp" id="main-content">
                <div className="sc-gPTkgY jAYNyi">
                    <div className="sc-gPTkgY jAYNyi" aria-hidden="false">
                        <div>
                            <main className="sc-fNFVIZ hxQRHK">
                                <div width="1" className="sc-kstrdz sc-hBEYos gvzVaF dWjUC">
                                    <div className="sc-hTsBph cTvoQZ">
                                        <div className="sc-kstrdz sc-hBEYos fZENZC dWjUC" width="100%" height="fit-content">
                                            <div className="sc-bDCewv hhngOi">
                                                <section className="sc-cFPsUu bztCDK">
                                                    <div className="sc-stgHA gabcHZ">
                                                        <h5 data-functional-selector="report-summary-header__reports-subtitle" className="sc-dzDlLZ fMObBc">Report</h5>
                                                        <h2 data-functional-selector="report-summary-header__reports-title" className="sc-jNwxvY RLUoc">
                                                            <button type="submit" aria-label="Rename report. Current report name. Dosage Review" data-functional-selector="report-summary-header__rename-button" className="sc-iBPRYJ kQjigY sc-hpSTqv fBfcAo">
                                                                <span className="sc-eCssSg dDNlwv dDNlwvone">
                                                                    {/* <svg id="edit-reports-title" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                                    <path
                                                                        d="M23.4091683,8.594 C24.1731683,9.359 24.1731683,10.693 23.4091683,11.458 L22.6901683,12.178 L19.8241683,9.313 L20.5451683,8.594 C21.3101683,7.83 22.6441683,7.829 23.4091683,8.594 L23.4091683,8.594 Z M8.37616832,23.626 L8.97116832,21.252 L10.7501683,23.033 L8.37616832,23.626 Z M18.4111683,10.728 L21.2741683,13.591 L12.7061683,22.159 L9.84316832,19.296 L18.4111683,10.728 Z M24.8211683,7.179 C24.0611683,6.418 23.0501683,6 21.9741683,6 C20.9001683,6 19.8881683,6.418 19.1281683,7.179 L7.71916832,18.587 C7.71916832,18.587 7.71816832,18.59 7.71716832,18.591 C7.63616832,18.673 7.49716832,18.889 7.45616832,19.052 L6.03016832,24.757 C5.94416832,25.097 6.04516832,25.458 6.29316832,25.706 L6.29416832,25.707 L6.29616832,25.708 C6.54416832,25.956 6.90416832,26.057 7.24516832,25.972 L12.9491683,24.545 C13.1121683,24.504 13.3291683,24.365 13.4101683,24.284 C13.4111683,24.283 13.4141683,24.282 13.4141683,24.282 L24.8231683,12.873 C25.5831683,12.113 26.0021683,11.102 26.0011683,10.027 C26.0011683,8.951 25.5831683,7.941 24.8231683,7.18 L24.8211683,7.179 Z"
                                                                        style={{ fill: "rgb(51, 51, 51)"}}
                                                                    ></path>
                                                                </svg> */}
                                                                </span>
                                                            </button>
                                                        </h2>
                                                        {/* <div className="sc-dYzDLp UqdGH">Report options</div> */}
                                                        <div className="sc-kmsPvo cCKVkA">
                                                            <div className="sc-wyIyg iVugHJ onboarding-reports">
                                                                <div className="sc-iRpACI gSFmGa sc-iiqTaX lmMISc" aria-haspopup="true">
                                                                    <button
                                                                        type="button"
                                                                        id="action-menu__toggle-report-action-menu__29ac8a87-2ece-47e7-8877-d1f41014e57e_1637756315167"
                                                                        aria-label="Action menu of Dosage Review report."
                                                                        aria-expanded="false"
                                                                        data-functional-selector="report-action-menu__toggle"
                                                                        className="sc-iBPRYJ mKjvP sc-eYRYCd hRiOnA"
                                                                    >
                                                                        {/* <span className="sc-eCssSg cAELPS cAELPSone" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                                            <svg id="action" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                                                <path
                                                                                    d="M16,10 C17.1045695,10 18,9.1045695 18,8 C18,6.8954305 17.1045695,6 16,6 C14.8954305,6 14,6.8954305 14,8 C14,9.1045695 14.8954305,10 16,10 Z M16,18 C17.1045695,18 18,17.1045695 18,16 C18,14.8954305 17.1045695,14 16,14 C14.8954305,14 14,14.8954305 14,16 C14,17.1045695 14.8954305,18 16,18 Z M16,26 C17.1045695,26 18,25.1045695 18,24 C18,22.8954305 17.1045695,22 16,22 C14.8954305,22 14,22.8954305 14,24 C14,25.1045695 14.8954305,26 16,26 Z"
                                                                                    style={{ fill: "rgb(51, 51, 51)" }}
                                                                                ></path>
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sc-kstrdz sc-hBEYos fMrbYi dWjUC">
                                                        {summary.map((summary) => <>

                                                            <h2 className="sc-jNwxvY bLhQJy">
                                                                {summary.quize_name}
                                                                {/* <button type="button" aria-label="Rename report. Current report name. Dosage Review" data-functional-selector="report-summary-header__rename-button" className="sc-iBPRYJ kQjigY sc-hpSTqv fBfcAo">
    <span className="sc-eCssSg dDNlwv dDNlwvsone">
      <svg id="edit-reports-title" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
        <path
          d="M23.4091683,8.594 C24.1731683,9.359 24.1731683,10.693 23.4091683,11.458 L22.6901683,12.178 L19.8241683,9.313 L20.5451683,8.594 C21.3101683,7.83 22.6441683,7.829 23.4091683,8.594 L23.4091683,8.594 Z M8.37616832,23.626 L8.97116832,21.252 L10.7501683,23.033 L8.37616832,23.626 Z M18.4111683,10.728 L21.2741683,13.591 L12.7061683,22.159 L9.84316832,19.296 L18.4111683,10.728 Z M24.8211683,7.179 C24.0611683,6.418 23.0501683,6 21.9741683,6 C20.9001683,6 19.8881683,6.418 19.1281683,7.179 L7.71916832,18.587 C7.71916832,18.587 7.71816832,18.59 7.71716832,18.591 C7.63616832,18.673 7.49716832,18.889 7.45616832,19.052 L6.03016832,24.757 C5.94416832,25.097 6.04516832,25.458 6.29316832,25.706 L6.29416832,25.707 L6.29616832,25.708 C6.54416832,25.956 6.90416832,26.057 7.24516832,25.972 L12.9491683,24.545 C13.1121683,24.504 13.3291683,24.365 13.4101683,24.284 C13.4111683,24.283 13.4141683,24.282 13.4141683,24.282 L24.8231683,12.873 C25.5831683,12.113 26.0021683,11.102 26.0011683,10.027 C26.0011683,8.951 25.5831683,7.941 24.8231683,7.18 L24.8211683,7.179 Z"
          style={{ fill: 'rgb(51, 51, 51)' }}
        />
      </svg>
    </span>
  </button> */}
                                                            </h2>
                                                        </>)}
                                                    </div>
                                                    <ul className="sc-eSNMKe gNRSAT sc-fUSoCb kJIzVF sc-dYzDLp UqdGH" data-functional-selector="tabs" role="tabpanel">
                                                        <RouterLink to={"/teacher/reports/question_summary/" + splitLocation[4]}>
                                                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" aria-label="Summary: An overview of game results." className={splitLocation[3] === "question_summary" ? "active_navbar" : "deflt_navbar"}>
                                                                    <span className="sc-hKizKw ceBZw">Summary</span>
                                                                    <span className="sc-dRKXJR dkNZJN"></span>
                                                                </button>
                                                            </li>
                                                        </RouterLink>
                                                        <RouterLink to={"/teacher/reports/question_players/" + splitLocation[4]}>
                                                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" aria-label="Players: Display the results of players." className={splitLocation[3] === "question_players" ? "active_navbar" : "deflt_navbar"}>
                                                                    <span className="sc-hKizKw ceBZw">Players ({players ? players.length : ''})</span>
                                                                </button>
                                                            </li>
                                                        </RouterLink>
                                                        <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                            <RouterLink to={"/teacher/reports/question_list/" + splitLocation[4]}>
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="questions" aria-label="Questions: Display the overview of 7 questions." className={splitLocation[3] === "question_list" ? "active_navbar" : "deflt_navbar"}>
                                                                    <span className="sc-hKizKw ceBZw">Questions ({questions ? questions.length : ''})</span>
                                                                </button>
                                                            </RouterLink>
                                                        </li>
                                                        {/* <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                      <RouterLink to="/records/question_feedback">
                                                        <button type="submit" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="feedback" aria-label="Feedback: Display feedback from players." className={splitLocation[3] === "question_feedback" ? "active_navbar" : "deflt_navbar"}>
                                                            <span className="sc-hKizKw ceBZw">Feedback</span>
                                                        </button>
                                                      </RouterLink>
                                                    </li> */}
                                                    </ul>
                                                </section>
                                                <section data-functional-selector="report-summary-header__right-panel" className="sc-hOPlpL dXIcKz">
                                                    {/* <div className="sc-impOMm egUcsT"></div> */}
                                                    {summary.map((summary) => <>
                                                        <div data-functional-selector="report-summary-header__game-type" className="sc-liZemx dcdvzA">
                                                            {console.log('summary', summary)}
                                                            {summary.mode == 0 ? 'Offline' : 'Live'}
                                                            {/* <img
                                                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMi4yNSIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDMpIj4KICAgICAgICA8bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+CiAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMyNjg5MEMiIGQ9Ik05LjI4MSA5LjI4MWg4LjQzOHY2LjE4OGEyLjI1IDIuMjUgMCAwIDEtMi4yNSAyLjI1SDkuMjhWOS4yOHoiIG1hc2s9InVybCgjYikiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjRkZBNjAyIiBkPSJNLjI4MSA5LjI4MUg4LjcydjguNDM4SDIuNTNhMi4yNSAyLjI1IDAgMCAxLTIuMjUtMi4yNVY5LjI4eiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMxMzY4Q0UiIGQ9Ik05LjI4MS4yODFoNi4xODhhMi4yNSAyLjI1IDAgMCAxIDIuMjUgMi4yNVY4LjcySDkuMjhWLjI4eiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNFMjFCM0MiIGQ9Ik0yLjUzMS4yODFIOC43MlY4LjcySC4yOFYyLjUzYTIuMjUgMi4yNSAwIDAgMSAyLjI1LTIuMjV6IiBtYXNrPSJ1cmwoI2IpIi8+CiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTEuNjQ0IDExLjY0NGgzLjM5djMuMzloLTMuMzl6TTQuNjYgMTUuNDExYTIuMDcyIDIuMDcyIDAgMSAxIDAtNC4xNDMgMi4wNzIgMi4wNzIgMCAwIDEgMCA0LjE0M3pNMTEuMDggNC42NjFsMi4yNi0yLjI2IDIuMjYgMi4yNi0yLjI2IDIuMjZ6TTYuNzMyIDYuMjczSDIuNTlsMi4wNzItMy4zMDd6IiBtYXNrPSJ1cmwoI2IpIi8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE3LjQzOCIgaGVpZ2h0PSIxNy40MzgiIHg9Ii4yODEiIHk9Ii4yODEiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTYzIiBtYXNrPSJ1cmwoI2IpIiByeD0iMi4yNSIvPgogICAgPC9nPgo8L3N2Zz4K"
                                                        className="sc-kMyZBz bcbmhN"
                                                    /> */}
                                                        </div>
                                                        <div data-functional-selector="report-summary-header__challenge-started" className="sc-liZemx dcdvzA">{summary.created_at}</div>
                                                        <div data-functional-selector="report-summary-header__username" className="sc-liZemx dcdvzA">Hosted by {summary.host_name}</div>
                                                    </>)}
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-izaQbr gytZrp" id="summary">
                                    <div className="sc-kstrdz sc-hBEYos fZENZC dWjUC" width="100%" height="fit-content">
                                        <section className="sc-kkJYca hImLkN">
                                            <div className="sc-kfpuCF lhPMXp">
                                                <div className="sc-kUKgKq cvAZyL">
                                                    <div className="sc-kstrdz sc-hBEYos sc-igRjXc gFMnBB dWjUC zqCWL">
                                                        <ul className="sc-eSNMKe gNRSAT sc-fUSoCb kragIp" data-functional-selector="report-players-table__result-type" role="tabpanel">
                                                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" data-functional-selector="all active" aria-label="Display the game data for all 2 players." className="sc-iAjKSQ jtrJfF active_players">
                                                                    <span className="sc-hKizKw ceBZw ">All ({players ? players.length : ''})</span>
                                                                    <span className="sc-dRKXJR dkNZJN" />
                                                                </button>
                                                            </li>
                                                            {/* <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button
                                                                  type="submit"
                                                                  color="#6E6E6E"
                                                                  role="tab"
                                                                  aria-selected="false"
                                                                  data-functional-selector="needHelp"
                                                                  aria-label="Display the game data of 2 players who had low average answers accuracy."
                                                                  className="sc-iAjKSQ kQSqeY no_active_players"
                                                                >
                                                                    <span className="sc-hKizKw ceBZw">Need help (2)</span>
                                                                    <span className="sc-dRKXJR dkNZJN" />
                                                                </button>
                                                            </li> */}
                                                            {/* <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                                                <button
                                                                    type="submit"
                                                                    color="#6E6E6E"
                                                                    role="tab"
                                                                    aria-selected="false"
                                                                    data-functional-selector="unfinished"
                                                                    aria-label="Display the game data of 2 players who didn’t complete this kahoot."
                                                                    className="sc-iAjKSQ kQSqeY no_active_players"
                                                                >
                                                                    <span className="sc-hKizKw ceBZw">Didn't finish (2)</span>
                                                                    <span className="sc-dRKXJR dkNZJN"></span>
                                                                </button>
                                                            </li> */}
                                                        </ul>
                                                        {/* <div width="100%" data-functional-selector="__search-field" className="sc-kstrdz sc-hBEYos bjPvep dWjUC">
                                                            <div className="sc-eZuTdo BaPLM"><input placeholder="Search" id="search" className="sc-eTLWQi cLqsro" aria-label="Search for the phrase in the table." dir="auto" value="" /></div>
                                                        </div> */}
                                                    </div>
                                                    <table
                                                        data-functional-selector="report-players-table"
                                                        aria-label="Players performance table. You can sort the table by several filters, including players’ nicknames and their final scores. "
                                                        className="sc-jDWdzH gwyuLI"
                                                        role="table"
                                                    >
                                                        <thead data-functional-selector="report-players-table__header" className="sc-kYpqvE fnvHVt">
                                                            <tr data-functional-selector="report-players-table__header-row" className="sc-gDRTSU dUiVwh">
                                                                <th role="columnheader" title="Toggle SortBy" id="nickname" width="30" color="#333" data-functional-selector="nickname" className="sc-hyngy fqddrJ nickname" style={{ cursor: 'pointer' }}>
                                                                    <button type="submit" aria-label="Sort table by player nicknames, from A to Z." className="sc-eDqjnT fGWQjS">
                                                                        Nickname
                                                                        {/* <span className="sc-eCssSg cAELPS table-sort-icon set_arrow" style={{ display: 'inline-block', width: '24px', height: '24px' }}>
                                                                            <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: 'rgb(51, 51, 51)' }} />
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </th>
                                                                <th role="columnheader" title="Toggle SortBy" id="rank" width="150" color="#46178f" data-functional-selector="rank" className="sc-gEjIJW geKzkc nickrank" style={{ cursor: 'pointer' }}>
                                                                    <button type="submit" aria-label="Sort table by player rank in ascending order." className="sc-eDqjnT fioqoJ">
                                                                        Rank
                                                                        {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', width: '24px', height: '24px' }}>
                                                                            <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: 'rgb(70, 23, 143)' }} />
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </th>
                                                                <th role="columnheader" title="Toggle SortBy" id="averageAccuracy" width="150" color="#333" data-functional-selector="averageAccuracy" className="sc-gEjIJW gumDV nickcans" style={{ cursor: 'pointer' }}>
                                                                    <button type="submit" aria-label="Sort table by percentage of correct answers in ascending order." className="sc-eDqjnT fioqoJ">
                                                                        Correct answers
                                                                        {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', width: '24px', height: '24px' }}>
                                                                            <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: 'rgb(51, 51, 51)' }} />
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </th>
                                                                <th role="columnheader" title="Toggle SortBy" id="unansweredCount" width="150" color="#333" data-functional-selector="unansweredCount" className="sc-gEjIJW gumDV nickunaw" style={{ cursor: 'pointer' }}>
                                                                    <button type="submit" aria-label="Sort table by number of unanswered questions in ascending order." className="sc-eDqjnT unanswerfioqoJ">
                                                                        Unanswered
                                                                        {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', width: '24px', height: '24px' }}>
                                                                            <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: 'rgb(51, 51, 51)' }} />
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </th>
                                                                <th role="columnheader" title="Toggle SortBy" id="totalPoints" width="150" color="#333" data-functional-selector="totalPoints" className="sc-gEjIJW gumDV nickfinal" style={{ cursor: 'pointer' }}>
                                                                    <button type="submit" aria-label="Sort table by final score in ascending order." className="sc-eDqjnT unanswerfioqoJ">
                                                                        Final score
                                                                        {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', width: '24px', height: '24px' }}>
                                                                            <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: 'rgb(51, 51, 51)' }} />
                                                                            </svg>
                                                                        </span> */}
                                                                    </button>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="sc-eeLDed lmEVNl">
                                                            {players.length == 0 ? <tr> <td style={{ textAlign: "center" }}>No Data Found</td></tr> : players.map((players, pid) => (<>
                                                                <tr key={pid} onClick={() => { handleShow(players.id, pid + 1) }} data-functional-selector="report-players-table__row" id="row-0" role="row" className="sc-gDRTSU dUiVwh">
                                                                    <td data-functional-selector="nickname" id="row-0-cell-0" role="cell" width="30%" className="sc-kZJQap zkIsh"><b>{players.nick_name}</b></td>
                                                                    <td data-functional-selector="rank" id="row-0-cell-1" role="cell" width="150" className="sc-kZJQap vWLXq">{pid + 1}</td>
                                                                    <td data-functional-selector="averageAccuracy" id="row-0-cell-2" role="cell" width="150" className="sc-kZJQap vWLXq">
                                                                        <div className="sc-kstrdz sc-hBEYos QvkTT dWjUC">
                                                                            <div height="30" data-functional-selector="progress-icon" className="sc-ftEBqf bMHYYe qt">
                                                                                <CircularProgressbar
                                                                                    value={players.total_correct_answer_percentage?players.total_correct_answer_percentage.toFixed(0):0}
                                                                                    styles={buildStyles({
                                                                                        textColor: "red",
                                                                                        pathColor: "green",
                                                                                        trailColor: "red",
                                                                                        strokeLinecap: "butt"
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <span className="sc-bzDAFm eiJKxS">{players.total_correct_answer_percentage?players.total_correct_answer_percentage.toFixed(0):0}%</span>
                                                                        </div>
                                                                    </td>
                                                                    <td data-functional-selector="unansweredCount" id="row-0-cell-3" role="cell" width="150" value="6" className="sc-fARevN gEDXQp">{players.unanswered}</td>
                                                                    <td data-functional-selector="totalPoints" id="row-0-cell-4" role="cell" width="150" value="842" className="sc-fARevN gEDXQp">{players.total_score}</td>
                                                                </tr>
                                                                <Modal className="playerleft" show={show} onHide={handleClose} animation={true}>
                                                                    {modalValue.length == 0 ? <tr> <td style={{ textAlign: "center" }}>No Data Found</td></tr> : modalValue.map((modal, mid) => (<>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                <h2 key={mid} height="3rem" className="sc-fFubgz jHympw">
                                                                                    <div data-functional-selector="player-detail-dialog__header" className="sc-kstrdz sc-hBEYos jIjhfa dWjUC">
                                                                                        <div className="sc-kstrdz sc-hBEYos QvkTT dWjUC">
                                                                                            <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '30px', height: '30px' }} >
                                                                                                <svg id="player" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                    <path
                                                                                                        d="M16,14 C17.6568542,14 19,12.6568542 19,11 C19,9.34314575 17.6568542,8 16,8 C14.3431458,8 13,9.34314575 13,11 C13,12.6568542 14.3431458,14 16,14 Z M16,16 C13.2385763,16 11,13.7614237 11,11 C11,8.23857625 13.2385763,6 16,6 C18.7614237,6 21,8.23857625 21,11 C21,13.7614237 18.7614237,16 16,16 Z M16,19 C13.0269583,19 10.5610282,20.4075601 9.52516483,22.3125 L22.4748352,22.3125 C21.4389718,20.4075601 18.9730417,19 16,19 Z M25,24.3125 L7,24.3125 C7,20.2739178 11.0294373,17 16,17 C20.9705627,17 25,20.2739178 25,24.3125 Z"
                                                                                                        style={{ fill: "rgb(51, 51, 51)" }}
                                                                                                    ></path>
                                                                                                </svg>
                                                                                            </span>
                                                                                            <div className="sc-kstrdz sc-hBEYos sc-fLVvar jlvGrj dWjUC hfLuqx">
                                                                                                <span data-functional-selector="player-detail-dialog__header-title" className="sc-gHTQPn jvWjvg">{modal.nick_name}</span><span data-functional-selector="player-detail-dialog__header-participant-id" className="sc-iBYcEi dxfBmt"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </h2></Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <div data-functional-selector="player-detail-dialog" className="sc-crrsfI gpBdAQ">
                                                                                <div overflow="hidden" className="sc-kstrdz sc-hBEYos iwejKI dWjUC">
                                                                                    {/* <nav height="3rem" className="sc-fodVxV foWPIL">
                                                                        <div font-size="14px" data-functional-selector="individual-player-header-text" className="sc-kstrdz DPrPM">1 of 2</div>
                                                                        <button aria-label="Show previous player details" data-functional-selector="individual-player-header-prev" tabindex="0" className="sc-iBPRYJ mKjvP sc-bkzZxe cSnsVU" disabled="">
                                                                            <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}                >
                                                                                <svg id="arrow-left" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                                                    <path d="M19 24.415L10.586 16 19 7.586 20.414 9.001 13.414 16 20.414 23z" style={{ fill: "rgb(242, 242, 242)" }}></path>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                        <button aria-label="Show next player details" data-functional-selector="individual-player-header-next" tabindex="0" className="sc-iBPRYJ mKjvP sc-bkzZxe cSnsVU">
                                                                            <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}                >
                                                                                <svg id="arrow-right" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                    <path d="M12 24.415L10.586 23 17.586 16 10.586 9.001 12 7.586 20.414 16z" style={{ fill: "rgb(0, 0, 0)" }}></path>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </nav> */}
                                                                                    <div aria-live="polite" aria-atomic="true" className="sc-cbJgGQ foYdzq">
                                                                                        <div className="sc-gYUCyT cBZXuR">
                                                                                            <div aria-label="14% correct" data-functional-selector="player-detail-dialog-accuracy" className="sc-kBciBV jSztpD">
                                                                                                <div height="87.5" data-functional-selector="progress-icon" className="sc-ijtCEc kUxHCN">
                                                                                                    <CircularProgressbar
                                                                                                        value={modal.total_correct_answer_percentage?modal.total_correct_answer_percentage.toFixed(0):0}
                                                                                                        styles={buildStyles({
                                                                                                            textColor: "red",
                                                                                                            pathColor: "green",
                                                                                                            trailColor: "red"
                                                                                                        })}
                                                                                                    />
                                                                                                </div>
                                                                                                <div data-functional-selector="player-detail-dialog-accuracy-value" className="sc-kstrdz sc-hBEYos sc-eRXIQM jlvGrj dWjUC lkHTGe"><span className="sc-hafqcb gOwaOX">{modal.total_correct_answer_percentage?modal.total_correct_answer_percentage.toFixed(0):0}%</span><span className="sc-gfkmuA llWFvj">correct</span></div>
                                                                                            </div>
                                                                                            <div className="sc-iSYOnO hXCyGJ">
                                                                                                <div className="sc-dJJIpH glekqB">
                                                                                                    <div data-functional-selector="player-detail-dialog-rank" className="sc-gcWgJX inuJxF">
                                                                                                        <div className="sc-fibdcw gCHVpx">Rank</div>
                                                                                                        <div data-functional-selector="player-detail-dialog-rank-value" className="sc-jDSZjR eddqeb">
                                                                                                            <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}                                >
                                                                                                                <svg id="report-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                                    <path
                                                                                                                        d="m11,13.5l-4,0c-0.553,0 -1,0.448 -1,1l0,7c0,0.552 0.447,1 1,1l4,0c0.553,0 1,-0.448 1,-1l0,-7c0,-0.552 -0.447,-1 -1,-1m7,-4l-4,0c-0.553,0 -1,0.448 -1,1l0,11c0,0.552 0.447,1 1,1l4,0c0.553,0 1,-0.448 1,-1l0,-11c0,-0.552 -0.447,-1 -1,-1m7,5l-4,0c-0.553,0 -1,0.448 -1,1l0,6c0,0.552 0.447,1 1,1l4,0c0.553,0 1,-0.448 1,-1l0,-6c0,-0.552 -0.447,-1 -1,-1"
                                                                                                                        style={{ fill: "rgb(70, 23, 143)" }}
                                                                                                                    ></path>
                                                                                                                </svg>
                                                                                                            </span>
                                                                                                            {/* {mid + 1} */}
                                                                                                            {rank}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div data-functional-selector="player-detail-dialog-final-score" className="sc-gcWgJX inuJxF">
                                                                                                    <div className="sc-fibdcw gCHVpx">Final score</div>
                                                                                                    <div className="sc-jDSZjR eddqeb">
                                                                                                        <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}                                >
                                                                                                            <svg id="report-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                                <path
                                                                                                                    d="m16,6.307361c3.86,0 7,3.14 7,7c0,2.326 -1.145,4.384 -2.897,5.659l0.887,6.2c0.053,0.367 -0.104,0.734 -0.406,0.952c-0.173,0.125 -0.378,0.19 -0.584,0.19c-0.153,0 -0.306,-0.036 -0.448,-0.107l-3.552,-1.774l-3.553,1.776c-0.332,0.166 -0.73,0.134 -1.03,-0.084c-0.304,-0.218 -0.46,-0.584 -0.407,-0.953l0.886,-6.2c-1.752,-1.275 -2.896,-3.333 -2.896,-5.657c0,-3.86 3.14,-7 7,-7l0,-0.002zm2.222,13.634c-0.699,0.234 -1.445,0.366 -2.222,0.366c-0.778,0 -1.524,-0.133 -2.223,-0.37l-0.518,3.62l2.293,-1.146c0.282,-0.14 0.614,-0.14 0.895,0l2.293,1.146l-0.518,-3.616zm-3.222,-7.634l-3,0l2,2l-1,3l3,-2l3,2l-1,-3l2,-2l-3,0l-1,-3l-1,3z"
                                                                                                                    style={{ fill: "rgb(10, 163, 163)" }}
                                                                                                                ></path>
                                                                                                            </svg>
                                                                                                        </span>
                                                                                                        {modal.total_score}
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                </div>
                                                                                                <div className="sc-dJJIpH glekqB">
                                                                                                    <div data-functional-selector="player-detail-dialog-questions-answered" className="sc-gcWgJX inuJxF">
                                                                                                        <div className="sc-fibdcw gCHVpx">Final Score</div>
                                                                                                        <div data-functional-selector="player-detail-dialog-questions-answered-value" className="sc-jDSZjR eddqeb">
                                                                                                            <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}                                >
                                                                                                                <svg id="report-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                                    <path
                                                                                                                        d="m16,6.307361c3.86,0 7,3.14 7,7c0,2.326 -1.145,4.384 -2.897,5.659l0.887,6.2c0.053,0.367 -0.104,0.734 -0.406,0.952c-0.173,0.125 -0.378,0.19 -0.584,0.19c-0.153,0 -0.306,-0.036 -0.448,-0.107l-3.552,-1.774l-3.553,1.776c-0.332,0.166 -0.73,0.134 -1.03,-0.084c-0.304,-0.218 -0.46,-0.584 -0.407,-0.953l0.886,-6.2c-1.752,-1.275 -2.896,-3.333 -2.896,-5.657c0,-3.86 3.14,-7 7,-7l0,-0.002zm2.222,13.634c-0.699,0.234 -1.445,0.366 -2.222,0.366c-0.778,0 -1.524,-0.133 -2.223,-0.37l-0.518,3.62l2.293,-1.146c0.282,-0.14 0.614,-0.14 0.895,0l2.293,1.146l-0.518,-3.616zm-3.222,-7.634l-3,0l2,2l-1,3l3,-2l3,2l-1,-3l2,-2l-3,0l-1,-3l-1,3z"
                                                                                                                        style={{ fill: "rgb(10, 163, 163)" }}
                                                                                                                    ></path>
                                                                                                                </svg>
                                                                                                            </span>
                                                                                                            {modal.total_score}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="sc-cdakHB dUxlCj">
                                                                                            <div className="sc-fTyQHV lmoXqj">
                                                                                                <div className="sc-fYBPgz bYhnBY">
                                                                                                    <div className="sc-kstrdz sc-hBEYos sc-dtiVcP sc-dchgTC CpYVy dWjUC dmuweK bWqcub">
                                                                                                        {/* <div className="sc-WRemZ uHzUX">Sort by:</div> */}
                                                                                                        {/* <div className="sc-fXwYyN iFFrLx sc-jpSfyY dYvrvn">
                                                                                            <div data-functional-selector="player-detail-dialog__sort-mobile" className="sc-bIMZcE bmHBqe">
                                                                                                <div className="css-dzh7t3-container">
                                                                                                    <span id="react-select-30-live-region" className="css-7pg0cj-a11yText"></span><span aria-live="polite" aria-atomic="false" aria-relevant="additions text" className="css-7pg0cj-a11yText"></span>
                                                                                                    <div className="sc-ciagcW eMrLPg">
                                                                                                        <button color="#333" data-functional-selector="player-detail-dialog__sort-mobile__button" className="sc-jEYnBn zRVcl">
                                                                                                            <span>Questions</span>
                                                                                                            <div className="sc-kOtWFZ ZzzWf">
                                                                                                                <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                                    <svg id="sort-arrow" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                                        <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <table data-functional-selector="player-detail-dialog" aria-label="Game performance details table for: fds. You can sort table by question number, question type, answers and others" className="sc-fARevN lhlhNC" role="table">
                                                                                                    <thead data-functional-selector="player-detail-dialog__header" className="sc-bDDOyi ljnsMi">
                                                                                                        <tr data-functional-selector="player-detail-dialog__header-row" className="sc-lnYokC Mxxaa">
                                                                                                            <th role="columnheader" title="Toggle SortBy" id="blockIndex" width="28%" color="#46178f" data-functional-selector="blockIndex" className="sc-fhMsBk jLSKSu" style={{ cursor: "pointer" }}>
                                                                                                                <button tabindex="0" aria-selected="true" aria-label="Sort table by questions in ascending numerical order. " className="sc-jDEoIF idwVhV">
                                                                                                                    Questions
                                                                                                                    {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                        <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                            <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(70, 23, 143)" }}></path>
                                                                                                        </svg>
                                                                                                    </span> */}
                                                                                                                </button>
                                                                                                            </th>
                                                                                                            <th role="columnheader" title="Toggle SortBy" id="blockType" width="15%" color="#333" data-functional-selector="blockType" className="sc-fhMsBk WsONS" style={{ cursor: "pointer" }}>
                                                                                                                <button tabindex="0" aria-selected="false" aria-label="Sort table by question type, from A to Z." className="sc-jDEoIF idwVhV">
                                                                                                                    Type
                                                                                                                    {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                        <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                            <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                                                                        </svg>
                                                                                                    </span> */}
                                                                                                                </button>
                                                                                                            </th>
                                                                                                            <th role="columnheader" title="Toggle SortBy" id="displayText" width="35%" color="#333" data-functional-selector="displayText" className="sc-fhMsBk fCQXhL" style={{ cursor: "pointer" }}>
                                                                                                                <button tabindex="0" aria-selected="false" aria-label="Sort table by: Answered." className="sc-jDEoIF idwVhV">
                                                                                                                    Answered
                                                                                                                    {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                        <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                            <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ cursor: "pointer" }}></path>
                                                                                                        </svg>
                                                                                                    </span> */}
                                                                                                                </button>
                                                                                                            </th>
                                                                                                            <th role="columnheader" title="Toggle SortBy" id="status" width="20%" color="#333" data-functional-selector="status" className="sc-fhMsBk jTIOKU" style={{ cursor: "pointer" }}>
                                                                                                                <button tabindex="0" aria-selected="false" aria-label="Sort table by questions answered correctly listed first." className="sc-jDEoIF idwVhV">
                                                                                                                    Correct/Incorrect
                                                                                                                    {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                        <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                            <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ cursor: "pointer" }}></path>
                                                                                                        </svg>
                                                                                                    </span> */}
                                                                                                                </button>
                                                                                                            </th>
                                                                                                            <th role="columnheader" title="Toggle SortBy" id="reactionTime" width="10%" color="#333" data-functional-selector="reactionTime" className="sc-fhMsBk gNiLhQ" style={{ cursor: "pointer" }}>
                                                                                                                <button tabindex="0" aria-selected="false" aria-label="Sort table by time taken to answer each question. Quickest response first." className="sc-jDEoIF gtaNyG">
                                                                                                                    Time
                                                                                                                    {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                        <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                            <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                                                                        </svg>
                                                                                                    </span> */}
                                                                                                                </button>
                                                                                                            </th>
                                                                                                            <th role="columnheader" title="Toggle SortBy" id="points" width="12%" color="#333" data-functional-selector="points" className="sc-fhMsBk NPEZO" style={{ cursor: "pointer" }}>
                                                                                                                <button tabindex="0" aria-selected="false" aria-label="Sort table by number of points earned for each question. Most to least points." className="sc-jDEoIF gtaNyG">
                                                                                                                    Points
                                                                                                                    {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                                                                        <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                            <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                                                                        </svg>
                                                                                                    </span> */}
                                                                                                                </button>
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody className="sc-fZvKFD ejeoHz">
                                                                                                        {modal.user_info.map((minfo, infoid) => (<>
                                                                                                            <tr key={infoid} data-functional-selector="player-detail-dialog__row" id="row-0" role="row" className="sc-lnYokC Mxxaa">
                                                                                                                <td data-functional-selector="blockIndex" id="row-0-cell-0" role="cell" width="28%" className="sc-itOPoD bjzUqB">
                                                                                                                    <div className="sc-kstrdz sc-hBEYos sc-jviEiS jlvGrj dWjUC gMscIC">
                                                                                                                        <div className="sc-kstrdz jZvnDp">{infoid + 1}</div>
                                                                                                                        <span data-player-header-title="true" className="sc-dyOajv eVHEfi">{minfo.question_title}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td data-functional-selector="blockType" id="row-0-cell-1" role="cell" width="15%" className="sc-itOPoD bjzUqB"><div className="sc-kstrdz sc-hBEYos sc-jviEiS jlvGrj dWjUC gMscIC">Quiz</div></td>
                                                                                                                <td data-functional-selector="displayText" id="row-0-cell-2" role="cell" width="35%" value="[object Object]" className="sc-itOPoD bjzUqB">
                                                                                                                    <div className="sc-kstrdz sc-ixDLba jlvGrj ercQPG">
                                                                                                                        <div className="sc-Eryum gBPfxK">
                                                                                                                            <div color="#e21b3c" type="quiz" className={minfo.selected_answer_option_no_index == 0 ? "sc-clsHhM lmcoQi0" : minfo.selected_answer_option_no_index == 1 ? "sc-clsHhM hzVFqE1" : minfo.selected_answer_option_no_index == 2 ? "sc-clsHhM dFMQva2" : minfo.selected_answer_option_no_index == 3 ? "sc-clsHhM cFxYFM3" : 'sc-clsHhM blank'}>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="sc-kstrdz sc-hBEYos sc-knXvXO ksuEIT dWjUC djKKtX"><span className="sc-ekkLtS ggRiwX">{minfo.answer_option_value ? minfo.answer_option_value : 'No Answer'}</span></div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td data-functional-selector="status" id="row-0-cell-3" role="cell" width="20%" value="CORRECT" className="sc-itOPoD bjzUqB">
                                                                                                                    <div className="sc-kstrdz sc-ixDLba eaPROq ercQPG">
                                                                                                                        <div className="sc-bUWuLP iQusgV">
                                                                                                                            <div className="sc-Eryum hhCfhf">
                                                                                                                                {minfo.is_right == 1 ?
                                                                                                                                    <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                                                                                                        <svg id="correct-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                                                                                                            <path d="M12.926 22.652L8 17.725 9.767 15.957 12.841 19.031 21.959 9 23.808 10.682z" style={{ fill: "rgb(38, 137, 12)" }}></path>
                                                                                                                                        </svg>
                                                                                                                                    </span> :
                                                                                                                                    <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                                                                                                        <svg id="timeout-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                                                                                                            <path d="M24.714 8.414L23.3 7 16.007 14.293 8.714 7 7.3 8.414 14.593 15.707 7.3 23 8.714 24.414 16.007 17.122 23.3 24.414 24.714 23 17.421 15.707z" style={{ fill: "rgb(226, 27, 60)" }}></path>
                                                                                                                                        </svg>
                                                                                                                                    </span>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <span className="sc-fqiHbb hRzoXK">{minfo.is_right == 1 ? 'Correct' : 'Incorrect'}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td data-functional-selector="reactionTime" id="row-0-cell-4" role="cell" width="10%" value="[object Object]" className="sc-itOPoD hjstLm">
                                                                                                                    <div className="sc-kstrdz sc-hBEYos sc-jviEiS eCTved dWjUC gMscIC"><div className="sc-kstrdz sc-hBEYos sc-bIyklt irtDPl dWjUC gqufWF">{minfo.time_taken}s</div></div>
                                                                                                                </td>
                                                                                                                <td data-functional-selector="points" id="row-0-cell-5" role="cell" width="12%" value="842" className="sc-itOPoD hjstLm"><div className="sc-kstrdz sc-hBEYos sc-jviEiS eCTved dWjUC gMscIC">{minfo.points}</div></td>
                                                                                                            </tr>
                                                                                                        </>))}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Modal.Body>
                                                                        {/* <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={handleClose}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer> */}
                                                                    </>))}
                                                                </Modal>
                                                            </>))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default QuestionPlayers;
