/* eslint-disable */
import React,{useEffect,useState} from 'react';
import Page from 'src/components/Page';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import global from 'src/utils/global';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { adminGetTraining, adminDeleteTraining } from 'src/utils/Api';
// import {Dialog,DialogActions,DialogContent} from '@material-ui/core';
import {
  Avatar,
  Box,
  Container,
  makeStyles,
  Card,
  SvgIcon,
  TextField,
  CardContent,
  InputAdornment,
  CircularProgress,
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import cogoToast from 'cogo-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));
const headCells = [
  {
    id: 's_no', numeric: false, disablePadding: false, label: 'Sno', sotable: true
  },
  {
    id: 'title', numeric: false, disablePadding: false, label: 'Title', sotable: true
  },
  {
    id: 'des_content', numeric: false, disablePadding: false, label: 'Description', sotable: true
  },
  {
    id: 'video_type', numeric: false, disablePadding: false, label: 'Video_Type', sotable: true
  },
  {
    id: 'content', numeric: false, disablePadding: false, label: 'Content', sotable: true
  },
  {
    id: 'action', numeric: false, disablePadding: false, label: 'Action', sotable: false
  },
];
function EnhancedTableHead(props) {
  const {
    classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {
              headCell.sotable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const ListTraining = () => {
  const classes = useStyles();
  const [list , setList] = useState([])
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [delModal, setDelModal] = useState({view:false,_id:''});
  const [isLoading, setIsLoading] = useState(false);
  const [delUser, setDelUser] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('video_type');
  const [selected, setSelected] = useState(['']);
  const [trigger, setTrigger] = useState(false)
  const navigate = useNavigate();
  // const [users, setUsers] = useState(customers);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const ToggleStatus = (event, id) => {
    console.log('toggle status +++++++++');
    console.log(event.target.value);
    updateStatus(event.target.value, id);
  };
  const removeUser = (user) => {
    setDelUser(user);
    console.log(user);
    setDelModal(true);
  };
  const removeUserToggleApi = () => {
    setIsLoading(true);
    console.log(delUser);
    removeUserApi(delUser).then((res) => {
      console.log(res.state);
      if (res.state) {
        setIsLoading(false);
        setDelModal(false);
        cogoToast.success('User is removed!', { position: 'top-right' });
        updateD();
      } else {
        setIsLoading(false);
        setDelModal(false);
        cogoToast.success('Something error, please try again.', { position: 'top-right' });
      }
    }).catch(() => {
      cogoToast.warn('Network issue, Please try again later', { position: 'top-right' });
    });
    setTimeout(() => {
      setIsLoading(false);
      setDelModal(false);
    });
  };

  const handleRequestSort = (event, property) => {
    console.log(property);
    const isAsc = orderBy === property && order === 'asc';
    console.log(isAsc);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const tempUsers = users;
    tempUsers.sort((a, b) => {
      const x = a[orderBy] ? a[orderBy].toLowerCase() : '';
      const y = b[orderBy] ? b[orderBy].toLowerCase() : '';
      if (order === 'asc') {
        if (x > y) { return -1; }
        if (x < y) { return 1; }
      } else {
        if (x < y) { return -1; }
        if (x > y) { return 1; }
      }
      return 0;
    });
    setUsers(tempUsers);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
 

  useEffect(() => {
    //   setUsers(customers);
    console.log("hello")
    adminGetTraining().then((res)=>{
      console.log("resssp",res)
      setList(res.data)
        // return cogoToast.error(' No Video Found', { position: 'top-right' });
    
    })
  }, [trigger]);

  const deleteVideo = (data) => {
    adminDeleteTraining(data).then((res)=>{
      setDelModal({view:false,_id:''})
      console.log("ddeded",res)
      setTrigger(true)
    })
  }

  return (
    <Page
      className={classes.root}
      title="Training List"
    >
      <Container maxWidth={false}>
        <Card >
          <CardContent className={classes.header}>
            <Box maxWidth={500}>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                All Training Videos

              </Typography>

            </Box>


          </CardContent>
        </Card>
        <Card style={{ marginTop: "20px" }}>
          <Dialog
                open={delModal.view}
                onClose={() => { setDelModal({view:false,_id:''}); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                key="hostModal"
            >
                <DialogTitle id="alert-dialog-title">Really?</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure to remove video
                    {}
                    <span>{  }</span>
                    ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setDelModal({view:false,_id:''})} style={{ backgroundColor: '#0C003C', color: 'white' }}>
                    Cancel
                </Button>
                <Button onClick={()=>{deleteVideo(delModal)}} style={{ background: 'red', color: 'white' }}>
                    Delete
                    { isLoading && <CircularProgress color="nice" size={20} className="progress" />}
                </Button>
                </DialogActions>
            </Dialog>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                // rowCount={users.length}
                />

                <TableBody>
                {list && list.length >0 ? list.map((item)=>{
                 return (
                   <TableRow >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell><textarea value={item.des_content}/></TableCell>
                    <TableCell>{item.video_type}</TableCell>
                    <TableCell>
                    { item.video_content.includes("http") ? (<iframe src={`${item.video_content}`} height={100} width={200}></iframe>)
                  : (<iframe src={`${global.serverUrl}upload/training_video/${item.video_content}`} height={100} width={200} ></iframe>)}
                      
                      </TableCell>
                    <TableCell style={{marginTop:"12px"}} >
                      <Button 
                       style={{ backgroundColor: '#0C003C', color: 'white',marginBottom:"5px"}}
                       onClick={()=>{navigate(`/admin/edit-training/${item.id}`)}} >
                        Edit
                      </Button>
                       <Button style={{backgroundColor: '#FF0000', color: 'white' }} onClick={()=>setDelModal({view:true,_id:item.id})} >
                         Delete
                       </Button>
                    </TableCell>
                   </TableRow>
                  )}):""}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={list.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default ListTraining;


  