/*eslint-disable*/
//-------------------------------import's-----------------------------------//

import { Box, Container, Grid, Typography, TextField, Select, Avatar, makeStyles, Button } from '@material-ui/core';
import React from 'react';
import Page from 'src/components/Page';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate, useParams } from 'react-router';
import { adminAddTraining, adminEditTraining, adminUpdateTraining } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Loader from "react-js-loader";

//-----------------------------Styling Function-----------------------------//

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        height: 100,
        width: 100
    },
    fileModal: {
        display: 'none'
    },
    userName: {
        marginTop: 20,
    },
    icon: {
        marginTop: 15,
        color: 'red'
    },
}));

//--------------------------------Main Component--------------------------------//

const AddTraining = () => {

    //----------------------------State's And Variables-----------------------------//

    const classes = useStyles();
    const navigate = useNavigate();
    const _id = useParams().id | '';
    const [title, setTitle] = React.useState('');
    const [des_content, setDes_content] = React.useState('');
    const [video_type, setVideo_type] = React.useState('Virtual');
    const [url, setUrl] = React.useState('');
    const [videoFile, setVideoFile] = React.useState({ files: [] });
    const [isLoading, setIsLoading] = React.useState(false);
    const [videoInput, setVideoInput] = React.useState(1);
    const [trigger, setTrigger] = React.useState(false);

            const handleChange = (event, newAlignment) => {
                setVideo_type(newAlignment);
            };

    //-----------------------------------UseEffect Function---------------------------//

    React.useEffect(() => {

        if (_id) {
            adminEditTraining({ id: _id }).then((res) => {
                console.log("res", res)
                setTitle(res[0].title);
                setDes_content(res[0].des_content);
                setVideo_type(res[0].video_type);
                setUrl(res[0].video_content)
            })
        }

    }, [])

    //------------------------------------Handler Function------------------------------//



    const SaveData = (e) => {
        e.preventDefault();
        console.log("addtraining", video_type)
        if(title == '' || des_content == '' ||(url=='' && videoFile.files == '')){
            return cogoToast.error('All Fields are Required', { position: 'top-right' });
        }
        let formData = new FormData();

        formData.append("title", title);
        formData.append("des_content", des_content);
        formData.append("video_type", video_type);
        formData.append("url", url);
        formData.append("video", videoFile.files)
        setTrigger(true);
        if (_id) {
            formData.append("id", _id);
            console.log("updatetraining")
            adminUpdateTraining(formData).then((res) => {
                if (res.status === 200) {
                    navigate('/admin/view-training');
                }
            })
        } else {
            
            adminAddTraining(formData).then((res) => {
                console.log("addadadad",res)
                if (res.status === 200) {
                    navigate('/admin/view-training');
                }
            })
        }
    }

    //------------------------------------Return Function-----------------------------//

    return (
        <>
        {!trigger ? (
        <Page className={classes.root}
            title={!_id ? 'Add Training Video' : 'Update Training Video'}
        >
            <Box>
                <Grid container >
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {!_id ? "Add Training Video" : "Update Training Video"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <ToggleButtonGroup
                            color="primary"
                            value={video_type}
                            onChange={handleChange}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton value="Virtual" onClick={(e) => setVideo_type(e.target.value)}>Virtual</ToggleButton>
                            <ToggleButton value="Gamification" onClick={(e) => setVideo_type(e.target.value)}>Gamification</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <hr />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <Container maxWidth="lg" className='contentForm'>
                    <form enctype="multipart/form-data" onSubmit={(e) => SaveData(e)} className='upload-form'>
                        <Grid className='contentInput' item xs={12} sm={12} lg={12}>
                            <Grid className='contentLabel' style={{ paddingRight: 30, paddingTop: 50 }} item xs={12} sm={12} md={6}>
                                Content Title:
                            </Grid>
                            <Grid style={{ paddingLeft: 30 }} item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    label="Content Title"
                                    value={title}
                                    margin="normal"
                                    name="title"
                                    onChange={e => setTitle(e.target.value)}
                                    type="text"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid className='contentInput' item xs={12} sm={12} lg={12}>
                            <Grid className='contentLabel' style={{ paddingRight: 30, paddingTop: 50 }} item xs={12} sm={12} md={6}>
                                Content Description:
                            </Grid>
                            <Grid style={{ paddingLeft: 30 }} item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={des_content}
                                    label="Content"
                                    margin="normal"
                                    name="plan_amount"
                                    onChange={e => setDes_content(e.target.value)}
                                    type="text"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid className='contentDropdown' item xs={12} sm={12} lg={12}>
                            <Grid className='dropdownLabel' style={{ paddingRight: 30, paddingTop: 50 }} item xs={12} sm={12} md={6}>
                                Select Video Type
                            </Grid>
                            <Grid style={{ paddingLeft: 30, paddingTop: 40 }} item xs={12} sm={12} md={6}>
                                <Select
                                    fullWidth
                                    native
                                    onChange={e => setVideoInput(e.target.value)}
                                >
                                    <option value={1}>URL</option>
                                    <option value={2}>Upload Video</option>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid className='uploadMain' item xs={12} lg={6} style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                            {videoInput == 1 ?
                                <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6} lg={12}>
                                    <TextField
                                        fullWidth
                                        label="URL"
                                        value={url}
                                        margin="normal"
                                        name="plan_storage"
                                        onChange={e => { setUrl(e.target.value); setVideoFile({ files: [] }) }}
                                        type="text"
                                        variant="outlined"
                                    />
                                </Grid>
                                :
                                <>
                                    <Grid className='uploadItem' style={{ paddingLeft: 30 }} item xs={12} sm={4} md={4} lg={6}>
                                        <TextField
                                            margin="normal"
                                            files={videoFile.files}
                                            name="video"
                                            onChange={(e) => { setVideoFile({ files: e.target.files[0] }); setUrl('') }}
                                            type="file"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <Box mt={4} lg={6} className="dropdownButton">
                            <Button
                                size="large"
                                type="submit"
                                style={{ color: 'white', backgroundColor: '#0C003C' }}
                            >
                                {!_id ? "Add Video" : "Update Video"}
                                {isLoading && <CircularProgress color="nice" size={20} className="progress" />}
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Page >) : (
                <div><Loader
                    type="spinner-cub"
                    bgColor={"#FFFFFF"}
                    title={"Loading..."}
                    color={'#0c003c'}
                    size={100} /></div>
            )}
        </>
    );
}

//------------------------------Export Function----------------------------//

export default AddTraining;
