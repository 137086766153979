/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import global from 'src/utils/global';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Card,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CardMedia,
  CircularProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { signUp } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Page from 'src/components/Page';
import { width } from '@mui/system';
import { adminGetTrainingUser } from 'src/utils/Api';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 50
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const TrainingVideos = () => {
  const [gameData, setGameData] = React.useState([])
  const [virtualData, setVirualData] = React.useState([])
  const classes = useStyles();
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    adminGetTrainingUser().then((res) => {
      console.log("training Data", res);
      setVirualData(res.virtual_data);
      setGameData(res.gamification_data);
    })
  },[])
  return (
    <Page
      className={classes.root}
      title="Training Videos"
    >
      <Grid container >
        <Grid className='trainImg' item xs={12} md={4}>

        </Grid>
        <Grid className='sectionFirst' item xs={12} md={8}>
          <Grid className='sectionFirstChild'>
            <h5 style={{ paddingBottom: 15, fontWeight: 700 }}>
              Brainaly Was Created In A Classroom, Not A Boardroom.
              That’s Important.
            </h5>
            <Typography>
              <h2 style={{ fontWeight: 700 }}>Tutorials</h2>
            </Typography>
            <Typography style={{ fontSize: 18, marginBottom: 15 }}>
              Our tutorials provide you with an introduction to Brainaly. These short videos highlight key features that help you teach, and help students learn.
            </Typography>
            <Typography style={{ fontSize: 18 }}>
              Also visit our searchable Knowledge Base for help on using specific features of Brainaly.
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid className='overviw_main'>
        <Grid container style={{ paddingTop: 30 }} >
          <Grid className='sectionsecond' item xs={12} md={6}>
            <Grid className='setStudent'>
              <h1 style={{ fontWeight: 700 }}>Student Overview</h1>
              <Typography style={{ maxWidth: 450 }}>
                This is where we introduce students to BigBlueButton, we’ll show them how the tools work and how to take advantage of the BigBlueButton learning environment to meet and exceed their educational goals.
              </Typography>
            </Grid>

          </Grid>
          <Grid className='sectionsecond' item xs={12} md={6}>
            <iframe src="https://www.youtube.com/embed/Yw6u6YkTgQ4" className="video_frame"></iframe>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: 30 }} >
          <Grid className='sectionsecond' item xs={12} md={6}>
            <Grid className='setStudent'>
              <h1 style={{ fontWeight: 700 }}>Instructor Overview</h1>
              <Typography style={{ maxWidth: 450 }}>
                This quick introduction will show you how BigBlueButton improves online learning by letting you easily create a more cohesive online classroom experience for students.
              </Typography>
            </Grid>
          </Grid>
          <Grid className='sectionsecond' item xs={12} md={6}>
          <iframe src="https://www.youtube.com/embed/Yw6u6YkTgQ4" className="video_frame"></iframe>
          </Grid>
        </Grid>
        <Grid style={{ marginTop:45 }}>
          <h3 className='overviewiner_head'>Virtual Classroom</h3>

          <Grid container spacing={2} style={{ paddingTop: 30,justifyContent:'left' }}  className='sectionmain'>
            {virtualData && virtualData.length>0 ? virtualData.map((item) => {
              return (
                <Grid className='sectionsecond' item xs={12} md={6} style={{paddingTop:40}}>
                <Grid className='setStudent'>
                  
                  { item.video_content.includes("http") ? (<iframe src={`${item.video_content}`} className="video_frame" ></iframe>)
                  : (<iframe src={`${global.serverUrl}upload/training_video/${item.video_content}`} className="video_frame" ></iframe>)}

                  {/* <div style={{ backgroundImage: "url(`${item.video_content}`)" }}></div> */}
                  <h3 className='overview_heading' style={{ fontWeight: 700 }}>{item.title}</h3>
                  <Typography className='overview_content' style={{ maxWidth: 500 }}>{item.des_content}</Typography>
                </Grid>
                </Grid>
              )
            }) : ""}
          </Grid>
        </Grid>
        <Grid style={{ marginTop:45 }}>
        <h3 className='overviewiner_head'>Gamification Features</h3>

        <Grid container spacing={2} style={{ paddingTop: 30,justifyContent:'left' }} className='sectionmain' >
          {gameData && gameData.length ? gameData.map((item) => {
            return (
              <Grid className='sectionsecond' item xs={12} md={6}>
              <Grid className='setStudent'>
              { item.video_content.includes("http") ? (<iframe src={`${item.video_content}`} className="video_frame"></iframe>)
                  : (<iframe src={`${global.serverUrl}upload/training_video/${item.video_content}`} className="video_frame" ></iframe>)}
                <h3 className='overview_heading' style={{ fontWeight: 700 }}>{item.title}</h3>
                <Typography className='overview_content' style={{ maxWidth: 500 }}>{item.des_content}</Typography>
              </Grid>
              </Grid>
            )
          }) : ""}
        </Grid>
      </Grid>
    </Grid>

     

    </Page >
  );
}

export default TrainingVideos;
