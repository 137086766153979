/*eslint-disable*/
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { CircularLoading } from 'respinner';
import styles from "./pricing.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Loader from 'src/components/Loader';

const CheckoutForm = ({ payinfo, isLoading }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const result = await stripe.createToken(cardElement);

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      payinfo(result);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="form-group text-start mb-3">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
      </div>
      <div className="form-group text-start mb-3">
        <label for="exampleInputPassword1">Name on Card</label>
        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name on Card" />
      </div> */}
      <div className="form-group text-start mb-3">
      <label for="exampleInputEmail1">Card Details</label>
        <CardElement />
      </div>


      <div className="form-group text-start mb-3">
      {
          isLoading ? <CircularLoading fill="#01025C" stroke="#01025C" /> : (
            <button type="submit" disabled={!stripe} className="btn btn-primary w-100 mb-2" id="button-7">
            Subscribe
            </button>
          )
        }
      </div>
    </form>
  );
};

CheckoutForm.propTypes = {
  payinfo: PropTypes.func,
  isLoading: PropTypes.bool
};

export default CheckoutForm;
