/* eslint-disable */
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
const IntroSection = () => {
  return (
    <div >
        <div className="slide-carousel">
          <img src="/static/carousel/slide1.jpg"class="img-fluid" />
          <div className="carousel-content">
            <h2>The fun and engaging way to learn and teach without limits</h2>
            <p>Unleash the power of learning and teaching anywhere and anytime.</p>
            <div  style={{ paddingTop: '30px'}}>
            <RouterLink
                  className='carousel-button'  
                  style={{marginRight:'20px'}}
                  to="/signup"
                  >
                  {" "} 
                  Sign Up for Free
                
                 </RouterLink>
            <RouterLink
                  className='carousel-button'  
                  to="/signin"
                  >
                  {" "} 
                  Log In
                
                 </RouterLink>

            </div>
                  
          </div>
        </div>
        {/* <div key="slide2" className="slide-carousel">
          <img src="/static/carousel/slide2.jpg" />
          <div className="slide-carousel-content">
            <h2>Gamify your learning experience and increase engagement with the #1 eLearning platform for teachers and students</h2>
            <p>Our virtual classroom and quiz gamification software is the perfect way to keep students entertained while they learn.</p>
            <RouterLink
                  className='carousel-button'  
                  to="/signup"
                  >
                  {" "} 
                  Sign Up for Free
                
                 </RouterLink>
            <RouterLink
                  className='carousel-button'  
                  to="/signin"
                  >
                  {" "} 
                  Log In
                
                 </RouterLink>         
          </div>
          
        </div> */}
      {/* <div className="scrolldown scrolldown-container">
        <a href="#joinsection">
          <span />
          Scroll
        </a>
      </div> */}
    </div>
  );
};

export default IntroSection;
