/*eslint-disable*/
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CircularProgress,
  MenuItem,
  Typography,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle

} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { signUp } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Page from 'src/components/Page';
import { getBlog, deleteBlog, } from 'src/utils/Api';
import global from 'src/utils/global';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 50
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    maxWidth: 345,
    maxHeight:355
  },
  media: {
    height: 140,
  },
  blog_content: {
    display: '-webkit-box',
    textAlign: 'left',
    width: '100% !important'
  },
  gridContainer: {
    marginTop: '25px',
    paddingLeft: "40px",
    paddingRight: "40px",
    justifyContent: 'left !important',
    marginBottom: '25px',
    textAlign: 'left'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));

const ViewBlog = () => {

  const [blogContent, setBlogTitle] = React.useState();
  const classes = useStyles();
  const [delModal, setDelModal] = React.useState({ view: false, _id: '' });
  const [trigger, setTrigger] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    getBlog().then((res) => {
      // console.log('blogdata ', res)
      setBlogTitle(res.data)
    }).catch((err) => {
      // console.log('catcherr', err)
    })
    setTrigger(false)
  }, [trigger])

  //------------------------------------UseState and other Constant's-----------------------------//


  var module = document.querySelector(".title_desc");

  // $clamp(module, {clamp: 3});

  //-------------------------------Handle Function's--------------------------//

  const handleEdit = (id) => {
    navigate(`/admin/edit-blog/${id}`);
  }

  function createMarkup(data) {
    return { __html: data };
  }

  const handleDelete = (modal) => {

    deleteBlog(modal._id).then(res => {
      setDelModal({ view: false, _id: '' })
      setTrigger(true);
    })
  }
  const handleCard = (title, id) => {
    // console.log("title", title);
 
  }
  function createMarkup(data) {
    return { __html: data };
  }

  //-------------------------RETURN-----------------------------------//

  return (
    <Page
      className={classes.root}
      title="All Blog"
    >
      <Dialog
        open={delModal.view}
        onClose={() => { setDelModal({ view: false, _id: '' }); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        key="hostModal"
      >
        <DialogTitle id="alert-dialog-title">Really?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to remove video
            { }
            <span>{ }</span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDelModal({ view: false, _id: '' })} style={{ backgroundColor: '#0C003C', color: 'white' }}>
            Cancel
          </Button>
          <Button onClick={() => { handleDelete(delModal) }} style={{ background: 'red', color: 'white' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth={false}>
        <Card >
          <CardContent className={classes.header}>
            <Box maxWidth={500}>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                All Blogs
              </Typography>
            </Box>

          </CardContent>
        </Card>
        <Grid
          container
          style={{ marginTop: "20px" }}
          spacing={4}
          className={classes.gridContainer}
        // style={{justifyContent:'left'}}
        >
          {blogContent?.length > 0 ? blogContent.map((item, index) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card className={`${classes.card} blog-card`} onClick={() => { handleCard(item.title, item.id) }}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={`${global.serverUrl}upload/blog/${item.image}`}
                    title={item.title}
                  />
                  <CardContent>
                    <Typography  className='title_blog' gutterBottom variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <Typography className='title_desc' component="p"
                    >
                    {item.title_desc}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => handleEdit(item.id)} size="small" style={{ background: '#0C003C', color: 'white' }}>
                      Edit
                    </Button>
                    <Button size="small" onClick={() => setDelModal({ view: true, _id: item.id })} style={{ background: 'red', color: 'white' }}>
                      Delete
                    </Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))
            :
            <Typography>No Records</Typography>}


        </Grid>
      </Container>

    </Page>
  );
}

export default ViewBlog;
