/*eslint-disable*/

import React from 'react';
import Page from 'src/components/Page';
import { Button } from 'react-bootstrap';
    import global from 'src/utils/global';
import '../user-blog/blog.css';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: theme.spacing(3),
      overflow: 'hidden'
    },
    cardMedia: {
      height: 600
    },
    boxContainer: {
      marginLeft: '5vw',
      marginRight: '5vw',
      marginBottom: 50
    },
    titleContainer: {
      marginTop: 40
    },
    contentContainer: {
      marginTop: 20
    },
    buttonContainer: {
      marginTop: 40
    },
    avatar: {
      width: 150,
      height: 150
    },
    card: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },


  }));
const Preview = () => {
    const classes = useStyles();

    //----------------UseState---------------------//

    const [title, setTitle] = React.useState();
    const [content, setContent] = React.useState();
    const [titleDesc, setTitleDesc] = React.useState();
    const [previewimage, setPreviewImage] = React.useState();
    const [image, setImage] = React.useState();

    //---------------------UseEffect--------------------//

    React.useEffect(() => {
        if (localStorage) {
            setTitle(localStorage.getItem("blogTitle"));
            console.log("blog dsdtitle",localStorage.getItem("blogTitle"))
            setTitleDesc(localStorage.getItem("blogTitleDESC"))
            setPreviewImage(localStorage.getItem("blogPreviewImage"))
            setImage(localStorage.getItem("blogImage"))
            setContent(localStorage.getItem("blogContent"));
           
        }
       
    }, [])

    //------------------------handlers-----------------------------//
   
    function createMarkup(data) {
        return { __html: data };
    }

    //------------------------return----------------------//
    return (
        <Page
        className={classes.root}
        title="View Blogs"
    >
        <div className="container">
            <div className="row" style={{maxWidth:833, display:'block', margin:'auto'}}>
                {title || titleDesc  || previewimage || content  ?
                <Card className='blog-card' onClick={()=>{handleCard(item.title,item.id)}}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={previewimage  != 'undefined'? previewimage : image !='undefined' ? `${global.serverUrl}upload/blog/${image}`:null}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                    {title == 'undefined' ? null : title}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                    {titleDesc == 'undefined' ? null : titleDesc}
                    </Typography>
                    
                    <Typography component="p" dangerouslySetInnerHTML={createMarkup(content == 'undefined' ? null : content)}>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
                    
                 : <p>Something Went Wrong</p>}

            </div>
        </div>
    </Page>

    );
}

export default Preview;
