/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { setSocket, emitEvent } from 'src/utils/socket';
import { getQuizList, getQuizPageApi, searchQuizApi } from 'src/utils/Api';
import Swinging from 'src/components/Swininging';
import authChecker from 'src/utils/authHelper';
import global from 'src/utils/global';
import Toolbar from 'src/views/product/ProductListView//Toolbar';
import ProductCard from 'src/views/product/ProductListView/ProductCard';
import { Button, Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));


const Studentgameplay = (props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [products, setProducts] = useState([]);
    const [proTotalNum, setProTotalNum] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [searchLoading, setSearchLoading] = useState(false);
    const handleClose = (value) => {
        props.setModal(value)
        setShow(value);
    }
    useEffect(() => {
      // if (!authChecker('authCheck')) {
      //   navigate('/', { replace: true });
      //   return;
      // }
      const user = JSON.parse(localStorage.getItem('brainaly_user'));
      async function getList() {
        await getQuizList({ userid: user.userId }).then((res) => {
          const productsArray = [];
          console.log('student summary result');
          console.log(res);
          setProTotalNum(Math.ceil(res.total / global.pageNationLimit));
          setPageNum(1);
          for (let i = 0; i < res.result.length; i++) {
            const newData = {
              title: res.result[i].q_name,
              length: JSON.parse(res.result[i].q_content).length,
              description: res.result[i].q_description,
              id: res.result[i].q_uid,
              media: res.result[i].q_cover === '' ? '/static/collection.png' : `${global.serverUrl}upload/${res.result[i].q_cover}`,
              created: res.result[i].q_created_at,
              played: res.result[i].q_play_num,
              q_uid: res.result[i].q_id
            };
            productsArray.push(newData);
          }
          var result = productsArray.filter((item)=>{
            return props.quiz_id == item.q_uid ?? item
          });
          console.log(result,'res++')
          setProducts(result);
        });
      }
      handleClose();
      getList();
      setSocket();
      emitEvent('connectRoom', user);
    }, []);
    async function getQuizPage(num) {
      const user = JSON.parse(localStorage.getItem('brainaly_user'));
      getQuizPageApi({ userid: user.userId, pageNum: num }).then((res) => {
        const productsArray = [];
        console.log(res);
        setPageNum(num);
        setProTotalNum(Math.ceil(res.total / global.pageNationLimit));
        for (let i = 0; i < res.result.length; i++) {
          const newData = {
            title: res.result[i].q_name,
            length: JSON.parse(res.result[i].q_content).length,
            description: res.result[i].q_description,
            id: res.result[i].q_uid,
            media: res.result[i].q_cover === '' ? '/static/collection.png' : `${global.serverUrl}upload/${res.result[i].q_cover}`,
            created: res.result[i].q_created_at,
            played: res.result[i].q_play_num,
            q_uid: res.result[i].q_id
          };
          productsArray.push(newData);
        }
        var result = productsArray.filter((item)=>{
            return props.quiz_id == item.q_uid ?? item
          });
          setProducts(result);
        // setProducts(productsArray);
      });
    }
    
    return (

            <Modal className="modalset" show={props.modalval} onHide={() => { handleClose(false) }}>
                <Modal.Header className="modal_head">
                    <Modal.Title>Play Again
                    <Button className="closebtn" variant="secondary" onClick={() => { handleClose(false) }}>
                        Close
                    </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalcent'>
                <Box mt={3}>
          <Grid
            container
            spacing={6}
          >
            {products.length
              ? products.map((product) => (
                <Grid
                  item
                  key={product.id}
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <ProductCard
                   className={classes.productCard}
                    product={product}
                  />
                </Grid>
              )) : (
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Swinging textProps="No Quiz" />
                </Grid>
              )}
          </Grid>
        </Box>
                </Modal.Body>
            </Modal>

    );
}

export default Studentgameplay;
