/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  IconButton,
  TextField,
  FormControl
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import cogoToast from 'cogo-toast';

const useStyles = makeStyles({
  container: {
    // backgroundColor: 'white'
  },
  icons: {
    color: '#fff'
  },
  checking: {
    // backgroundColor: 'white'
    transition: 'transform .5s ease',
    '&:hover': {
      transform: 'scale(1.05)'
    },
    cursor: 'pointer'
  },
  textSection: {
    width: '100%',
  },
  root: {
    minHeight: 100,
    padding: 10,
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex'
  }
});
const colors = [
  '#e21b3c',
  '#1368ce',
  '#d89e00',
  '#26890c'
];
var getCheckVal = localStorage.getItem('setCheckBox')
console.log('getCheckVal', getCheckVal)

export default function Unchecked({
  answer, order, check, cAnswer, updateAnswer, timeCount, owner
}) {
  const classes = useStyles();
  const [cResult, setCResult] = React.useState(cAnswer);

  if(getCheckVal == localStorage.getItem('setCheckBox')){
  const temp = cAnswer;
  temp[getCheckVal] = cAnswer[getCheckVal] === 1 ? 1 : 1; 
  }

  async function updateCheck(pos, status) {
    getCheckVal = null
    const temp = cAnswer;
    const timer = JSON.parse(localStorage.getItem('downTime'));
    // console.log('status', status, 'timer',timer, 'order',order);
    // console.log('temp+++++', temp)
    localStorage.setItem('setCheckBox', pos)
    for(let i=0; i<=3; i++){
      temp[i] = cAnswer[i] === 1?0:0
    }
    temp[pos] = cAnswer[pos] === 1 ? 0 : 1; 
    console.log('my_time', timer);
    // console.log('temp', temp, 'temp pos',temp[pos], 'cAnswer[pos]', cAnswer[pos] );
    // if (timer == 0) {
    //   cogoToast.warn('Time is up', { position: 'top-right' });
    // } else {
    updateAnswer(temp, timer);
    setCResult(temp);
    // }
  }
  return (
    <div className={classes.container}>
      <Card className={classes.root} style={{ backgroundColor: colors[order] }} onClick={() => {updateCheck(order, check); }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div className={classes.textSection}>
            <FormControl fullWidth>
              <div
                id="standard-basic"
                className="answer-text"
              >
                {answer}
              </div>
            </FormControl>
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            {
              !owner ? (
                <IconButton
                  aria-label="delete"
                  className={classes.checking}
                >
                  {cResult[order] == 1 ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <RadioButtonUncheckedIcon fontSize="large" className={classes.icons} />}
                </IconButton>
              ) : null
            }
          </div>
        </div>
      </Card>
    </div>
  );
}
Unchecked.propTypes = {
  answer: PropTypes.string,
  order: PropTypes.number,
  check: PropTypes.bool,
  updateAnswer: PropTypes.func,
  cAnswer: PropTypes.array,
  timeCount: PropTypes.number,
  owner: PropTypes.bool
};
