/* eslint-disable */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Page from 'src/components/Page';
import global from 'src/utils/global';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    Grid
} from '@material-ui/core';
import { adminGetPlan, adminDeletePlan } from 'src/utils/Api';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    avatar: {
        margin: '0 -19px 0 14px;'
    }
}));
const ViewPlan = () => {
    const classes = useStyles();

    const [getPlan, setGetplan] = React.useState([])
    const [delModal, setDelModal] = React.useState(false);
    const [planDelete, setplanDelete] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);


    //Plan Delete
    const planDel = (d) => {
        console.log('plan', planDelete)
        adminDeletePlan({ id: planDelete }).then((res) => {
            console.log('plan Delete', res)
            setDelModal(false);
        })
    }

    //Cancel Plan
    const cancelPlan = () => {
        console.log('cancel Plan')
        setplanDelete('');
        setDelModal(false);
    }

    const deletePlan = (id) => {
        setplanDelete(id);
        setDelModal(true);
    };

    React.useEffect(() => {
        adminGetPlan().then((res) => {
            console.log('getPlan', res)
            setGetplan(res.data)
        })
    }, [delModal])
    return (
        <Page
            className={classes.root}
            title="View Plan"
        >
            <Dialog
                open={delModal}
                onClose={() => { setDelModal(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                key="hostModal"
            >
                <DialogTitle id="alert-dialog-title">Really?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <strong>Are you sure ?</strong>  <br />You want to delete this Plan
                        {' '}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelPlan} style={{ background: '#0C003C', color: 'white' }}>
                        Cancel
                        {isLoading && <CircularProgress color="nice" size={20} className="progress" />}
                    </Button>
                    <Button onClick={planDel} style={{ background: 'red', color: 'white' }}>
                        Delete
                        {isLoading && <CircularProgress color="nice" size={20} className="progress" />}
                    </Button>
                </DialogActions>
            </Dialog>
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h3"
                >
                    View Plans
                </Typography>
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                // height="100%"
                justifyContent="center"
            >
                <Container maxWidth="lg">


                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">Month (Days)</TableCell>
                                    <TableCell align="center">Total Quiz</TableCell>
                                    <TableCell align="center">Classes</TableCell>
                                    <TableCell align="center">Collections</TableCell>
                                    <TableCell align="center">Class Partcipant</TableCell>
                                    <TableCell align="center">Class Duration</TableCell>
                                    <TableCell align="center">Total Recordings</TableCell>
                                    <TableCell align="center">Storage</TableCell>
                                    <TableCell align="center">Data Transfer</TableCell>
                                    <TableCell align="center">Image</TableCell>
                                    <TableCell align="center">Edit</TableCell>
                                    <TableCell align="center">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getPlan.length > 0 ? getPlan.map((plan, item) => (
                                    <TableRow
                                        key={item}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row"> {plan.plan_title}  </TableCell>
                                        <TableCell align="center">$ {plan.plan_amount}</TableCell>
                                        <TableCell align="center">{plan.plan_month}</TableCell>
                                        <TableCell align="center">{plan.plan_quiz}</TableCell>
                                        <TableCell align="center">{plan.plan_classes}</TableCell>
                                        <TableCell align="center">{plan.plan_collection}</TableCell>
                                        <TableCell align="center">{plan.no_of_virtual_participant}</TableCell>
                                        <TableCell align="center">{plan.class_duration}</TableCell>
                                        <TableCell align="center">{plan.no_of_recordings}</TableCell>
                                        <TableCell align="center">{plan.storage}</TableCell>
                                        <TableCell align="center">{plan.data_transfer}</TableCell>
                                        <TableCell align="center">
                                            <Box
                                                alignItems="center"
                                            >
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={`${global.serverUrl}upload/${plan.plan_img}`}
                                                >
                                                </Avatar> 
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <RouterLink to={`/admin/edit-plan/${plan.id}`}>
                                                <Button style={{ backgroundColor: '#0C003C', color: 'white', paddingLeft: 10 }} >Edit</Button>
                                            </RouterLink>
                                        </TableCell>
                                        <TableCell>
                                            <Button disabled={plan.id == 1 ? true:false}  onClick={() => { deletePlan(plan.id); }} style={{ backgroundColor: plan.id == 1 ? 'grey':'red', color: 'white' }}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableCell align="center">No Records</TableCell>}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Container>
            </Box>
        </Page>
    )

}

export default ViewPlan