/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Card,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CardMedia,
  CircularProgress,
  MenuItem
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { signUp } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 50
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Termofuse = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [accountType, setAcctType] = React.useState('teacher');
  const [isLoading, setIsLoading] = React.useState(false);
  const handChangeAccType = (value) => {
    console.log(value.target.value);
    setAcctType(value.target.value);
  };
  const handleClickShowPassword = () => {
    console.log('asdasdasdsd');
  };
  const handleMouseDownPassword = () => {
    console.log('asdasdasd');
  };
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });
  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Box
        component="div"
        mt={15}
        className='privacy-container'
      >
        <Grid
          container
          spacing={7}
          justify="center"
        >
          <Grid
            xl={8}
            lg={8}
            md={8}
            xs={12}
            item
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justify="center">
                <Grid item>
                  {/* <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '60%', margin: 'auto' }}>
                  <img src="/static/logo.png" alt="logo" width="100%" />
              </div> */}
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Terms And Conditions</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    By utilizing the contents of the site, you acknowledge and agree to these terms and conditions as applied to your use of the site. The term “site” incorporates accessing or using the site by means of a computer, a mobile device or a portable application, as well as any versatile application, worked by the Company that does not access the site. This user agreement is a contract between you and Brainaly and applies to your membership and utilization of products and services accessible through <a href="https://www.brainaly.com" className="privacy-text" style={{ textDecoration: 'underline', color: 'rgb(1, 2, 92)' }}>https://www.brainaly.com</a>.
                  </div>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    In this agreement, "you" or "your" implies any individual or substance subscribing to or utilizing the service of this site. Purchase of services demonstrates a certain agreement with the terms of use. If you do not agree or don't intend to concede by these expressed terms of use, it would be ideal if you abstain from buy or utilize Brainaly services.
                  </div>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    You have to be at least 13 years old or more to be able to utilize the site or our services. Clients between the ages of 13 and 18 must review these Terms and Conditions with a parent or legitimate guardian to guarantee the parent or legitimate guardian to recognize and agree to these Terms and Conditions, and not access the site and uninstall any application in case his or her parent or legitimate guardian doesn’t concur to these Terms and Conditions.
                  </div>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    In case you buy a Program for another to use (if for example, you want to acquire a Program for your friend) these Terms oversee both you and other(s) who utilize any Program you buy. By accessing the site and acquiring or utilizing Programs, you concur to be bound by the Terms, which is an agreement between Brainaly and you.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">License</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Brainaly awards you with a license that is not transferable, exclusive or licensable, and the right to utilize and access the Brainaly site from numerous devices and/or locations. You are not permitted to access the Brainaly site from multiple devices and/or locations at the same time.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Access of Brainaly services from numerous devices and/or locations will be characterized as account sharing. The site and the services offered on or through the site, including any content, are only for you as an individual and not for commercial use. We reserve the right to alter or make adjustments to the operations or any data accessible on the Site at any time and without earlier warning.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Exclusive Rights</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Brainaly claims exclusively all rights and title to the site, all the content including data, outlines, videos, sound, photos, illustrations, the design and organization of the site, including but not limited to any copyrights, trademark rights, patent rights and other intellectual property and restrictive rights in that. Your use of the site does not allow to your possession of any substance, code, information or materials you'll get to on the site.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Restricted Use</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Any commercial distribution or abuse of the site, data or materials on the site, any content, or code, is entirely disallowed. You may not otherwise reproduce, download, perform, duplicate, show, disperse, adjust, exchange, create subsidiary works from, sell or otherwise abuse any content, code, data or materials on the site. If you improperly utilize the site, we may forcefully uphold our property and other rights to the fullest extent of the law, including criminal prosecution.</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Trade Limitations On Worldwide Sales</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    In understanding with the current U.S. trade limitations, Brainaly packages may be used by people throughout the world except those within the following banned nations: Syria, North Korea, Iran, Sudan, and Cuba. In case you're residing in one of these nations, you will not buy a membership to the Brainaly site.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Registration Process</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">In Brainaly, you must complete the registration process in order to subscribe and utilize the services by giving us precisely your current data as provoked by the applicable registration form. You should be responsible for the precision of the information given and may update your profile information in order to reflect the foremost accurate current information. You will also select a password.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You are totally responsible for keeping up the secrecy of your password and account and for any and all activities that happen under your account. You agree to inform Brainaly instantly of any unauthorized use of your account or any other breach of security.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly will not be obligated for any misfortune that you simply may bring about as a result of somebody else utilizing your password or account, either with or without your knowledge. In any case, you may be held obligated for misfortunes incurred by Brainaly or another party due to somebody else utilizing your account or password.</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Passwords</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">In understanding with the current U.S. trade limitations, Brainaly packages may be used by people throughout the world except those within the following banned nations: Syria, North Korea, Iran, Sudan, and Cuba. In case you're residing in one of these nations, you will not buy a membership to the Brainaly site.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Taxes</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">
                    Purchases may be subject to charges in many states. Tax rates are diverse from state to state. You're responsible for paying all such taxes.</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Disclaimer Of Warranties</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly gives its services without any warranty or condition, express, suggested, or statutory. We particularly renounce any inferred warranties of title or merchantability.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly ought to be utilized as a tool to assist people to get ready for various exams. Brainaly reserves the right to withdraw subscriptions and the complete site in the event that it is essential. In case a subscription is ever withdrawn, you'll only pay for the time of your subscription that has passed.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly does not support and isn't responsible for the precision or unwavering quality of any conclusion, advice, or explanation made through the site by any party other than Brainaly. Other than as required under customer security law, under no circumstance will Brainaly be obligated for any misfortune or harm caused by your dependence on data obtained through the site. It is your duty to assess the accuracy, completeness, or value of any data, conclusion, advice, or other substance accessible through the site. It would be ideal if you look for the advice of experts as fitting with respect to the assessment of any particular data, conclusion, advice, or other substance.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly will utilize appropriate measures to keep its site online at all times. You understand that planned support and issues out of the control of Brainaly can cause the site to be incidentally inaccessible.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly will use appropriate measures to amend any mistake that happens on its site but does not ensure access to the site in case of program errors/viruses/other characteristic or specialized catastrophes that cannot be repaired in spite of a sensible effort.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Limitation Of Liability</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly disclaims any warranties without limitation for all programs provided by the site. Brainaly particularly disclaims any obligation for any direct or indirect effects or damages resulting from access or utilization of the site, such as the infection of a user's computer equipment. In any case, the maximum liability resulting from the use of the site regardless of the cause of the action will not exceed the amounts paid by you for your program subscription</div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Copyrights</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">All books, video, sound, content, questions, clarifications, graphs, pictures, movements and other substance that you simply get or you've got access during your Program or through use of the site, in any case, are secured by copyright law and belong to Brainaly.
                    <ul>
                      <li>You may not endeavor to decompile, switch design, scrape or data mine programs.</li>
                      <li>You will not download, record, screenshot, duplicate or reproduce content in any way.</li>
                      <li>You may not make any sound and/or video recording of a course or any portion of your package</li>
                    </ul>
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You will not enlist in or utilize any Program for the advantage of any competitor of Brainaly. Programs may not be shared, resold, duplicated, re-published, adjusted, exchanged or distributed in any way without Brainaly's earlier written authorization. Programs are for your individual and non-commercial use only. All Programs and the site are claimed by Brainaly.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly awards you an individual restricted and non-exclusive or transferable permit to access Program content during the access period of your Program. Programs may include digital access to Program content. Subject to your compliance with these terms and conditions, access periods may be seen from the account administration page of your Brainaly online account.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">The logos, trademarks, and plans that are shown on the site and in Programs are the enlisted and unregistered trademarks of Brainaly and third-party vendors and may not be utilized without Brainaly's earlier, composed authorization. Third party items are possessed by such third parties and their individual licensors.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Refunds</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Brainaly has the right to deny a refund in the event that the client is found to be in infringement of Terms and Conditions or acting against Brainaly's interest. Except as otherwise provided in this agreement or at the sole discretion of Brainaly, in any case, the money will not be refunded after the purchase process has been completed by the buyer. There will be no refunds or changes in any way, cancellations to memberships will not be permitted</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Special Requirements</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You will be responsible for meeting and maintaining the least technical requirements for your chosen purchase in order to access certain features of your package. Access to packages may require web access, for which Brainaly isn't responsible.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">Access to Brainaly services through certain mobile or portable devices are given as an extra feature, but it isn't intended to be a replacement for computer access. Brainaly, at its sole discretion, may select to cease advertising access through any or all mobile devices at any time with or without reason or explanation.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">To install and utilize any of our programs, you must have a compatible device that meets all system requirements. Any devices that have operating systems that have been adjusted in a way that's not approved or any endeavor to install or use the package on such a device will constitute a breach of these terms and conditions of utilizing and may result in account termination, without refund, at the sole discretion of Brainaly.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You agree that Brainaly will not be held at risk for any damages to your devices, operating systems, or any data loss resulting from the direct or indirect installation or utilization of the given software. No refunds or cancellation will be honored due to your failure to install or utilize the given package on any mobile device, in any case of whether or not the device meets the least system requirements.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">An online connection is required when the program is in use, and a Wi-Fi internet connection with an unlimited data plan is profoundly recommended, both unlimited upload and download. In case you use a fixed or limited data plan, you'll be exclusively responsible for any data charges caused. You agree that Brainaly will not be held at risk for any data charges, overage expenses, or any other various expenses or costs that result, directly or indirectly, from utilizing of the software on your devices, including within the occasion of software breakdown.
                  </div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">In any way, you agree that will not attempt to duplicate or capture the content of any test screen including, but not limited to, via any third-party applications while a test is in progress. Brainaly reserves the right to disable your account without refund in the event that you duplicate or endeavor to duplicate any Brainaly copyrighted content.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">It is your sole responsibility to guarantee that your mobile device is suitably used and shielded. In the event that your device is lost or stolen, you must inform Brainaly instantly so that we may disable access to the account. Any violations of these terms and conditions under your account will be treated as if you performed said activities, and you'll be completely obligated for any harms directly or indirectly resulting from said violations.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center" style={{ borderBottom: '1px solid #cdcdcd', margin: 0 }}>
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Payments, Charging Fees, And Cancellation Arrangements</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">For the payment, Brainaly makes certain items and services accessible to guests and registrants of the site. You agree to pay all fees, furthermore, appropriate sales tax, for any purchases you make concurrent along with your online order. In the event that your payment method is declined and you receive the products or services, you agree to pay all amounts due upon demand by us.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">In case you have not yet received the items or services, we are going to cancel your order. Certain items that you simply buy and download through the site may be subject to extra terms and conditions displayed to you at the time of such purchase or download.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">On the same day of each month or every 3 months or every 6 months depend on your service plan, until you cancel it, we are going to charge the fee to the payment method we have on record and refill your account with the number of days that compare to your plan.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You'll be able to cancel your plan at any time. In case you cancel your plan, any remaining days are accessible for utilizing through the end of the current charging month. No refunds, discounts or other credits will be issued for unused days. If you cancel your plan after the first 30 days, you'll not get any refund or discount, or other credit for unused days. Cancellation should only be made if you would NOT wish to renew your subscription for the program plan you choose or any other program. You will not receive any refund for any given service plan.</div>

                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You give us the permission to charge the payment method we have on record, regardless of whether the information related to such payment method has changed, including but not restricted to the expiration date of a physical card. We may receive updated information about your payment method from the issuing financial institution. You agree to pay all fees and charges caused in association along with your account, including any applicable charges at the rates in effect when the charges were caused. You agree to pay all amounts due upon our demand. In the event that we request a third party to collect unpaid amounts that you owe us, you will be obligated for all of our attorneys and collection organization expenses.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="privacy-box"
      >
        <Grid
          container
          spacing={7}

        >
          <Grid
            xl={6}
            lg={8}
            md={8}
            xs={12}
            item
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justifyContent="center">
                <Grid item xs className={classes.titleContainer}>
                  <div style={{ color: '#01025C', fontWeight: 'bold' }} className="privacy-title">Contact</div>
                </Grid>
                <Grid item>
                  <div style={{ color: '#000', marginBottom: 10 }} className="privacy-text">You may contact us for any comments, questions or suggestions relating to these Terms and Conditions. Your opinions are welcomed at the following email address: <a href="info@brainaly.com" className="privacy-text" style={{ textDecoration: 'underline', color: 'rgb(1, 2, 92)' }}>info@brainaly.com</a>.</div>

                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

    </Page>
  );
};

export default Termofuse;
