/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Avatar,
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    CircularProgress,
    makeStyles,
    Card,
    Grid,
    Select
} from '@material-ui/core';
import cogoToast from 'cogo-toast';
import { signIn } from 'src/utils/Api';
import Page from 'src/components/Page';
import StoreContext from 'src/context/index';
import { imageUpload, adminPlan, adminEditPlan, adminUpdatePlan } from 'src/utils/Api';
import global from 'src/utils/global';
const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        height: 100,
        width: 100
    },
    fileModal: {
        display: 'none'
    },
    userName: {
        marginTop: 20,
    }
}));
const AddPlan = (callback) => {
    const classes = useStyles();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const isUpdateMode = params.id | '';
    // console.log('upar++++', isUpdateMode)
    // if(isUpdateMode == 0){
    //     console.log('cascac++++', isUpdateMode)
    //     setplanTitle('')
    // }
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState(isUpdateMode);
    const [planTitle, setplanTitle] = React.useState('');
    const [planAmount, setplanAmount] = React.useState('');
    const [planQuiz, setplanQuiz] = React.useState('');
    const [planClasses, setplanClasses] = React.useState('');
    const [planCollection, setplanCollection] = React.useState('');
    const [planMonth, setplanMonth] = React.useState('');
    const [planImg, setplanImg] = React.useState('');
    const [virtualClassParticipants, setVirtualClassParticipants] = React.useState('');
    const [classDurationLimit, setClassDurationLimit] = React.useState('');
    const [totalRecordings, setTotalRecordings] = React.useState('');
    const [planStorage, setPlanStorage] = React.useState('');
    const [dataTransfer, setDataTransfer] = React.useState('');

    const planSubmit = async (e) => {

        e.preventDefault();
        console.log('planTitle', planTitle)
        console.log('planAmount', planAmount)
        console.log('planQuiz', planQuiz)
        console.log('planClasses', planClasses)
        console.log('planCollection', planCollection)
        console.log('planMonth', planMonth)
        console.log('planImg', planImg)
        console.log('virtualClassParticipants', virtualClassParticipants)
        console.log('classDurationLimit', classDurationLimit)
        console.log('totalRecordings', totalRecordings)
        console.log('planStorage', planStorage)
        console.log('dataTransfer', dataTransfer)
        if (planTitle == '' || planAmount == '' || planQuiz == '' || planMonth == '' || planClasses == '' || planCollection == '' || planImg == '' || virtualClassParticipants == '' ||classDurationLimit =='' || totalRecordings == '' || planStorage == '' || dataTransfer == '') {
            cogoToast.error('All Fields are Required', { position: 'top-right' });


        } else {
            await adminPlan({
                title: planTitle,
                amount: planAmount,
                quizes: planQuiz,
                classes: planClasses,
                collection: planCollection,
                no_of_virtual_participant: virtualClassParticipants,
                class_duration: classDurationLimit,
                no_of_recordings: totalRecordings,
                storage: planStorage,
                data_transfer: dataTransfer,
                image_name: planImg,
                month: planMonth,

            }).then((res) => {
                console.log(res, 'save Plan')
                navigate('/admin/view-plan')
            })

        }


    }

    const handleImageChange = async (e) => {
        if (e.target.files[0]) {
            e.preventDefault();
            const reader = URL.createObjectURL(e.target.files[0]);
            await imageUpload(e.target.files[0]).then((res) => {
                setplanImg(res.data.filename)
                console.log(res, 'res+++++++++')
            })

        }
    }

    const editPlan = () => {

        adminEditPlan({ id: isUpdateMode }).then((res) => {
            console.log(res, 'edit_res')
            if (res.length > 0) {
                setplanTitle(res[0]?.plan_title)
                setplanAmount(res[0]?.plan_amount)
                setplanQuiz(res[0]?.plan_quiz)
                setplanClasses(res[0]?.plan_classes)
                setplanCollection(res[0]?.plan_collection)
                setplanImg(res[0]?.plan_img)
                setplanMonth(res[0]?.plan_month)

                setVirtualClassParticipants(res[0]?.no_of_virtual_participant)
                setClassDurationLimit(res[0]?.class_duration)
                setTotalRecordings(res[0]?.no_of_recordings)
                setPlanStorage(res[0]?.storage)
                setDataTransfer(res[0]?.data_transfer)
            } else {

                setplanTitle('')
                setplanAmount('')
                setplanQuiz('')
                setplanClasses('')
                setplanCollection('')
                setplanImg('')
                setplanMonth('')
            }
        })
    }

    const planUpdate = async (e) => {
        e.preventDefault();
        console.log('planTitle Edit', planTitle)
        console.log('planAmount Edit', planAmount)
        console.log('planQuiz Edit', planQuiz)
        console.log('planClasses Edit', planClasses)
        console.log('planClasses Edit', planCollection)
        console.log('planCollection Edit', planImg)
        console.log('virtualClassParticipants', virtualClassParticipants)
        console.log('classDurationLimit', classDurationLimit)
        console.log('totalRecordings', totalRecordings)
        console.log('planStorage', planStorage)
        console.log('dataTransfer', dataTransfer)
        if (planTitle == '' || planAmount == '' || planQuiz == '' || planClasses == '' || planMonth == '' || planCollection == '' || planImg == '') {
            cogoToast.error('All Fields are Required', { position: 'top-right' });


        } else {
            await adminUpdatePlan({
                id: isUpdateMode,
                title: planTitle,
                amount: planAmount,
                quizes: planQuiz,
                classes: planClasses,
                collection: planCollection,
                month: planMonth,
                no_of_virtual_participant: virtualClassParticipants,
                class_duration: classDurationLimit,
                no_of_recordings: totalRecordings,
                storage: planStorage,
                data_transfer: dataTransfer,
                image_name: planImg,
                plan_month: planMonth,

            }).then((res) => {
                console.log(res, 'Update Plan')
                navigate('/admin/view-plan')
            })

        }
    }
    const planCancel = () => {
        navigate('/admin/view-plan')

    }

    React.useState(() => {
        editPlan()

    }, [])

    return (


        <Page
            className={classes.root}
            title={!isUpdateMode ? 'Add Plan' : 'Update Plan'}
        >
            {!isUpdateMode ?
                <>
                    <Box >
                        <Typography
                            color="textPrimary"
                            variant="h2"
                        >
                            Add Plans
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Container maxWidth="lg">


                            <form >

                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>
                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Plan Title"
                                            margin="normal"
                                            name="text"
                                            onChange={e => setplanTitle(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField
                                            fullWidth
                                            label="Plan Amount"
                                            margin="normal"
                                            name="plan_amount"
                                            onChange={e => setplanAmount(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField

                                            fullWidth
                                            label="Plan Quiz"
                                            margin="normal"
                                            name="plan_question"
                                            onChange={e => setplanQuiz(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>
                                        <TextField

                                            fullWidth
                                            label="Plan Classes"
                                            margin="normal"
                                            name="plan_classes"
                                            onChange={e => setplanClasses(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField

                                            fullWidth
                                            label="Plan Collections"
                                            margin="normal"
                                            name="plan_collection"
                                            onChange={e => setplanCollection(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField
                                            fullWidth
                                            label="Class Participants"
                                            margin="normal"
                                            name="virtual_participant"
                                            onChange={e => setVirtualClassParticipants(e.target.value)}

                                            type="text"
                                            variant="outlined"
                                        />

                                    </Grid>

                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField

                                            fullWidth
                                            label="Class Duration in Minutes"
                                            margin="normal"
                                            name="class_duration"
                                            onChange={e => setClassDurationLimit(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField
                                            fullWidth
                                            label="Total Recordings in Minutes"
                                            margin="normal"
                                            name="total_recording"
                                            onChange={e => setTotalRecordings(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />

                                    </Grid>

                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField

                                            fullWidth
                                            label="Total Storage"
                                            margin="normal"
                                            name="plan_storage"
                                            onChange={e => setPlanStorage(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField
                                            fullWidth
                                            label="Total Data Transfer"
                                            margin="normal"
                                            name="data_transfer"
                                            onChange={e => setDataTransfer(e.target.value) }
                                            type="text"
                                            variant="outlined"
                                        />

                                    </Grid>

                                </Grid>
                                
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30, marginTop:20 }} item xs={12} sm={6} md={6}>

                                        <Select
                                            fullWidth
                                            native
                                            onChange={e => setplanMonth(e.target.value)}

                                        >
                                            <option >Select Month</option>
                                            <option value={7}>1 Week</option>
                                            <option value={30}>1 Month</option>

                                        </Select>
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={4} md={4}>

                                    <TextField

                                        // label="Plan Image"
                                        margin="normal"
                                        name="plan_image"
                                        // value={planImg?'':''}
                                        onChange={(event) => { handleImageChange(event); }}
                                        type="file"
                                        variant="outlined"
                                    />

                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={2} md={2}>

                                    <Avatar
                                        className={classes.avatar}
                                        src={`${global.serverUrl}upload/${planImg}`}
                                    >
                                    </Avatar>
                                    </Grid>


                                </Grid>

                                <Box my={4}>
                                    <Button
                                        // disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        style={{ color: 'white', backgroundColor: '#0C003C' }}
                                        onClick={planSubmit}
                                    >
                                        Add Plan
                                        {isLoading && <CircularProgress color="nice" size={20} className="progress" />}
                                    </Button>
                                </Box>
                            </form>
                        </Container>
                    </Box>
                </>


















                :

                <>

                    <Box >
                        <Typography
                            color="textPrimary"
                            variant="h2"
                        >
                            Update Plans
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Container maxWidth="lg">


                            <form >

                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>
                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Plan Title"
                                            margin="normal"
                                            name="edit_plan_title"
                                            value={planTitle}
                                            onChange={e => setplanTitle(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField
                                            fullWidth
                                            label="Plan Amount"
                                            margin="normal"
                                            name="edit_plan_amount"
                                            value={planAmount}
                                            onChange={e => setplanAmount(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField

                                            fullWidth
                                            label="Plan Quiz"
                                            margin="normal"
                                            name="edit_plan_question"
                                            value={planQuiz}
                                            onChange={e => setplanQuiz(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>
                                        <TextField

                                            fullWidth
                                            label="Plan Classes"
                                            margin="normal"
                                            name="edit_plan_classes"
                                            value={planClasses}
                                            onChange={e => setplanClasses(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                        <TextField

                                            fullWidth
                                            label="Plan Collections"
                                            margin="normal"
                                            name="edit_plan_collection"
                                            value={planCollection}
                                            onChange={e => setplanCollection(e.target.value)}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                    <TextField
                                        fullWidth
                                        label="Class Participants"
                                        margin="normal"
                                        name="edit_virtual_participant"
                                        value={virtualClassParticipants}
                                        onChange={e=> setVirtualClassParticipants(e.target.value)}
                                        type="text"
                                        variant="outlined"
                                    />

                                    </Grid>

                                </Grid>
                                <Grid item xs={12} lg={12} style={{
                                        display: 'flex'
                                    }}>

                                        <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                            <TextField

                                                fullWidth
                                                label="Class Duration in Minutes"
                                                margin="normal"
                                                name="edit_plan_class_duration"
                                                value={classDurationLimit}
                                                onChange={e => setClassDurationLimit(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                            <TextField
                                                fullWidth
                                                label="Total Recordings in Minutes"
                                                margin="normal"
                                                name="edit_plan_recording"
                                                value={totalRecordings}
                                                onChange={e => setTotalRecordings(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                            />

                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} lg={12} style={{
                                        display: 'flex'
                                    }}>

                                        <Grid style={{ paddingRight: 30 }} item xs={12} sm={6} md={6}>

                                            <TextField

                                                fullWidth
                                                label="Total Storage"
                                                margin="normal"
                                                name="edit_plan_storage"
                                                value={planStorage}
                                                onChange={e => setPlanStorage(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid style={{ paddingLeft: 30 }} item xs={12} sm={6} md={6}>

                                            <TextField
                                                fullWidth
                                                label="Total Data Transfer"
                                                margin="normal"
                                                name="edit_plan_transfer"
                                                value={dataTransfer}
                                                onChange={e => setDataTransfer(e.target.value)}
                                                type="text"
                                                variant="outlined"
                                            />

                                        </Grid>

                                    </Grid>

                                <Grid item xs={12} lg={12} style={{
                                    display: 'flex'
                                }}>

                                    <Grid style={{ paddingRight: 30, marginTop:20 }} item xs={12} sm={6} md={6}>

                                        <Select
                                            fullWidth
                                            native
                                            value={planMonth}
                                            onChange={e => setplanMonth(e.target.value)}
                                        >
                                            <option >Select Month</option>
                                            <option selected={planMonth} value={7}>1 Week</option>
                                            <option value={30}>1 Month</option>

                                        </Select>
                                    </Grid>
                                    <Grid style={{ paddingLeft: 30 }} item xs={12} sm={4} md={4}>

                                        <TextField

                                            // label="Plan Image"
                                            margin="normal"
                                            name="edit_plan_image"
                                            // value={planImg}
                                            onChange={(event) => { handleImageChange(event); }}
                                            type="file"
                                            variant="outlined"
                                        />

                                        </Grid>
                                        <Grid style={{ paddingLeft: 30 }} item xs={12} sm={2} md={2}>
                                        <Avatar
                                            className={classes.avatar}
                                            src={`${global.serverUrl}upload/${planImg}`}
                                        >
                                        </Avatar>
                                        </Grid>


                                </Grid>
                                <Box my={4}>
                                    <Button
                                        color="primary"
                                        // disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        style={{ color: 'white', backgroundColor: 'red' }}
                                        onClick={planCancel}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color="primary"
                                        // disabled={isSubmitting}
                                        style={{ color: 'white', backgroundColor: '#0C003C', marginLeft: 10 }}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={planUpdate}
                                    >
                                        Update
                                        {isLoading && <CircularProgress color="nice" size={20} className="progress" />}
                                    </Button>
                                </Box>
                            </form>
                        </Container>
                    </Box>
                </>


            }
        </Page>
    )
}

export default AddPlan
