/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
  Box,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Card,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  List,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon
} from 'react-feather';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from 'src/components/Page';
// import QuestionPlayers from 'src/views/allrecords/QuestionPlayers';
import QuestionSummary from 'src/views/allrecords/QuestionSummary';
import QuestionFeedback from 'src/views/allrecords/QuestionFeedback';
import cogoToast from 'cogo-toast';
import { quizQuestions, quizPlayers } from 'src/utils/Api';
import global from 'src/utils/global';
import { Button, Modal } from 'react-bootstrap';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // height: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%'
  },
}));

const QuestionsList = () => {
  // const { children, value, index, ...other } = props;
  const [questions, setQuestions] = React.useState([]);
  const [questlist, setquestlist] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [summary, setSummary] = React.useState([]);

  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");
  const handleClose = () => setShow(false);
  const hostData = async () => {
    var get_id = splitLocation[4];
    var data = JSON.parse(localStorage.getItem('host'));
    var result = data.filter((item) => {
      return get_id == item['id'] ?? item
    });
    setSummary([...result])
    console.log('filresult', result)
  }
  const handleShow = (title, id) => {
    console.log('qid', title)
    console.log('qiddd', id)
    var result = questions.filter((item) => {

      return title == item['title'] ?? item
    });
    console.log('filresult', result)
    setquestlist([...result])

    setShow(true)

  };
  const quiz_questions = async () => {
    //Date Format
    function dateFormat(str) {
      var months = {
        '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
        '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
        '11': 'November', '12': 'December',
      }
      var date = new Date(str),
        // day = ("0" + (date.getDate()+ 1)).slice(-2);
        day = ("0" + date.getDate()).slice(-2);

      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        monthNumber = months[mnth];

      return [day, monthNumber, date.getFullYear(),].join("-");
    }

    //Time Function
    function tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }
    var get_id = splitLocation[4]
    await quizQuestions({ id: get_id }).then((res) => {
      console.log(res, 'quizQuestions');
      var quiz_question = res.data;     
      console.log(quiz_question, 'quizQuestions');
      setQuestions([...quiz_question])
    }).catch((err) => {
      console.log(err, 'quizQuestionsserror');
    });
  }
  const quiz_players = async () => {
    //Date Format
    function dateFormat(str) {
      var months = {
        '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
        '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
        '11': 'November', '12': 'December',
      }
      var date = new Date(str),
        // day = ("0" + (date.getDate()+ 1)).slice(-2);
        day = ("0" + date.getDate()).slice(-2);

      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        monthNumber = months[mnth];

      return [day, monthNumber, date.getFullYear(),].join("-");
    }

    //Time Function
    function tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }
    var get_id = splitLocation[4]
    await quizPlayers({ id: get_id }).then((res) => {
      var data = res.data;
      var quiz_filter = data ? data.map(players => ({
        'id': players['id'],
        'quiz_id': players['quiz_id'],
        'game_list_id': players['game_list_id'],
        'user_id': players['user_id'],
        'nick_name': players['user_name'],
        'total_correct_answer': players['total_correct_answer'],
        'total_correct_answer_percentage': players['total_correct_answer_percentage'],
        'total_score': players['total_score'],
        'unanswered': players['unanswered'],
        'user_info': players['user_info'],
        'unanswered': players['unanswered'],
        'created_at': dateFormat(players['created_at'].split('T')[0]) + ' ' + tConvert(players['created_at'].split('T')[1].split('.')[0].split(':')[0] + ':' + players['created_at'].split('T')[1].split('.')[0].split(':')[1]),
      })) : ''
      console.log(quiz_filter, 'quizPlayers');
      setPlayers([...quiz_filter])
    }).catch((err) => {
      console.log(err, 'quizPlayerserror');
    });
  }
  React.useEffect(() => {
    quiz_questions();
    quiz_players();
    hostData();
  }, [])

  return (
    <Page
      className={classes.root}
      title="Teacher Brainaly Questions"
    >
      {/* <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        All Records
      </Box> */}
      <div className="sc-izaQbr gytZrp" id="main-content">
        <div className="sc-gPTkgY jAYNyi">
          <div className="sc-gPTkgY jAYNyi" aria-hidden="false">
            <div>
              <main className="sc-fNFVIZ hxQRHK">
                <div width="1" className="sc-kstrdz sc-hBEYos gvzVaF dWjUC">
                  <div className="sc-hTsBph cTvoQZ">
                    <div className="sc-kstrdz sc-hBEYos fZENZC dWjUC" width="100%" height="fit-content">
                      <div className="sc-bDCewv hhngOi">
                        <section className="sc-cFPsUu bztCDK">
                          <div className="sc-stgHA gabcHZ">
                            <h5 data-functional-selector="report-summary-header__reports-subtitle" className="sc-dzDlLZ fMObBc">Report</h5>
                            <h2 data-functional-selector="report-summary-header__reports-title" className="sc-jNwxvY RLUoc">
                              <button type="submit" aria-label="Rename report. Current report name. Dosage Review" data-functional-selector="report-summary-header__rename-button" className="sc-iBPRYJ kQjigY sc-hpSTqv fBfcAo">
                                <span className="sc-eCssSg dDNlwv dDNlwvone">
                                  {/* <svg id="edit-reports-title" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                                    <path
                                                                        d="M23.4091683,8.594 C24.1731683,9.359 24.1731683,10.693 23.4091683,11.458 L22.6901683,12.178 L19.8241683,9.313 L20.5451683,8.594 C21.3101683,7.83 22.6441683,7.829 23.4091683,8.594 L23.4091683,8.594 Z M8.37616832,23.626 L8.97116832,21.252 L10.7501683,23.033 L8.37616832,23.626 Z M18.4111683,10.728 L21.2741683,13.591 L12.7061683,22.159 L9.84316832,19.296 L18.4111683,10.728 Z M24.8211683,7.179 C24.0611683,6.418 23.0501683,6 21.9741683,6 C20.9001683,6 19.8881683,6.418 19.1281683,7.179 L7.71916832,18.587 C7.71916832,18.587 7.71816832,18.59 7.71716832,18.591 C7.63616832,18.673 7.49716832,18.889 7.45616832,19.052 L6.03016832,24.757 C5.94416832,25.097 6.04516832,25.458 6.29316832,25.706 L6.29416832,25.707 L6.29616832,25.708 C6.54416832,25.956 6.90416832,26.057 7.24516832,25.972 L12.9491683,24.545 C13.1121683,24.504 13.3291683,24.365 13.4101683,24.284 C13.4111683,24.283 13.4141683,24.282 13.4141683,24.282 L24.8231683,12.873 C25.5831683,12.113 26.0021683,11.102 26.0011683,10.027 C26.0011683,8.951 25.5831683,7.941 24.8231683,7.18 L24.8211683,7.179 Z"
                                                                        style={{ fill: "rgb(51, 51, 51)"}}
                                                                    ></path>
                                                                </svg> */}
                                </span>
                              </button>
                            </h2>
                            {/* <div className="sc-dYzDLp UqdGH">Report options</div> */}
                            <div className="sc-kmsPvo cCKVkA">
                              <div className="sc-wyIyg iVugHJ onboarding-reports">
                                <div className="sc-iRpACI gSFmGa sc-iiqTaX lmMISc" aria-haspopup="true">
                                  <button
                                    type="button"
                                    id="action-menu__toggle-report-action-menu__29ac8a87-2ece-47e7-8877-d1f41014e57e_1637756315167"
                                    aria-label="Action menu of Dosage Review report."
                                    aria-expanded="false"
                                    data-functional-selector="report-action-menu__toggle"
                                    className="sc-iBPRYJ mKjvP sc-eYRYCd hRiOnA"
                                  >
                                    {/* <span className="sc-eCssSg cAELPS cAELPSone" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                      <svg id="action" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                        <path
                                          d="M16,10 C17.1045695,10 18,9.1045695 18,8 C18,6.8954305 17.1045695,6 16,6 C14.8954305,6 14,6.8954305 14,8 C14,9.1045695 14.8954305,10 16,10 Z M16,18 C17.1045695,18 18,17.1045695 18,16 C18,14.8954305 17.1045695,14 16,14 C14.8954305,14 14,14.8954305 14,16 C14,17.1045695 14.8954305,18 16,18 Z M16,26 C17.1045695,26 18,25.1045695 18,24 C18,22.8954305 17.1045695,22 16,22 C14.8954305,22 14,22.8954305 14,24 C14,25.1045695 14.8954305,26 16,26 Z"
                                          style={{ fill: "rgb(51, 51, 51)" }}
                                        ></path>
                                      </svg>
                                    </span> */}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sc-kstrdz sc-hBEYos fMrbYi dWjUC">
                            {summary.map((summary) => <>

                              <h2 className="sc-jNwxvY bLhQJy">
                                {summary.quize_name}
                                {/* <button type="button" aria-label="Rename report. Current report name. Dosage Review" data-functional-selector="report-summary-header__rename-button" className="sc-iBPRYJ kQjigY sc-hpSTqv fBfcAo">
                                <span className="sc-eCssSg dDNlwv dDNlwvsone">
                                  <svg id="edit-reports-title" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                    <path
                                      d="M23.4091683,8.594 C24.1731683,9.359 24.1731683,10.693 23.4091683,11.458 L22.6901683,12.178 L19.8241683,9.313 L20.5451683,8.594 C21.3101683,7.83 22.6441683,7.829 23.4091683,8.594 L23.4091683,8.594 Z M8.37616832,23.626 L8.97116832,21.252 L10.7501683,23.033 L8.37616832,23.626 Z M18.4111683,10.728 L21.2741683,13.591 L12.7061683,22.159 L9.84316832,19.296 L18.4111683,10.728 Z M24.8211683,7.179 C24.0611683,6.418 23.0501683,6 21.9741683,6 C20.9001683,6 19.8881683,6.418 19.1281683,7.179 L7.71916832,18.587 C7.71916832,18.587 7.71816832,18.59 7.71716832,18.591 C7.63616832,18.673 7.49716832,18.889 7.45616832,19.052 L6.03016832,24.757 C5.94416832,25.097 6.04516832,25.458 6.29316832,25.706 L6.29416832,25.707 L6.29616832,25.708 C6.54416832,25.956 6.90416832,26.057 7.24516832,25.972 L12.9491683,24.545 C13.1121683,24.504 13.3291683,24.365 13.4101683,24.284 C13.4111683,24.283 13.4141683,24.282 13.4141683,24.282 L24.8231683,12.873 C25.5831683,12.113 26.0021683,11.102 26.0011683,10.027 C26.0011683,8.951 25.5831683,7.941 24.8231683,7.18 L24.8211683,7.179 Z"
                                      style={{ fill: 'rgb(51, 51, 51)' }}
                                    />
                                  </svg>
                                </span>
                              </button> */}
                              </h2>
                            </>)}
                          </div>
                          <ul className="sc-eSNMKe gNRSAT sc-fUSoCb kJIzVF sc-dYzDLp UqdGH" data-functional-selector="tabs" role="tabpanel">
                            <RouterLink to={"/teacher/reports/question_summary/" + splitLocation[4]}>
                              <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" aria-label="Summary: An overview of game results." className={splitLocation[3] === "question_summary" ? "active_navbar" : "deflt_navbar"}>
                                  <span className="sc-hKizKw ceBZw">Summary</span>
                                  <span className="sc-dRKXJR dkNZJN"></span>
                                </button>
                              </li>
                            </RouterLink>
                            <RouterLink to={"/teacher/reports/question_players/" + splitLocation[4]}>
                              <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="true" aria-label="Players: Display the results of players." className={splitLocation[3] === "question_players" ? "active_navbar" : "deflt_navbar"}>
                                  <span className="sc-hKizKw ceBZw">Players ({players ? players.length : ''})</span>
                                </button>
                              </li>
                            </RouterLink>
                            <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                              <RouterLink to={"/teacher/reports/question_list/" + splitLocation[4]}>
                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="questions" aria-label="Questions: Display the overview of 7 questions." className={splitLocation[3] === "question_list" ? "active_navbar" : "deflt_navbar"}>
                                  <span className="sc-hKizKw ceBZw">Questions ({questions ? questions.length : ''})</span>
                                </button>
                              </RouterLink>
                            </li>
                            {/* <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                              <RouterLink to="/records/question_feedback">
                                <button type="submit" color="#6E6E6E" role="tab" aria-selected="false" data-functional-selector="feedback" aria-label="Feedback: Display feedback from players." className={splitLocation[3] === "question_feedback" ? "active_navbar" : "deflt_navbar"}>
                                  <span className="sc-hKizKw ceBZw">Feedback</span>
                                </button>
                              </RouterLink>
                            </li> */}
                          </ul>
                        </section>
                        <section data-functional-selector="report-summary-header__right-panel" className="sc-hOPlpL dXIcKz">
                          {/* <div className="sc-impOMm egUcsT"></div> */}
                          {summary.map((summary) => <>
                            <div data-functional-selector="report-summary-header__game-type" className="sc-liZemx dcdvzA">
                              {console.log('summary', summary)}
                              {summary.mode == 0 ? 'Offline' : 'Live'}
                              {/* <img
                                                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMi4yNSIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDMpIj4KICAgICAgICA8bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+CiAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMyNjg5MEMiIGQ9Ik05LjI4MSA5LjI4MWg4LjQzOHY2LjE4OGEyLjI1IDIuMjUgMCAwIDEtMi4yNSAyLjI1SDkuMjhWOS4yOHoiIG1hc2s9InVybCgjYikiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjRkZBNjAyIiBkPSJNLjI4MSA5LjI4MUg4LjcydjguNDM4SDIuNTNhMi4yNSAyLjI1IDAgMCAxLTIuMjUtMi4yNVY5LjI4eiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMxMzY4Q0UiIGQ9Ik05LjI4MS4yODFoNi4xODhhMi4yNSAyLjI1IDAgMCAxIDIuMjUgMi4yNVY4LjcySDkuMjhWLjI4eiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNFMjFCM0MiIGQ9Ik0yLjUzMS4yODFIOC43MlY4LjcySC4yOFYyLjUzYTIuMjUgMi4yNSAwIDAgMSAyLjI1LTIuMjV6IiBtYXNrPSJ1cmwoI2IpIi8+CiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTEuNjQ0IDExLjY0NGgzLjM5djMuMzloLTMuMzl6TTQuNjYgMTUuNDExYTIuMDcyIDIuMDcyIDAgMSAxIDAtNC4xNDMgMi4wNzIgMi4wNzIgMCAwIDEgMCA0LjE0M3pNMTEuMDggNC42NjFsMi4yNi0yLjI2IDIuMjYgMi4yNi0yLjI2IDIuMjZ6TTYuNzMyIDYuMjczSDIuNTlsMi4wNzItMy4zMDd6IiBtYXNrPSJ1cmwoI2IpIi8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE3LjQzOCIgaGVpZ2h0PSIxNy40MzgiIHg9Ii4yODEiIHk9Ii4yODEiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTYzIiBtYXNrPSJ1cmwoI2IpIiByeD0iMi4yNSIvPgogICAgPC9nPgo8L3N2Zz4K"
                                                        className="sc-kMyZBz bcbmhN"
                                                    /> */}
                            </div>
                            <div data-functional-selector="report-summary-header__challenge-started" className="sc-liZemx dcdvzA">{summary.created_at}</div>
                            <div data-functional-selector="report-summary-header__username" className="sc-liZemx dcdvzA">Hosted by {summary.host_name}</div>
                          </>)}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="sc-gvPzKF kMpcxm">
                  <div width="100%" className="sc-kstrdz goIqpK">
                    {/* <div className="sc-jhMlwA hVdjjx">
                      <div className="sc-kstrdz sc-hBEYos QvkTT dWjUC">
                        <button aria-label="Expand the reports table  to see more data." data-functional-selector="expanded-view" aria-selected="false" tabindex="0" className="sc-iBPRYJ mKjvP sc-bgnQsG hgQcJe">
                          <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                            <svg id="view-switch-default" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                              <path
                                d="M8,19 L8,24 L23.997,24 L24,19 L8,19 Z M8,8 L8,13 L23.997,13 L24,8 L8,8 Z M24,15 L8,15 C6.897,15 6,14.103 6,13 L6,8 C6,6.897 6.897,6 8,6 L24,6 C25.103,6 26,6.897 26,8 L26,13 C26,14.103 25.103,15 24,15 L24,15 Z M24,26 L8,26 C6.897,26 6,25.103 6,24 L6,19 C6,17.897 6.897,17 8,17 L24,17 C25.103,17 26,17.897 26,19 L26,24 C26,25.103 25.103,26 24,26 L24,26 Z"
                                style={{ fill: "rgb(51, 51, 51)" }}
                              ></path>
                            </svg>
                          </span>
                          <span className="sc-fubCfw irPtrP"><span className="sc-bwzNSG ljquiA">Expanded view</span></span>
                        </button>
                      </div>
                      <div className="sc-kstrdz sc-hBEYos QvkTT dWjUC">
                        <button aria-label="Display the reports table in a compacted view." data-functional-selector="compact-view" aria-selected="true" tabindex="0" className="sc-iBPRYJ mKjvP sc-bgnQsG hgQcJe">
                          <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                            <svg id="grid-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                              <path
                                d="M9,10 L23,10 L23,8 L9,8 L9,10 Z M23,12 L9,12 C7.897,12 7,11.103 7,10 L7,8 C7,6.898 7.897,6 9,6 L23,6 C24.103,6 25,6.898 25,8 L25,10 C25,11.103 24.103,12 23,12 L23,12 Z M9,17 L23,17 L23,15 L9,15 L9,17 Z M23,19 L9,19 C7.897,19 7,18.103 7,17 L7,15 C7,13.898 7.897,13 9,13 L23,13 C24.103,13 25,13.898 25,15 L25,17 C25,18.103 24.103,19 23,19 L23,19 Z M9,24 L23,24 L23,22 L9,22 L9,24 Z M23,26 L9,26 C7.897,26 7,25.103 7,24 L7,22 C7,20.897 7.897,20 9,20 L23,20 C24.103,20 25,20.897 25,22 L25,24 C25,25.103 24.103,26 23,26 L23,26 Z"
                                style={{ fill: "rgb(81, 45, 168)" }}
                              ></path>
                            </svg>
                          </span>
                          <span className="sc-fubCfw irPtrP"><span className="sc-bwzNSG kcvuiI">Compact view</span></span>
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div width="100%" className="sc-kstrdz sc-kEbnRK cNTEdy fACyxj"></div>
                  <div className="sc-gLDLRf eYfUJB">
                    <div className="sc-kUKgKq cvAZyL">
                      <div className="sc-kstrdz sc-hBEYos sc-igRjXc gFMnBB dWjUC zqCWL">
                        <ul className="sc-eSNMKe gNRSAT sc-fUSoCb kragIp" data-functional-selector="report-questions-table__result-type" role="tabpanel">
                          <li className="sc-jNrxex dhdKlb" data-functional-selector="list-item">
                            <button color="#6E6E6E" tabindex="0" role="tab" aria-selected="true" data-functional-selector="all active" aria-label="Display data in table for all 7 questions." className="sc-iAjKSQ flRDN">
                              <span className="sc-hKizKw ceBZw">All ({questions ? questions.length : ''})</span><span className="sc-dRKXJR dkNZJN"></span>
                            </button>
                          </li>
                        </ul>
                        {/* <div width="100%" data-functional-selector="__search-field" className="sc-kstrdz sc-hBEYos bjPvep dWjUC">
                        <div className="sc-eZuTdo BaPLM"><input placeholder="Search" id="search" className="sc-eTLWQi cLqsro" aria-label="Search for the phrase in the table." dir="auto" value="" /></div>
                      </div> */}
                      </div>
                      <table
                        data-functional-selector="report-questions-table"
                        aria-label="Players performance per question table. You can sort the table by question number, question type or average accuracy per question."
                        className="sc-jDWdzH eHdDFJ"
                        role="table"
                      >
                        <thead data-functional-selector="report-questions-table__header" className="sc-kYpqvE fnvHVt">
                          <tr data-functional-selector="report-questions-table__header-row" className="sc-lnYokC Mxxaa">
                            <th colspan="20" role="columnheader" title="Toggle SortBy" id="index" width="65%" color="#46178f" data-functional-selector="index" className="sc-hyngy dVQetf" style={{ cursor: 'pointer' }}>
                              <button tabindex="0" aria-selected="true" aria-label="Sort table by questions in ascending numerical order." className="sc-eDqjnT fGWQjS">
                                Question
                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(70, 23, 143)" }} ></path>
                                </svg>
                              </span> */}
                              </button>
                            </th>
                            <th role="columnheader" title="Toggle SortBy" id="type" width="15%" color="#333" data-functional-selector="type" className="sc-hyngy bLFIip" style={{ cursor: 'pointer' }}>
                              <button tabindex="0" aria-selected="false" aria-label="Sort table by question type, from Z to A." className="sc-eDqjnT fGWQjS">
                                Type
                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                </svg>
                              </span> */}
                              </button>
                            </th>
                            <th role="columnheader" title="Toggle SortBy" id="averageAccuracy" width="22%" color="#333" data-functional-selector="averageAccuracy" className="sc-hyngy iUCfFu" style={{ cursor: 'pointer' }}>
                              <button tabindex="0" aria-selected="false" aria-label="Sort table by percentage of correct answers in ascending order." className="sc-eDqjnT fioqoJ">
                                Correct/Incorrect
                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                </svg>
                              </span> */}
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="sc-eeLDed lmEVNl">
                          {questions.length == 0 ? <tr><td>No Record Found</td></tr> : questions.map((quest, qid) => <>
                            <tr key={qid} onClick={() => { handleShow(quest.title, qid + 1) }} data-functional-selector="report-questions-table__row" id="row-0" role="row" className="sc-gDRTSU skdUiVwh">
                              <td data-functional-selector="index" id="row-0-cell-0" role="cell" width="65%" colspan="20" value="[object Object]" className="sc-kZJQap zkIsh">
                                <div className="sc-kstrdz sc-hBEYos jFVOTc dWjUC">
                                  <div width="100%" className="sc-kstrdz sc-hBEYos eIKDEA dWjUC">
                                    <span className="sc-kzyJjZ ceyDxa">{qid + 1}</span>
                                    <span data-latex-element="true" className="sc-kIXaRb gtSfVe">{quest.title}</span>
                                  </div>
                                </div>
                              </td>
                              <td data-functional-selector="type" id="row-0-cell-1" role="cell" width="15%" value="[object Object]" className="sc-kZJQap zkIsh">
                                <div className="sc-kstrdz sc-hBEYos jlvGrj dWjUC"><span className="sc-bHqbil fgZjIA">Quiz</span></div>
                              </td>
                              <td data-functional-selector="averageAccuracy" id="row-0-cell-2" role="cell" width="22%" value="0.5" className="sc-kZJQap vWLXq">
                                <div className="sc-kstrdz sc-hBEYos QvkTTT dWjUC">
                                  <div height="30" data-functional-selector="progress-icon" className="sc-ftEBqf bMHYYe qt">
                                    <CircularProgressbar
                                      value={quest.right_answer_percentage?quest.right_answer_percentage.toFixed(0):0}
                                      styles={buildStyles({
                                        textColor: "red",
                                        pathColor: "green",
                                        trailColor: "red",
                                        strokeLinecap: "butt"
                                      })}
                                    />
                                  </div>
                                  <span className="sc-bzDAFm eiJKxS">{quest.right_answer_percentage?quest.right_answer_percentage.toFixed(0):0}%</span>
                                </div>
                              </td>
                            </tr>
                            <tr className="sc-cLQfIn ZPCxf">
                              <td colspan="100" data-functional-selector="report-questions-table__row-content"></td>
                            </tr>
                          </>)}
                        </tbody>

                        <Modal className= "quetionleft" show={show} onHide={handleClose} animation={true}>
                          {questlist.length == 0 ? <tr> <td style={{ textAlign: "center" }}>No Data Found</td></tr> : questlist.map((quest, qlid) => <>

                            <Modal.Header closeButton>
                              <Modal.Title>
                                <h2 height="3.5rem" className="sc-fFubgz buWiNT">
                                  <div className="sc-kstrdz sc-eFRnhs jlvGrj eGzpUc">
                                    <span className="sc-fDawzw chfCdB">Quiz Name: </span>
                                    <span data-functional-selector="question-details-dialog__header_title" className="sc-gbFcJm ercNFR">{quest.title}</span>
                                  </div>
                                </h2>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="sc-dxDmGn XbjEQ">

                                {/* <nav height="3.5rem" className="sc-fodVxV cIHinc">
                                <div data-functional-selector="question-details-dialog_header-text" className="sc-kstrdz DPrPM">1 of 7</div>
                                <button aria-label="Show previous question details" data-functional-selector="question-details-dialog_header-prev" disabled="" tabindex="0" className="sc-iBPRYJ mKjvP sc-bkzZxe cSnsVU">
                                  <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                    <svg id="arrow-left" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                      <path d="M19 24.415L10.586 16 19 7.586 20.414 9.001 13.414 16 20.414 23z" style={{ fill: "rgb(242, 242, 242)" }}
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                                <button aria-label="Show next question details" data-functional-selector="question-details-dialog_header-next" tabindex="0" className="sc-iBPRYJ mKjvP sc-bkzZxe cSnsVU">
                                  <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                    <svg id="arrow-right" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                      <path d="M12 24.415L10.586 23 17.586 16 10.586 9.001 12 7.586 20.414 16z" style={{ fill: "rgb(0, 0, 0)" }}></path>
                                    </svg>
                                  </span>
                                </button>
                              </nav> */}
                                <div className="sc-gYHvax eiWFHc">
                                  <div id="row-undefined-details" data-functional-selector="answers-overview" className="sc-kstrdz sc-hBEYos sc-hGFGMM jlvGrj dWjUC kMujMM">
                                    <div className="sc-kstrdz sc-hBEYos ksuEIT dWjUC">
                                      <div type="quiz" data-functional-selector="report-media-image" className="sc-brWQNQ dwHQmm">
                                        <div className="sc-kJJeMD koThzL">
                                          {quest.image ? <img className="img-resize" src={`${global.serverUrl}upload/${quest.image}`}></img> :
                                            <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                              <svg id="image-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                <path
                                                  d="M25,6 C26.104,6 27,6.897 27,8 L27,8 L27,24 C27,25.103 26.104,26 25,26 L25,26 L7,26 C5.897,26 5,25.103 5,24 L5,24 L5,8 C5,6.897 5.897,6 7,6 L7,6 Z M25,8 L7,8 L7,24 L24.997,24 L24.999,14 L25,14 L25,8 Z M18,14 L22,20 L10,20 L13,16 L15,18 L18,14 Z M12,11 C13.104,11 14,11.894 14,13 C14,14.105 13.104,15 12,15 C10.895,15 10,14.105 10,13 C10,11.894 10.895,11 12,11 Z"
                                                  style={{ fill: "rgb(255, 255, 255)" }}
                                                ></path>
                                              </svg>
                                            </span>
                                          }
                                        </div>
                                      </div>
                                      <div className="sc-kstrdz sc-hBEYos jlvGrj dWjUC">
                                        <div className="sc-fUiUso ezLBWn">
                                          <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                            <svg id="answers-overview__stat-icon-timer" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                              <path
                                                d="M16,26 C10.486,26 6,21.515 6,16 C6,10.487 10.486,6 16,6 C21.514,6 26,10.486 26,16 C26,21.515 21.514,26 16,26 Z M16,8 C11.589,8 8,11.589 8,16 C8,20.411 11.589,24 16,24 C20.411,24 24,20.411 24,16 C24,11.589 20.411,8 16,8 Z M15,11 L17,11 L17,18 L15,18 L15,11 Z"
                                                style={{ fill: "rgb(110, 110, 110)" }}
                                              >
                                              </path>
                                            </svg>
                                          </span>
                                          {quest.quizTime}s time limit
                                        </div>
                                      </div>
                                    </div>
                                    <div data-functional-selector="answers-overview__stat-table" className="sc-lfkeUN eSvmy">
                                      {quest.quizAnswer.map((anslist, asid) => <>
                                        <div data-functional-selector="answer-stats-row-undefined-0" className="sc-kstrdz sc-hBEYos sc-guxjaN jlvGrj dWjUC gbElrs">
                                          <div className="sc-Eryum eywLfQ">
                                            <div color="#e21b3c" type="quiz" className={asid == 0 ? "sc-clsHhM lmcoQi0" : asid == 1 ? "sc-clsHhM hzVFqE1" : asid == 2 ? "sc-clsHhM dFMQva2" : asid == 3 ? "sc-clsHhM cFxYFM3" : 'sc-clsHhM blank'}>
                                            </div>
                                          </div>
                                          <div className="sc-kstrdz sc-hiAUTu jlvGrj fazLRK"><span className="sc-ekkLtS ggRiwX">{anslist.answer}</span></div>
                                          <div className="sc-Eryum hhCfhf">
                                            {anslist.sel == 1 ?
                                              <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                <svg id="correct-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                  <path d="M12.926 22.652L8 17.725 9.767 15.957 12.841 19.031 21.959 9 23.808 10.682z" style={{ fill: "rgb(38, 137, 12)" }}></path>
                                                </svg>
                                              </span> :
                                              <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                <svg id="timeout-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                  <path d="M24.714 8.414L23.3 7 16.007 14.293 8.714 7 7.3 8.414 14.593 15.707 7.3 23 8.714 24.414 16.007 17.122 23.3 24.414 24.714 23 17.421 15.707z" style={{ fill: "rgb(226, 27, 60)" }}></path>
                                                </svg>
                                              </span>
                                            }
                                          </div>
                                          <div className="sc-jwzInS cMoSYS">
                                            <div className="sc-fIUAZz ebZwGv"><div className="sc-rejdi gygoAu"></div></div>
                                          </div>
                                          <div data-functional-selector="stats-row__count" className="sc-dyUqLn jwJGqs">{anslist.count}</div>
                                        </div>
                                      </>)}
                                      {/* <div data-functional-selector="answer-stats-row-undefined-0" className="sc-kstrdz sc-hBEYos sc-guxjaN jlvGrj dWjUC gbElrs">
                                        <div className="sc-Eryum eywLfQ">
                                          <div color="#e21b3c" type="quiz" className='sc-clsHhM blank'>
                                          </div>
                                        </div>
                                        <div className="sc-kstrdz sc-hiAUTu jlvGrj fazLRK"><span className="sc-ekkLtS ggRiwX">No Answer</span></div>
                                        <div className="sc-Eryum hhCfhf">

                                          <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                            <svg id="timeout-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                              <path d="M24.714 8.414L23.3 7 16.007 14.293 8.714 7 7.3 8.414 14.593 15.707 7.3 23 8.714 24.414 16.007 17.122 23.3 24.414 24.714 23 17.421 15.707z" style={{ fill: "rgb(226, 27, 60)" }}></path>
                                            </svg>
                                          </span>
                                        </div>
                                        <div className="sc-jwzInS cMoSYS">
                                          <div className="sc-fIUAZz ebZwGv"><div className="sc-rejdi gygoAu"></div></div>
                                        </div>
                                        <div data-functional-selector="stats-row__count" className="sc-dyUqLn jwJGqs">0</div>
                                      </div> */}
                                    </div>
                                  </div>
                                  {/* <div aria-live="polite" aria-atomic="true" className="sc-hkfzSQ TeoEB">
                                  <div className="sc-dWhPOB bytfcq">
                                    <div className="sc-kstrdz sc-hBEYos kbapjo dWjUC">
                                      <div className="sc-kstrdz sc-eFRnhs jlvGrj eGzpUc">Correct answers</div>
                                      <div height="30" data-functional-selector="progress-icon" className="sc-ijtCEc gnCRvg">
                                      </div>
                                      <div className="sc-kstrdz khwMCn">50%</div>
                                    </div>
                                  </div>
                                  <div className="sc-dWhPOB bytfcq">
                                    <div className="sc-kstrdz sc-hBEYos geLHTC dWjUC">
                                      <div className="sc-kstrdz sc-eFRnhs dmNAkW eGzpUc">Avg. answers time</div>
                                      <div className="sc-kstrdz khwMCn">
                                        <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                          <svg id="stat-icon-timer" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                            <path d="M16 6c5.52 0 10 4.48 10 10s-4.48 10-10 10S6 21.52 6 16 10.48 6 16 6zm1 5h-2v7h2v-7z" style={{ fill: "rgb(10, 163, 163)" }}></path>
                                          </svg>
                                        </span>
                                        <span className="sc-cYYGwx iCwIgn">16.23s</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sc-dWhPOB bytfcq">
                                    <div className="sc-kstrdz sc-hBEYos geLHTC dWjUC">
                                      <div className="sc-kstrdz sc-eFRnhs dmNAkW eGzpUc">Players answered</div>
                                      <div className="sc-kstrdz eibRTR">
                                        <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '28px', height: '28px' }}>
                                          <svg id="icon-questions" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                            <path
                                              d="M16,16 C13.2385763,16 11,13.7614237 11,11 C11,8.23857625 13.2385763,6 16,6 C18.7614237,6 21,8.23857625 21,11 C21,13.7614237 18.7614237,16 16,16 Z M25,24.3125 L7,24.3125 C7,20.2739178 11.0294373,17 16,17 C20.9705627,17 25,20.2739178 25,24.3125 Z"
                                              style={{ fill: "rgb(134, 76, 191)" }}
                                            ></path>
                                          </svg>
                                        </span>
                                        <span className="sc-cYYGwx iCwIgn">2 of 2</span>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                  <div className="sc-kstrdz sc-hBEYos sc-eVQMuq cyTLzH dWjUC eWwErI">
                                    <div className="sc-fTyQHV lmoXqj">
                                      <div className="sc-fYBPgz bYhnBY">
                                        <div className="sc-kstrdz sc-hBEYos sc-dtiVcP sc-dchgTC CpYVy dWjUC dmuweK bWqcub">
                                          <div className="sc-WRemZ uHzUX">Sort by:</div>
                                          <div className="sc-fXwYyN iFFrLx sc-jpSfyY dYvrvn">
                                            <div data-functional-selector="answers-questions-table__sort-mobile" className="sc-bIMZcE bmHBqe">
                                              <div className="css-dzh7t3-container">
                                                <span id="react-select-5-live-region" className="css-7pg0cj-a11yText"></span><span aria-live="polite" aria-atomic="false" aria-relevant="additions text" className="css-7pg0cj-a11yText"></span>
                                                <div className="sc-ciagcW eMrLPg">
                                                  <button color="#333" data-functional-selector="answers-questions-table__sort-mobile__button" className="sc-jEYnBn zRVcl">
                                                    <span>Player</span>
                                                    <div className="sc-kOtWFZ ZzzWf">
                                                      <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                        <svg id="sort-arrow" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                          <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                        </svg>
                                                      </span>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <table
                                        data-functional-selector="answers-questions-table"
                                        aria-label="Players performance on question table. Performance for question number 1. You can sort table by header filters."
                                        className="sc-fARevN lhlhNC sc-beyegn jPkTxr"
                                        role="table"
                                      >
                                        <thead data-functional-selector="answers-questions-table__header" className="sc-bDDOyi ljnsMi">
                                          <tr data-functional-selector="answers-questions-table__header-row" className="sc-lnYokC Mxxaa">
                                            <th colspan="3" role="columnheader" title="Toggle SortBy" id="displayName" width="30%" color="#46178f" data-functional-selector="displayName" className="sc-fhMsBk dCFjXj" style={{ cursor: "pointer" }}>
                                              <button tabindex="0" aria-selected="true" aria-label="Sort table by player nicknames, from A to Z." className="sc-jDEoIF idwVhV">
                                                Player
                                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(70, 23, 143)" }}></path>
                                                </svg>
                                              </span> */}
                                              </button>
                                            </th>
                                            <th role="columnheader" title="Toggle SortBy" id="displayText" width="25%" color="#333" data-functional-selector="displayText" className="sc-fhMsBk epVtyW" style={{ cursor: "pointer" }}>
                                              <button tabindex="0" aria-selected="false" aria-label="Sort table by submitted answers, from A to Z." className="sc-jDEoIF idwVhV">
                                                Answered
                                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                </svg>
                                              </span> */}
                                              </button>
                                            </th>
                                            <th role="columnheader" title="Toggle SortBy" id="status" width="20%" color="#333" data-functional-selector="status" className="sc-fhMsBk jTIOKU" style={{ cursor: "pointer" }}>
                                              <button tabindex="0" aria-selected="false" aria-label="Sort table by submitted answers, from A to Z." className="sc-jDEoIF idwVhV">
                                                Correct/Incorrect
                                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                </svg>
                                              </span> */}
                                              </button>
                                            </th>
                                            <th role="columnheader" title="Toggle SortBy" id="reactionTime" width="10%" color="#333" data-functional-selector="reactionTime" className="sc-fhMsBk gNiLhQ" style={{ cursor: "pointer" }}>
                                              <button tabindex="0" aria-selected="false" aria-label="Sort table by time taken to answer each question. Quickest response first." className="sc-jDEoIF gtaNyG">
                                                Time
                                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                </svg>
                                              </span> */}
                                              </button>
                                            </th>
                                            <th role="columnheader" title="Toggle SortBy" id="points" width="10%" color="#333" data-functional-selector="points" className="sc-fhMsBk gNiLhQ" style={{ cursor: "pointer" }}>
                                              <button tabindex="0" aria-selected="false" aria-label="Sort table by number of points earned for each question. Most to least points." className="sc-jDEoIF gtaNyG">
                                                Points
                                                {/* <span className="sc-eCssSg cAELPS table-sort-icon" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px', height: '24px' }}>
                                                <svg id="sort-arrow" data-functional-selector="table-sort-icon__down" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                  <path d="M7.085 12.501L8.501 11.086 15.501 18.087 22.5 11.086 23.915 12.501 15.501 20.915z" style={{ fill: "rgb(51, 51, 51)" }}></path>
                                                </svg>
                                              </span> */}
                                              </button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="sc-fZvKFD ejeoHz">
                                          {quest.player_answer_info.map((qtplayer) => <>
                                            <tr data-functional-selector="answers-questions-table__row" id="row-0" role="row" className="sc-lnYokC Mxxaa">
                                              <td data-functional-selector="displayName" id="row-0-cell-0" role="cell" width="30%" colspan="3" value="dsad" className="sc-itOPoD bjzUqB">
                                                <div className="sc-kstrdz sc-hBEYos jlvGrj dWjUC">
                                                  <div className="sc-kstrdz sc-hBEYos sc-dYHDcm jlvGrj dWjUC gTTXur"><span className="sc-cbHnyr iVtlyY">{qtplayer.user_name}</span></div>
                                                </div>
                                              </td>
                                              <td data-functional-selector="displayText" id="row-0-cell-1" role="cell" width="25%" value="[object Object]" className="sc-itOPoD bjzUqB">
                                                <div className="sc-kstrdz sc-ixDLba jlvGrj ercQPG">
                                                  <div className="sc-Eryum gBPfxK">
                                                    <div color="#d89e00" type="quiz" className={qtplayer.selected_answer_option_no_index == 0 ? "sc-clsHhM lmcoQi0" : qtplayer.selected_answer_option_no_index == 1 ? "sc-clsHhM hzVFqE1" : qtplayer.selected_answer_option_no_index == 2 ? "sc-clsHhM dFMQva2" : qtplayer.selected_answer_option_no_index == 3 ? "sc-clsHhM cFxYFM3" : 'sc-clsHhM blank'}>
                                                    </div>
                                                  </div>
                                                  <div className="sc-kstrdz sc-hBEYos sc-knXvXO ksuEIT dWjUC djKKtX"><span className="sc-ekkLtS ggRiwX">{qtplayer.answer_option_value ? qtplayer.answer_option_value : 'No Answer'}</span></div>
                                                </div>
                                              </td>
                                              <td data-functional-selector="status" id="row-0-cell-2" role="cell" width="20%" value="WRONG" className="sc-itOPoD bjzUqB">
                                                <div className="sc-kstrdz sc-ixDLba eaPROq ercQPG">
                                                  <div className="sc-bUWuLP iQusgV">
                                                    <div className="sc-Eryum hhCfhf">
                                                      {qtplayer.is_right == 1 ?
                                                        <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                          <svg id="correct-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none">
                                                            <path d="M12.926 22.652L8 17.725 9.767 15.957 12.841 19.031 21.959 9 23.808 10.682z" style={{ fill: "rgb(38, 137, 12)" }}></path>
                                                          </svg>
                                                        </span> :
                                                        <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                          <svg id="timeout-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" stroke-width="0">
                                                            <path d="M24.714 8.414L23.3 7 16.007 14.293 8.714 7 7.3 8.414 14.593 15.707 7.3 23 8.714 24.414 16.007 17.122 23.3 24.414 24.714 23 17.421 15.707z" style={{ fill: "rgb(226, 27, 60)" }}></path>
                                                          </svg>
                                                        </span>
                                                      }
                                                    </div>
                                                  </div>
                                                  <span className="sc-fqiHbb hRzoXK">{qtplayer.is_right == 1 ? 'Correct' : 'Incorrect'}</span>
                                                </div>
                                              </td>
                                              <td data-functional-selector="reactionTime" id="row-0-cell-3" role="cell" width="10%" value="[object Object]" className="sc-itOPoD hjstLm">
                                                <div className="sc-kstrdz sc-hBEYos sc-dYHDcm eCTved dWjUC gTTXur"><div className="sc-kstrdz sc-hBEYos sc-bIyklt irtDPl dWjUC gqufWF">{qtplayer.time_taken}s</div></div>
                                              </td>
                                              <td data-functional-selector="points" id="row-0-cell-4" role="cell" width="10%" value="0" className="sc-itOPoD hjstLm"><div className="sc-kstrdz sc-hBEYos sc-dYHDcm eCTved dWjUC gTTXur">{qtplayer.points}</div></td>
                                            </tr>
                                          </>)}
                                          {/* <tr data-functional-selector="answers-questions-table__row" id="row-1" role="row" className="sc-lnYokC Mxxaa">
                                          <td data-functional-selector="displayName" id="row-1-cell-0" role="cell" width="30%" colspan="3" value="fds" className="sc-itOPoD bjzUqB">
                                            <div className="sc-kstrdz sc-hBEYos jlvGrj dWjUC">
                                              <div className="sc-kstrdz sc-hBEYos sc-dYHDcm jlvGrj dWjUC gTTXur"><span className="sc-cbHnyr iVtlyY">fds</span></div>
                                            </div>
                                          </td>
                                          <td data-functional-selector="displayText" id="row-1-cell-1" role="cell" width="25%" value="[object Object]" className="sc-itOPoD bjzUqB">
                                            <div className="sc-kstrdz sc-ixDLba jlvGrj ercQPG">
                                              <div className="sc-Eryum gBPfxK">
                                                <div color="#e21b3c" type="quiz" className="sc-clsHhM lmcoQi">
                                                  <span className="sc-eCssSg cAELPS sc-GqfZa emfPRz" style={{ display: 'inline-block', verticalAlign: 'middle', width: '30px', height: '30px' }}>
                                                    <svg id="option-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                      <path d="M27,24.559972 L5,24.559972 L16,7 L27,24.559972 Z" style={{ fill: "rgb(197, 0, 40)" }}></path>
                                                    </svg>
                                                  </span>
                                                  <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '26px', height: '26px' }}>
                                                    <svg id="option-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                      <path d="M27,24.559972 L5,24.559972 L16,7 L27,24.559972 Z" style={{ fill: "rgb(255, 255, 255)" }}></path>
                                                    </svg>
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="sc-kstrdz sc-hBEYos sc-knXvXO ksuEIT dWjUC djKKtX"><span className="sc-ekkLtS ggRiwX">Flocculation</span></div>
                                            </div>
                                          </td>
                                          <td data-functional-selector="status" id="row-1-cell-2" role="cell" width="20%" value="CORRECT" className="sc-itOPoD bjzUqB">
                                            <div className="sc-kstrdz sc-ixDLba eaPROq ercQPG">
                                              <div className="sc-bUWuLP iQusgV">
                                                <div className="sc-Eryum hhCfhf">
                                                  <span className="sc-eCssSg cAELPS" style={{ display: 'inline-block', verticalAlign: 'middle', width: '32px', height: '32px' }}>
                                                    <svg id="correct-icon" data-functional-selector="icon" viewBox="0 0 32 32" focusable="false" stroke="none" >
                                                      <path d="M12.926 22.652L8 17.725 9.767 15.957 12.841 19.031 21.959 9 23.808 10.682z" style={{ fill: "rgb(38, 137, 12)" }}></path>
                                                    </svg>
                                                  </span>
                                                </div>
                                              </div>
                                              <span className="sc-fqiHbb hRzoXK">Correct</span>
                                            </div>
                                          </td>
                                          <td data-functional-selector="reactionTime" id="row-1-cell-3" role="cell" width="10%" value="[object Object]" className="sc-itOPoD hjstLm">
                                            <div className="sc-kstrdz sc-hBEYos sc-dYHDcm eCTved dWjUC gTTXur"><div className="sc-kstrdz sc-hBEYos sc-bIyklt irtDPl dWjUC gqufWF">28.5s</div></div>
                                          </td>
                                          <td data-functional-selector="points" id="row-1-cell-4" role="cell" width="10%" value="842" className="sc-itOPoD hjstLm"><div className="sc-kstrdz sc-hBEYos sc-dYHDcm eCTved dWjUC gTTXur">842</div></td>
                                        </tr> */}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="sc-NwpXa jJDEZI"></div>
                                </div>
                              </div>


                            </Modal.Body>
                            {/* <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={handleClose}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer> */}
                          </>)}
                        </Modal>
                      </table>
                    </div>
                  </div>
                </section>

              </main>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default QuestionsList;
