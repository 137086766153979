/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Test = (state) => {
  console.log('Test', state);
  const navigate = useNavigate();
  // navigate('/game/play')
  React.useEffect(() => {
    console.log('Test', state);
    navigate('/game/play')

  },[state])
  return (
<div>
  {/* Hii test */}
</div>
  );
};

export default Test;
