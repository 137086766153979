import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import ChatView from 'src/views/chat/';
import ContactForm from 'src/views/contact/Contact';
// End Teacher Records
import AllRecords from 'src/views/allrecords/AllRecord';
import QuestionSummary from 'src/views/allrecords/QuestionSummary';
import QuestionPlayers from 'src/views/allrecords/QuestionPlayers';
import QuestionsList from 'src/views/allrecords/QuestionsList';
import QuestionFeedback from 'src/views/allrecords/QuestionFeedback';
// End Teacher Records
// Student Records
import StudAllRecords from 'src/views/allstudrecords/StudAllRecord';
import StudQuestionSummary from 'src/views/allstudrecords/StudQuestionSummary';
import StudQuestionPlayers from 'src/views/allstudrecords/StudQuestionPlayers';
import StudQuestionsList from 'src/views/allstudrecords/StudQuestionsList';
// import StudQuestionFeedback from 'src/views/allstudrecords/StudQuestionFeedback';
// End Student Records
// Admin Plans
import Addplan from 'src/views/plans/AddPlan';
import Viewplan from 'src/views/plans/ViewPlan';
// End Admin Plans
// Admin Training
import ListTraining from 'src/views/training-videos/ViewTraining';
import AddTraining from 'src/views/training-videos/AddTraining';
// End Admin Training
import DashboardView from 'src/views/reports/DashboardView';
import HomeView from 'src/views/home/HomeView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import About from 'src/views/aboutus/About';
import TrainingVideos from 'src/views/user-training/Training';
import Privacy from 'src/views/privacy-policy/Privacy';
import TermofUse from 'src/views/term-of-use/Termofuse';
import LoginView from 'src/views/auth/LoginView';
import AdminLoginView from 'src/views/auth/AdminLoginView';
import SettingsView from 'src/views/settings/SettingsView';
import NewQuiz from 'src/views/product/ProductListView/newQuiz';
import Collections from 'src/views/collection';
import GamePanel from 'src/views/game';
import VerifyForm from 'src/views/auth/VerifyForm';
import ForgotPwdView from 'src/views/auth/ForgotPwd';
import ClassView from 'src/views/classes';
import EditCollection from 'src/views/collection/edit';
import EditClass from 'src/views/classes/edit';
import AuthProtect from 'src/components/AuthProtect';
import StudentHome from 'src/views/student/quiz';
import Membership from 'src/views/membership';
import MembershipStu from 'src/views/student/membership';
import CustomerListView from 'src/views/customer/CustomerListView';
import TransactionListView from 'src/views/transaction/TransactionListView';
import ContactListView from 'src/views/contactData/ContactListView';
import AdminMembershipDetails from 'src/views/admin-membership/MembershipDetails';
import AdminManageMembership from './views/admin-membership/ManageMembership';
import QuizLayout from './layouts/QuizLayout';
import QuizLayoutStu from './layouts/QuizLayoutStu';
import AdminLayout from './layouts/AdminLayout';
import OnlyHeaderLayout from './layouts/OnlyHeaderLayout';
import ClassLayout from './layouts/ClassLayout';
import StudentLayout from './layouts/StudentLayout';
import Test from './views/game/Test';
// ---------------Blog--------------//
import AddBlog from './views/blog/AddBlog';
import ViewBlog from './views/blog/ViewBlog';
import Preview from './views/preview/Preview';
import UserBlog from './views/user-blog/UserBlog';
import UserViewBlog from './views/user-blog/UserViewBlog';
import Pricing from './views/User-pricing/Pricing';
import PriceSubscription from './views/User-pricing/PriceSubscription';
// import StoreContext from 'src/context/index';

// const { store } = React.useContext(StoreContext);

const routes = [
  {
    path: 'game',
    element: null,
    children: [
      { path: 'play', element: <GamePanel /> }
    ]
  },
  { path: 'test', element: <Test /> },
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> }, // security selected
      { path: 'chat', element: <ChatView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/app/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: 'home', element: <HomeView /> },
      { path: 'signup', element: <RegisterView /> },
      { path: 'forgot', element: <ForgotPwdView /> },
      { path: 'signin', element: <LoginView /> },
      { path: 'contact', element: <ContactForm /> },
      { path: 'verifyemail', element: <VerifyForm /> },
      { path: 'about', element: <About /> },
      { path: 'training-video', element: <TrainingVideos /> },
      { path: 'blog-preview', element: <Preview /> },
      { path: 'blog', element: <UserBlog /> },
      { path: 'blog/:slug', element: <UserViewBlog /> },
      { path: 'privacy-policy', element: <Privacy /> },
      { path: 'term-of-use', element: <TermofUse /> },
      { path: 'pricing', element: <Pricing /> },
      { path: 'stripe-subscription/:id', element: <PriceSubscription /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'teacher',
    element: <DashboardLayout />,
    children: [
      {
        path: 'home', element: <ProductListView /> // security selected
      },
      { path: 'account', element: <AccountView /> }, // security selected
      { path: 'signup', element: <RegisterView /> },
      { path: 'collections', element: <Collections /> }, // security selected
      { path: 'class', element: <ClassView /> }, // security selected
      { path: 'contact', element: <ContactForm /> },
      { path: 'reports', element: <AllRecords /> },
      { path: 'reports/question_summary/:id', element: <QuestionSummary /> },
      { path: 'reports/question_players/:id', element: <QuestionPlayers /> },
      { path: 'reports/question_list/:id', element: <QuestionsList /> },
      { path: 'reports/question_feedback', element: <QuestionFeedback /> },
      { path: 'membership', element: <Membership /> }, // security selected
      { path: 'signin', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="/teacher/404" /> }
    ]
  },
  {
    path: 'teacher',
    element: <QuizLayout />,
    children: [
      { path: 'new', element: <NewQuiz /> }, // security selected
      { path: 'signup', element: <RegisterView /> },
      { path: 'signin', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="/teacher/404" /> }
    ]
  },
  {
    path: 'collection',
    element: <OnlyHeaderLayout />,
    guard: AuthProtect,
    children: [
      { path: 'edit', element: <EditCollection /> }, // security selected
      { path: 'signup', element: <RegisterView /> },
      { path: 'signin', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="/collection/404" /> }
    ]
  },
  {
    path: 'class',
    element: <ClassLayout />,
    guard: AuthProtect,
    children: [
      { path: 'edit', element: <EditClass /> }, // security selected
      { path: 'signup', element: <RegisterView /> },
      { path: 'signin', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="/class/404" /> }
    ]
  },
  {
    path: 'student',
    element: <StudentLayout />,
    children: [
      { path: 'account', element: <AccountView /> }, // security selected
      { path: 'home', element: <StudentHome /> }, // security selected
      { path: 'chat', element: <ChatView /> }, // security selected
      { path: 'class', element: <ClassView /> }, // security selected
      { path: 'membership', element: <MembershipStu /> }, // security selected
      { path: 'edit', element: <EditClass /> }, // security selected
      { path: 'contact', element: <ContactForm /> },
      { path: 'reports', element: <StudAllRecords /> },
      { path: 'reports/question_summary/:id', element: <StudQuestionSummary /> },
      { path: 'reports/question_players/:id', element: <StudQuestionPlayers /> },
      { path: 'reports/question_list/:id', element: <StudQuestionsList /> },
      // { path: 'reports/question_feedback', element: <StudQuestionFeedback /> },
      { path: 'signin', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <StudentHome /> },
      { path: '*', element: <Navigate to="/student/404" /> }
    ]
  },
  {
    path: 'student',
    element: <QuizLayoutStu />,
    children: [
      { path: 'new', element: <NewQuiz /> }, // security selected
      { path: 'signup', element: <RegisterView /> },
      { path: 'signin', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="/teacher/404" /> }
    ]
  },
  {
    path: 'admin',
    element: <AdminLayout />,
    children: [
      { path: '/', element: <DashboardView /> },
      { path: 'main', element: <DashboardView /> },
      { path: 'users', element: <CustomerListView /> },
      { path: 'transaction_history', element: <TransactionListView /> },
      { path: 'contact', element: <ContactListView /> },
      { path: 'membership', element: <AdminMembershipDetails /> },
      { path: 'manage-membership/:user_id', element: <AdminManageMembership /> },
      { path: 'add-plan/', element: <Addplan /> }, // Add Plan
      { path: 'edit-plan/:id', element: <Addplan /> }, // Edit Plan
      { path: 'view-plan', element: <Viewplan /> }, // View Plan
      { path: 'view-training', element: <ListTraining /> }, // View Training List
      { path: 'add-training', element: <AddTraining /> }, // Add Training List
      { path: 'edit-training/:id', element: <AddTraining /> }, // Edit Training
      { path: 'view-blog', element: <ViewBlog /> },
      { path: 'edit-blog/:id', element: <AddBlog /> },
      { path: 'add-blog', element: <AddBlog /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/teacher/404" /> }
    ]
  },
  {
    path: 'admin',
    children: [
      { path: 'signin', element: <AdminLoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];
export default routes;
