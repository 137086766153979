/*eslint-disable*/
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CircularProgress,
  MenuItem,
  Typography,
  Button
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { signUp } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Page from 'src/components/Page';
import { getBlog } from 'src/utils/Api';
import global from 'src/utils/global';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 50
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    maxWidth: 345,
    maxHeight: 355
  },
  media: {
    height: 140,
  },
  blog_content: {
    display: '-webkit-box',
    textAlign: 'left',
    width: '100% !important'
  },
  gridContainer: {
    marginTop: '25px',
    paddingLeft: "40px",
    paddingRight: "40px",
    justifyContent: 'left !important',
    marginBottom: '25px',
    textAlign: 'left'
  }
}));
const UserBlog = () => {
  const [blogContent, setBlogTitle] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    getBlog().then((res) => {
      // console.log('blogdata ', res)
      setBlogTitle(res.data)
    }).catch((err) => {
      console.log('catcherr', err)
    })
  }, [])

  const handleCard = (slug) => {
    // console.log("sluggg",slug)
    window.open(`${window.location.origin}/blog/${slug}`);

  }
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <Page
      className={classes.root}
      title="Blog"
    >
      <Box
        component="div"
        mt={15}
        className='blog-container'
      >
        <Grid
          container
          spacing={7}
          justify="center"
        >
          <Grid
            xl={8}
            lg={8}
            md={8}
            xs={12}
            item
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justify="center">
                <Grid item className={classes.contentContainer}>

                  <div className="about-banner-content" style={{ height: '300px', color: '#F3F3F3' }}>
                    BLOG
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
      // style={{justifyContent:'left'}}
      >
        {blogContent?.length > 0 ? blogContent.map((item, index) => (
          <Grid item xs={12} sm={6} md={4}>
            <Card className={`${classes.card} blog-card`} onClick={() => { handleCard(item.slug) }}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={`${global.serverUrl}upload/blog/${item.image}`}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.title}
                  </Typography>
                  <Typography className='title_desc' component="p">

                    {item.title_desc}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
                <Button size="small" color="primary">
                  Learn More
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        ))
          :
          <Typography>No Records</Typography>}


      </Grid>


    </Page>
  );
}

export default UserBlog;

