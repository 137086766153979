/* eslint-disable */
import React,{useEffect} from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Button,
  Card,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Flip, Bounce } from 'react-awesome-reveal';
import { Elements } from '@stripe/react-stripe-js';
import cogoToast from 'cogo-toast';
import { loadStripe } from '@stripe/stripe-js';
import global from 'src/utils/global';
import authChecker from 'src/utils/authHelper';
import Page from 'src/components/Page';
import { membershipUpgradeApi, adminGetPlan, currentUSerPlan, cancelPlan } from 'src/utils/Api';
import CheckoutForm from './CheckoutForm';

const useStyles = makeStyles((theme) => ({
  rootChat: {
    padding: 15,
    marginTop: 9,
    marginBottom: 70,
    height: 600,
    overflowY: 'scroll'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  welcome: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: theme.spacing(3),
    height: 800,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  msgInputContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: -50
  },
  membershipBtn: {
    marginTop: 20,
  },
  membershipcancelBtn: {
    marginTop: 20,
    backgroundColor: 'red !important',
    color: 'white'
  }
}));


const Membership = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [membershipType, setMembershipType] = React.useState('');
  const [planID, setPlanID] = React.useState('');
  const [userID, setplanUserID] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState(JSON.parse(localStorage.getItem('brainaly_user')));
  const [allPlans, setAllPlans] = React.useState('');
  const [userPlan, setUserPlan] = React.useState('');
  const stripePromise = loadStripe(global.stripePKey);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (!authChecker('authCheck')) {
      navigate('/', { replace: true });
      return;
    }
    adminGetPlan().then((res) => {
      console.log('teacher palns', res)
      setAllPlans(res.data)
    })
    //Get Current User Plan
    currentUSerPlan({ id: profile?.userId }).then((res) => {
      console.log('getCurrentUserPlans', res)
      setUserPlan(res.plan_data)
    })
  }, [dialogOpen, cancelDialogOpen]);
  const handleClose = () => {
    setDialogOpen(false);
  };
  //Cancel Model
  const cancelOpen = (plan_id, user_id) => {
    console.log('type', plan_id, user_id)
    setPlanID(plan_id);
    setplanUserID(user_id)
    setCancelDialogOpen(true);
  };

  //CancelMembership Function
  const cancelMemberShip = () =>{

    cancelPlan({ userID, planID }).then((res)=>{
      console.log('ress success', res)
      if(res.code === 200){
        console.log('sucesss', res)

        setCancelDialogOpen(false);

      }else{
        console.log('error', res)

        setCancelDialogOpen(false);
      }
    }).catch((err)=>{
        console.log('cancel serve err', err)
    })

  }

  const userBadgeCal = (score) => {
    if (score < 30) {
      return 'bronze';
    } if (score < 60) {
      return 'silver';
    }
    return 'gold';
  };

  const handleOpen = (plan_id, type) => {
    console.log('type', type, plan_id)
    setPlanID(plan_id);
    setMembershipType(type);
    console.log(membershipType);
    setDialogOpen(true);
  };

  const payNow = (info) => {
    console.log('payNow call', info);
    setLoading(true);
    const payInfo = {
      token: info.token,
      ...profile,
      plan_id: planID,
      payType: membershipType,

    };

    console.log(payInfo, 'payInfooooo Teacher');
    membershipUpgradeApi(payInfo).then((res) => {
      console.log(res, 'recieve api data upgrade');
      const userData = {
        userId: res.userInfo.u_id,
        userEmail: res.userInfo.u_email,
        userName: res.userInfo.u_name,
        userAvatar: res.userInfo.u_avatar,
        user_birth: res.userInfo.u_birthday,
        userType: res.userInfo.u_type,
        userSchool: res.userInfo.u_school,
        userPhone: res.userInfo.u_phonenumber,
        userMembership: res.userInfo.u_membership_type,
        userMemberdate: res.userInfo.u_expire_date,
        userBadge: userBadgeCal(res.userInfo.u_score)
      };
      // console.log(setProfile(userData));
      setTimeout(() => {
        setProfile(userData);
        setLoading(false);
      }, 100);
      localStorage.setItem('brainaly_user', JSON.stringify(userData));
      // setProfile(userData);
      console.log(profile);
      cogoToast.success('Your membership is upgraded', { position: 'bottom-right' });
      handleClose();
    }).catch(() => {
      setTimeout(() => {
        setLoading(false);
      }, 100);
      cogoToast.warn('There was a error, Please try again or contact admin', { position: 'bottom-right' });
      handleClose();
    });
  };

  return (
    <Page
      className={classes.root}
      title="Teacher MemberShip"
    >
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Membership Upgrade</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please insert your payment info. it is not save in the back-end.
          </DialogContentText>
          <Elements stripe={stripePromise}>
            <CheckoutForm payinfo={payNow} isLoading={loading} />
          </Elements>
        </DialogContent>
      </Dialog>
      <Dialog
        open={cancelDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Membership Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel membership.
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={cancelMemberShip} style={{ backgroundColor: '#0C003C', color: 'white' }} variant="success">
            Yes
          </Button>
          <Button onClick={()=>{setCancelDialogOpen(false)}} style={{ color: 'white', backgroundColor: 'red' }} variant="danger">
            No
          </Button>
        </DialogActions>

      </Dialog>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          alignContent="space-around"
          direction="row"
          justify="center"
        >
          {allPlans.length > 0 ? allPlans.map((item) => (

            <Grid
              key={item.id}
              item
              xl={3}
              lg={3}
              md={3}
              xs={3}
            >
              <Flip left cascade delay={400} duration={700}>
                <Box mt={3}>
                  <Card className={clsx(classes.welcome)}>
                    {
                      userPlan?.plan_id === item.id ? <div className="ribbon ribbon-top-right"><span>Current</span></div> : null
                    }
                    <Bounce delay={1100}>
                      <img src={`${global.serverUrl}upload/${item.plan_img}`} alt="Image" className="membershipMark" />
                    </Bounce>
                    <h1 className="membership-title">{`${item.plan_title}`}</h1>
                    <p className="membership-description">
                      $
                      <span>{`${item.plan_amount}`}</span>

                    </p>
                    <p className="membership-description">{`${item.plan_month} Days`}</p>
                    {

                      userPlan?.plan_id === item.id ? <p className="membership-description">{userPlan.end_date.split('T')[0].substring(0, 10)}</p> : null
                    }
                    {item?.id != 1 ?  <Button style={{ backgroundColor:userPlan?.plan_id === item.id ? 'grey':'#0C003C', color:'white'}} disabled={userPlan?.plan_id === item.id ? true:false} className={classes.membershipBtn} onClick={() => { handleOpen(item.id, item?.plan_title); }}>Upgrade</Button> : null}
                    {item?.id != 1 && userPlan?.plan_id === item.id ?  <Button className={classes.membershipcancelBtn} onClick={() => { cancelOpen(item.id, userPlan?.user_id); }}>Cancel</Button> : null}   
                    <div>
                      <p className="membership-item">{`${item.plan_quiz} Quizes`}</p>
                      <p className="membership-item">{`${item.plan_classes} Classes`}</p>
                      <p className="membership-item">{`${item.plan_collection} Collections`}</p>

                      <p className="membership-item">{`${item.no_of_virtual_participant} Class Participants`}</p>
                      <p className="membership-item">{`${item.class_duration} Class Duration in Minutes`}</p>
                      <p className="membership-item">{`${item.no_of_recordings} Recordings in Minutes`}</p>
                      <p className="membership-item">{`${item.data_transfer} Data Trasfer`}</p>
                      <p className="membership-item">{`${item.storage} Storage`}</p>


                    </div>
                  </Card>
                </Box>
              </Flip>
            </Grid>
          )) : 'No Plans'}
        </Grid>
      </Container>
    </Page>
  );
};

export default Membership;
