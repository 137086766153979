/* eslint-disable */
import React from 'react';
import {
  Grid,
  makeStyles,
  Box,
  List,
  ListItem
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocialMediaIconsReact } from 'social-media-icons-react';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    backgroundColor: '#5E2B66'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 40
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  linkeTitle: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold'
  },
  linkStyel: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
    marginLeft: 30
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      className={classes.boxContainer}
    >
      <Grid
        container
        spacing={7}
        style={{ background: '#0C003C' }}
        className="footer-container foot_custom"
      >
        <Grid
          xl={2}
          lg={2}
          md={6}
          xs={12}
          item
          className="footer-logo footer-touch foot_custom"
        >
          <a className='logo-img foot_custom ' href="/">
            <img className='foot-img' src="/static/brainaly_new_logo-removebg-preview.png" alt="footer-logo" style={{
              margin: 20, maxWidth: '187px',
              height: 120, width: '110%'
            }} />
          </a>
        </Grid>

        <Grid
          xl={2}
          lg={2}
          md={6}
          xs={12}
          className="footer-touch foot_custom"

          item
        >
          <List component="nav" className="footer-box foot_custom" aria-label="contacts">

            <ListItem button className={classes.linkeTitle}>
              <RouterLink

                className="footer-title"
                to="/"
              >
                Home
              </RouterLink>
            </ListItem>

            <ListItem button className="links-box">
              {/* <a className="footer-links"  href="/about"> About Us</a>
            <a className="footer-links"  href="/privacy-policy">Privacy</a>
            <a className="footer-links"  href="/term-of-use">Terms of Use</a>
            <a className="footer-links"  href="/contact">Contact</a> */}
              <RouterLink
                className="footer-links"
                to="about"

              >
                About Us
              </RouterLink>

              <RouterLink
                className="footer-links"
                to="privacy-policy"
              >
                Privacy
              </RouterLink>

              <RouterLink
                className="footer-links"
                to="term-of-use"
              >
                Terms of Use
              </RouterLink>

              <RouterLink
                className="footer-links"
                to="contact"
              >
                Contact Us
              </RouterLink>

              <RouterLink
                className="footer-links"
                to="blog"
              >
                Blog
              </RouterLink>

              <RouterLink
                className="footer-links"
                to="pricing"
              >
                Pricing
              </RouterLink>

              <RouterLink
                className="footer-links"
                to="training-video"
              >
                Training Videos
              </RouterLink>

            </ListItem>
          </List>
        </Grid>

        <Grid
          xl={2}
          lg={2}
          md={6}
          xs={12}
          className="footer-touch foot_custom"

          item
        >
          <List component="nav" className="footer-box foot_custom" aria-label="contacts">
            <ListItem className="foot_touch foot_custom" button>
              {/* <RouterLink
                className="footer-title"
                to="/signup"
              >
                Get in touch
              </RouterLink> */}
              <a className="footer-title foot_touch"
                href='/contact'
                to="/contact">  Get in touch </a>
            </ListItem>
            <ListItem button className={[classes.linkeTitle, 'foot_touch']}>

              <a className="footer-links"
                href='/contact'
                to="/contact">  E-Mail: info@brainaly.com </a>
              {/* <RouterLink
                className="footer-links"
                to="/contact"
              >
                E-Mail: info@brainaly.com
                <FontAwesomeIcon icon="coffee" />
              </RouterLink> */}
            </ListItem>
          </List>
        </Grid>

        <Grid
          xl={2}
          lg={2}
          md={6}
          xs={12}
          className="footer-touch foot_custom"

          item
        >
          <List component="nav" className="footer-box foot_custom" aria-label="contacts">
            <ListItem button className="footer-title">
              Find Us Here
            </ListItem>
          </List>

          <List component="nav" className="footer-box" aria-label="contacts">
            <ListItem button className="footer-links">
              {/* Phone: +1(708) 497-9728 */}
              Phone: +1(800)-242-3110
            </ListItem>
          </List>
          <List component="nav" className="footer-social" aria-label="contacts">
            <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" icon="facebook" iconColor="rgba(255,255,255,1)" backgroundColor="#4267B2" url="https://www.facebook.com/Brainaly/" size="28" />
            <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" icon="twitter" iconColor="rgba(255,255,255,1)" backgroundColor="#1DA1F2" url="https://twitter.com/BrainalyApp" size="28" />
            <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" icon="instagram" iconColor="rgba(255,255,255,1)" backgroundColor="#fb3958" url="https://www.instagram.com/brainalyapp/" size="28" />
            <SocialMediaIconsReact borderColor="rgba(0,0,0,0.25)" icon="linkedin" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(26,166,233,1)" url="https://linkedin.com/company/brainaly" size="28" />
          </List>
        </Grid>

        <Grid
          xl={2}
          lg={2}
          md={6}
          xs={12}
          className="footer-touch foot_custom"

          item
        >
          <List component="nav" className="footer-box foot_custom" aria-label="contacts">
            <ListItem button className="footer-title privacy-foot">
              Copyright © 2022 Brainaly. All Rights Reserved.
            </ListItem>
          </List>
        </Grid>

        <Grid
          xl={2}
          lg={2}
          md={6}
          xs={12}
          item
          className="footer-bottom footer-touch foot_custom"
        >
          <img
            className='elephent'
            src="/static/images/elephant.png"
            alt="element-logo"
            style={{
              margin: 30, maxHeight: 150, resize: 'contain'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
