/* eslint-disable */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import {
  makeStyles, ownerDocument,
} from '@material-ui/core';
import Page from 'src/components/Page';
import IntroSection from './IntroSection';
import Gamification from './Gamification';
// import TeacherSection from './TeacherSection';
import VirtualSection from './VirtualSection';
import StoreContext from 'src/context/index';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw'
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 40
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const HomeView = () => {

  const classes = useStyles();

  const { store } = React.useContext(StoreContext);

  React.useEffect(() => {
    if (Object.keys(store).length>0) {

      document.getElementById(store.scroll?store.scroll:0).scrollIntoView({behavior: "smooth"});
    }
  }, [store])

  return (
    <Page
      className={classes.root}
      title="Home"
    >
        <IntroSection />
      <div id='1'>
        <Gamification />
      </div>

      {/* <TeacherSection /> */}
      <div id='2'>
      <VirtualSection />
      </div>
    </Page>
  );
};

export default HomeView;
