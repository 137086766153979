/*eslint-disable*/
import React, { Fragment } from 'react';
import styles from "./pricing.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Col, Container, Row, ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import { adminGetPlan } from 'src/utils/Api';
import Page from 'src/components/Page';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

const Pricing = () => {
    const [plans, setPlans] = React.useState([])

    React.useEffect(() => {
        adminGetPlan().then((res) => {
            console.log('ress', res)
            setPlans(res.data)

        }).catch((err) => {
            console.log('catcherr', err)
        })

    }, [])
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });
    return (
        <Page
            title="Pricing"
        >
            <Fragment >
                <h1 className={styles.head}>Start Online Classes with</h1>
                <h1 style={{ fontSize: '42px', fontWeight: "bolder" }}>Brainaly</h1>
                <section className={`${styles.pricing} py-5`}>
                    <Container className="container">
                        <Row className="row ">
                            {plans.length > 0 ? plans.map((item, index) => (
                                <Col className="col-lg-3 mb-4">
                                    <Card className={`${styles.card} mb-5 mb-lg-0`}>
                                        <Card.Body className="card-body">
                                            <Card.Title className={`${styles.cardTitle} text-uppercase text-center`}>{item.plan_title}</Card.Title>
                                            <h6 className={`${styles.cardPrice} text-center`}>${item.plan_amount}<span className={`${styles.period}`}>/{item.plan_month} days</span></h6>
                                            <div className={`d-grid ${styles.btnDiv}`}>
                                                <RouterLink to={`/stripe-subscription/${item.id}`}>
                                                    <span className={`${styles.btn} text-uppercase`}>{item.plan_title}</span>
                                                </RouterLink>
                                            </div>
                                            <hr className={`${styles.hr}`} />
                                            <ListGroup as="ul">
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span>  {`${item.plan_quiz} Quizes`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.plan_classes} Classes`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.plan_collection} Collections`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.no_of_virtual_participant} Class Participants`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.class_duration} Class Duration in Minutes`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.no_of_recordings} Recordings in Minutes`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.data_transfer} Data Trasfer in Gb(s)`}</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> {`${item.storage} Cloud Storage in Gb(s)`}</ListGroupItem>
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) :
                                <Col className="col-lg-3">
                                    <Card className={`${styles.card} mb-5 mb-lg-0`}>
                                        <Card.Body className="card-body">
                                            <Card.Title className={`${styles.cardTitle} text-uppercase text-center`}>Free</Card.Title>
                                            <h6 className={`${styles.cardPrice} text-center`}>$0<span className={`${styles.period}`}>/month</span></h6>
                                            <div className={`d-grid ${styles.btnDiv}`}>
                                                <a href="#" className={`${styles.btn} btn-primary text-uppercase`}>Free Trail</a>
                                            </div>
                                            <hr className={`${styles.hr}`} />
                                            <ListGroup as="ul">
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span>  HD Video</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> Moodle Integration</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> Analytics & Attendance</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> MP4 Recordings</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> Custom Classroom Settings</ListGroupItem>
                                                <ListGroupItem as="li" className={`${styles.li}`}><span>&#10003;</span> Custom Branding (logo, color, wall paper)</ListGroupItem>

                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </Container>
                </section>
                <div>
                    <p className={`${styles.info}`}>You can upgrade, downgrade, or cancel your subscription anytime. No contracts, no hidden charges.</p>
                    {/* <p className={`${styles.info}`}>Prefer paying via Paypal? <a href="#" style={{ color: "#3388fe" }}>Click here</a> to subscribe using Paypal.</p> */}
                </div>
                <h3 className={`${styles.plan_comp}`}>Plan Comparison</h3>

                <table className={`${styles.table}`}>
                    <thead className={`${styles.tb_head}`}>
                        <tr>
                            <th>FEATURE(s)</th>
                            {plans.map((item) => {
                                return (
                                    <th>{item.plan_title}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className={`${styles.tb_body}`}>
                        <tr>
                            <td className={`${styles.tb_hr} `} colSpan={7}><hr className={`${styles.hr} mb-0`} /></td>
                        </tr>
                        <tr className={`${styles.tr}`}>
                            <td className={`${styles.td}`}>No. of Quizes</td>
                            <td className={`${styles.td}`}>No of Classes</td>
                            <td className={`${styles.td}`}>No. of Collections</td>
                            <td className={`${styles.td}`}>No. of Participants</td>
                            <td className={`${styles.td}`}>Class Duration</td>
                            <td className={`${styles.td}`}>No. of Recordings</td>
                            <td className={`${styles.td}`}>Data Trasfer Limit</td>
                            <td className={`${styles.td}`}>Cloud Storage</td>
                        </tr>
                        {plans.map((item) => {
                            return (<tr className={`${styles.tr}`}>
                                <td className={`${styles.td}`}>{item.plan_quiz}</td>
                                <td className={`${styles.td}`}>{item.plan_classes}</td>
                                <td className={`${styles.td}`}>{item.plan_collection}</td>
                                <td className={`${styles.td}`}>{item.no_of_virtual_participant}</td>
                                <td className={`${styles.td}`}>{item.class_duration} Minutes(s)</td>
                                <td className={`${styles.td}`}>{item.no_of_recordings}</td>
                                <td className={`${styles.td}`}>{item.data_transfer} Gb(s)</td>
                                <td className={`${styles.td}`}>{item.storage} Gb(s)</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </Fragment>
        </Page>
    );
}

export default Pricing;
