/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Card,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CardMedia,
  CircularProgress,
  MenuItem
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { signUp } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 50
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 20
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const About = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [accountType, setAcctType] = React.useState('teacher');
  const [isLoading, setIsLoading] = React.useState(false);
  const handChangeAccType = (value) => {
    console.log(value.target.value);
    setAcctType(value.target.value);
  };
  const handleClickShowPassword = () => {
    console.log('asdasdasdsd');
  };
  const handleMouseDownPassword = () => {
    console.log('asdasdasd');
  };
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  return (
    <Page
      className={classes.root}
      title="About Us"
    >
      <Box
        component="div"
        mt={15}
        className='about-container'
      >
        <Grid
          container
          spacing={7}
          justify="center"
        >
          <Grid
            xl={8}
            lg={8}
            md={8}
            xs={12}
            item
          >
            <Fade left>
              <Grid item xs={12} container direction="column" spacing={2} justify="center">
                <Grid item className={classes.contentContainer}>
                  {/* <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '60%', margin: 'auto' }}>
                    <img src="/static/logo.png" alt="logo" width="100%" />
                </div> */}
                  <div className="about-banner-content" style={{ height: '300px', color: '#F3F3F3' }}>
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>


      <Box
        component='div'
        className="about-overview"
      >

        <Grid
          container
          spacing={7}

          style={{ justifyContent: 'center' }}
        >
          <Grid
            xl={10}
            lg={10}
            md={10}
            xs={12}
            item
          >
            <Fade right>
              <Grid item xs={12} container display="flex" direction="column" spacing={2} justifyContent="center">
                <Grid item xs>
                  <div className="about-overview-head" style={{ color: '#01025C', fontWeight: 'bold' }}>About Us</div>
                </Grid>
                <Grid item className={classes.contentContainer}>
                  <div className="about-overview-text" style={{ color: '#000' }}>
                    Brainaly is a leading educational application that uses gamification to make learning fun as well as enhance retention. Our platform is easy to use so teachers can quickly create quizzes for their classes, communicate with students in real time using a chat function and make data-driven decisions on where to focus based on the rich analytics the application provides.
                  </div>
                </Grid>
              </Grid>

            </Fade>

          </Grid>
        </Grid>
      </Box>

      <Box
        component='div'>
        <Grid
          container
          spacing={7}
          style={{ justifyContent: 'center' }}
        >
          <Grid
            xl={10}
            lg={10}
            md={10}
            xs={12}
            item
          >
            <Fade left>
              <Grid item className="learners-container" xs={12} container display="flex" direction="column" spacing={2} justifyContent="center">
                <Grid item xs>
                  <div className="learners-head" style={{ color: '#01025C', fontWeight: 'bold' }}>Facilitating the Education of All Students</div>
                </Grid>
                <Grid item className={classes.contentContainer}>
                  <div className="learners-text" style={{ color: '#000' }}>
                    The collection of products and services offered by Brainaly is designed to serve learners at every level, beginning with kindergarten and continuing all the way through their careers.
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12} spacing={2} style={{ display: "flex", flexWrap: 'wrap', justifyContent: "center", marginTop: 30 }}>
                <Grid item xs={12} sm={5} md={5} className="serving-box" style={{ padding: 20, marginBottom: 30 }}>
                  <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                    <img src="../about_pic/call.png" alt="Image" width="100%" />
                  </div>
                  <Grid item>
                    <div className="serving-head" style={{ color: '#01025C', fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>K-12</div>
                  </Grid>
                  <Grid item>
                    <div className="serving-text" style={{ color: '#000' }}>
                      The Brainaly platform offers schools a way to quickly and easily take their learning online. With all the tools you need to deliver engaging and impactful training, Brainaly makes it easy for schools to get up and running with online learning.
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={5} md={5} className="serving-box" style={{ padding: 20, marginBottom: 30 }}>
                  <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                    <img src="../about_pic/online-teaching.png" alt="Image" width="100%" />
                  </div>
                  <Grid item>
                    <div className="serving-head" style={{ color: '#01025C', fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>Higher Education</div>
                  </Grid>
                  <Grid item>
                    <div className="serving-text" style={{ color: '#000' }}>
                      Brainaly offers cutting-edge technologies and services that support institutions in their efforts to adapt successfully to a changing environment. Institutions are able to better focus on student achievement and make improvements to that success across their whole student journey with the assistance of these tools and services.
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={5} md={5} className="serving-box" style={{ padding: 20 }}>
                  <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                    <img src="../about_pic/wenibar.png" alt="Image" width="100%" />
                  </div>
                  <Grid item>
                    <div className="serving-head" style={{ color: '#01025C', fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>Corporate Training</div>
                  </Grid>
                  <Grid item>
                    <div className="serving-text" style={{ color: '#000' }}>
                      Brainaly offers businesses the means by which they can provide their employees with the opportunities for professional growth that are essential to the organization's success. This may include live online classes or engaging in other adaptable modes of education.
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={5} md={5} className="serving-box" style={{ padding: 20 }}>
                  <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }}>
                    <img src="../about_pic/teaching.png" alt="Image" width="100%" />
                  </div>
                  <Grid item>
                    <div className="serving-head" style={{ color: '#01025C', fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>Online Teaching</div>
                  </Grid>
                  <Grid item>
                    <div className="serving-text" style={{ color: '#000' }}>
                      Utilizing eLearning may be a terrific method to reach more students and professionals all over the world regardless of what you want to teach. You will be able to increase the amount of money you make from the courses you already offer if you utilize eLearning.
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        mt={15}
        className="mission"
      >
        <Grid
          container
          spacing={7}
          justify="center"
        >
          <Grid
            xl={6}
            lg={6}
            md={6}
            xs={12}
            item
          >
            <Flip left>
              <Card>
                <CardMedia
                  style={{ height: 450 }}
                  image="/static/images/classmates-working-together.png"
                />
              </Card>
            </Flip>
          </Grid>

          <Grid
            xl={6}
            lg={6}
            md={6}
            xs={12}
            item
          >
            <Fade right>
              <Grid item xs={12} container direction="column" spacing={2} justify="center">
                <Grid item xs>
                  <div className="mission-head" style={{ color: '#01025C', fontWeight: 'bold' }}>Mission</div>
                </Grid>
                <Grid item className={classes.contentContainer}>
                  <div className="mission-text" style={{ color: '#000', fontSize: 28 }}>
                    We believe the best learning occurs when it is most enjoyed and our mission is to create easy to use platforms that facilitate the process of embedding lots of fun into learning activities.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default About;
