/* eslint-disable */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  Select
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { quizUsageInfo } from 'src/utils/Api';
import { result } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const Sales = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [dataList, setdataList] = React.useState(); //Created Graph
  const [titleList, settitleList] = React.useState([]); //Created Graph

  const [playeddataList, setplayeddataList] = React.useState(); //Played Graph
  const [playedtitleList, setplayedtitleList] = React.useState([]); //Played Graph

  const [filter, setFilter] = React.useState(1);
  const [show, setShow] = React.useState(false); //DatePicker Show Or False
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [focusedInput, setFocusedInput] = React.useState(null);
  const handleDatesChange = ({ startDate, endDate }) => {
      setStartDate(startDate);
     setEndDate(endDate);

  checkDate(startDate, endDate)

  };
  const checkDate = async (stDate, enDate) => {
    if(stDate && enDate){
      // var dayDiffernce = enDate - stDate
      // dayDiffernce = dayDiffernce / (1000 * 60 * 60 * 24)
      // if(dayDiffernce <= 31){
        // console.log('chota', dayDiffernce)

    await quizUsageInfo({
      from_date: stDate?dateConvert(stDate):'',
      to_date: enDate?dateConvert(enDate):'',
      type: filter,
    }).then((res) => {
      if (res.status === 200) {
        console.log('DateFilter', res)
        setdataList(res.data.data.quiz_played_data.data_list)//create
        settitleList(res.data.data.quiz_played_data.title_list) //create
        setplayeddataList(res.data.data.quiz_created_data.data_list) //played
        setplayedtitleList(res.data.data.quiz_created_data.title_list) //played
      }
    }).catch((err) => {
      console.log('Sales Created Error', err)
    })
  // }else{
  //   alert('Please Select Minimum 31 Days Atleast');

  // }
  }

  }
  function dateConvert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: dataList,
        label: 'This year'
      }
    ],
    labels: titleList

  };

  const playeddata = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: playeddataList,
        label: 'This year'
      }
    ],
    labels: playedtitleList

  };
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const latestUsage = (event) => {
    setFilter(event.target.value)
  };
  const showDatePicker = () => {
    if (filter == 7) {
      setShow(true)
    } else {
      setShow(false)
    }
  };
  const setBarGraph = async (filter) => {
    await quizUsageInfo({
      from_date: '',
      to_date: '',
      type: filter !=7 ? filter : '',
    }).then((res) => {
      if (res.status === 200) {
        console.log('SalesrCreatedes', res)
        setdataList(res.data.data.quiz_played_data.data_list)//create
        settitleList(res.data.data.quiz_played_data.title_list) //create
        setplayeddataList(res.data.data.quiz_created_data.data_list) //played
        setplayedtitleList(res.data.data.quiz_created_data.title_list) //played
      }
    }).catch((err) => {
      console.log('Sales Created Error', err)
    })
  }
  React.useEffect(() => {
    showDatePicker()
    setBarGraph(filter)

  }, [show, filter])

  return (

    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
    >

      <div className='select-dropdown' style={{ display: 'flex', width: '100%', marginBottom: 30 }}>
      <Select
          style={{ float: 'left', width: '200px', marginRight: 20 }}
          native
          value={filter}
          onChange={latestUsage}

        >
          <option selected value={1}>Last 7 Days</option>
          <option value={2}>Current Month</option>
          <option value={3}>Last 1 Month</option>
          <option value={4}>Last 3 Month</option>
          <option value={5}>Last 6 Month</option>
          <option value={6}>Last 1 Year</option>
          <option value={7}>Date Range </option>


        </Select>

        {show ?
          <DateRangePicker
            startDate={startDate}
            startDateId="tata-start-date"
            endDate={endDate}
            // minDate={new Date()}
            maxDate={new Date()}
            isOutsideRange={() => false}
            endDateId="tata-end-date"
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            style={{ width: '34%' }}
          />
          : null
        }
      </div>
        
      <div className="date-picker" style={{ width: '50%', paddingRight: 30, borderRight: '1px solid rgba(124, 124, 124, 1)' }}>
       
        <CardHeader

          title="Quiz Created List"
          
        />

        <Divider />
        <CardContent>
          <Box
            height={400}
            position="relative"
          >
            <Bar
              data={data}
              options={options}
            />
          </Box>

        </CardContent>
      </div>

      <div className="date-picker" style={{ width: '45%' }}>
        <CardHeader

        title="Quiz Played List"
        />

        <Divider />
        <CardContent>
        <Box
          className="mt-2"
          height={400}
          position="relative"
        >
          <Bar
            data={playeddata}
            options={options}
          />
        </Box>

        </CardContent>
      </div>
      
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
