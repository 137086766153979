/* eslint-disable */
import React from 'react';
import {
  Grid,
  makeStyles,
  Box,
  Card,
  CardMedia,
  Button
} from '@material-ui/core';
import {
  Users as UsersIcon,
} from 'react-feather';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw'
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 40
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Gamification = () => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      mt={15}
      className={classes.boxContainer}
      id="joinsection"
    >
      <Grid
        container
        spacing={7}
        style={{ justifyContent: 'center' }}
      >
        <Grid
          xl={10}
          lg={12}
          md={12}
          xs={12}
          item
        >
          <Grid xs={12} md={10} lg={10} container direction="column" spacing={2} justifyContent="center" alignItems="center" className="exp-heading">
            <Grid item xs className={classes.titleContainer}>
              <div className="learning-head">

                <h3 className="second-box-heading" >Gamify your learning experience and increase engagement with the #1 eLearning platform for teachers and students</h3>
                <p className="second-box-sub-heading">Our virtual classroom and quiz gamification software is the perfect way to keep students entertained while they learn.</p>

              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} spacing={2} style={{ display: "flex", flexWrap: 'wrap', marginTop: 30 }} className="experience">
            <Grid item xs={12} sm={6} md={6} lg={4} className="gamified-quizzes gami-one" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/game-quiz.png" alt="logo" width="100%" fluid />
              </div>
              <Grid item>
                <div className="gamified-quizzes">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Easily create and share gamified quizzes </strong> </p>
                  Gamified quizzes are a great way to engage your audience. Easily create and share gamified quizzes with our easy-to-use platform. With just a few clicks, you can create engaging and interactive quizzes that will resonate with your audience.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="multiple-choice gami-one" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/true-false2.png" alt="logo" width="100%" />
              </div>

              <Grid item>
                <div className="multiple-choice">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Multiple choice and True/False question type </strong></p>
                  with Brainaly, you can quickly and easily create multiple choice and true false questions. This powerful tool makes it simple to engage your audience and get the results you need.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="remote-instruction gami-one" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/remote-ins.png" alt="logo" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Remote and in-person instruction </strong></p>
                  Brainaly allows you to offer remote and in-person instruction that is creative, interesting, and engaging. Provide your students with the opportunity to learn in a way that is best for them. Whether you are looking for an online or offline learning experience, Brainaly has a solution for you.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="gamified-quizzes-img gami-one" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/devices.png" alt="Image" width="100%" fluid />
              </div>
              <Grid item>
                <div className="gamified-quizzes">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Users can join from any device </strong></p>
                  Users can join Brainaly from any device. They can log in from their desktop, laptop, tablet, or smartphone. Our software is accessible from anywhere in the world.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="multiple-choice gami-one" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/create-class.png" alt="logo" width="100%" />
              </div>

              <Grid item>
                <div className="multiple-choice">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Create classes </strong></p>
                  Brainaly offers the ability to create virtual classes. This innovative feature allows you to use our powerful learning tools to create a customized learning experience for your students. With Brainaly, you can make learning fun and engaging for your students, while also providing them with the structure and support they need to succeed.
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="remote-instruction gami-one" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/report-ana.png" alt="logo" width="100%" />
              </div>
              <Grid item>
                <div className="remote-instruction">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Detailed reports and analytics</strong></p>
                  The brainaly student learning analytics tool provides detailed reports and analytics to help you understand student learning.
                    You can use brainaly to track progress, identify patterns, and pinpoint areas of need. With brainaly, you'll get a clear picture of student learning and be able to make informed decisions to improve outcomes.
                </div>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6} lg={4} className="gamified-quizzes" style={{ padding: 20, marginBottom: 30 }}>
              <div style={{ color: '#5E2B66', fontSize: 36, maxWidth: '40%', margin: 'auto' }} className="exp-image">
                <img src="home/admin-dash.png" alt="logo" width="100%" fluid />
              </div>
              <Grid item>
                <div className="gamified-quizzes">
                  <p className="mt-3 mt-md-0 mb-2"><strong>Admin Dashboard </strong></p>
                   The Brainaly platform offers schools a way to quickly and easily take their learning online.
                </div>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Gamification;
