/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as RouterLink, useNavigate, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Card,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from 'src/components/Page';
import { reportAll } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardText,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
} from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%'
  },
}));

const QuestionsList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [accountType, setAcctType] = React.useState('teacher');
  const [isLoading, setIsLoading] = React.useState(false);
  const handChangeAccType = (value) => {
    console.log(value.target.value);
    setAcctType(value.target.value);
  };
  const handleClickShowPassword = () => {
    console.log('asdasdasdsd');
  };
  const handleMouseDownPassword = () => {
    console.log('asdasdasd');
  };
  const columns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'name', text: 'Name' },
    { dataField: 'questions', text: 'Email' }
  ];

  return (
    <Page
      className={classes.root}
      title="Question Feedback"
    >
      {/* <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        All Records
      </Box> */}
      <div width="100%" className="sc-kstrdz sc-hBEYos fZENZC dWjUC" data-functional-selector="report-list__page" height="fit-content">
          <div width="100%" className="sc-kstrdz dOdMcc">
              <div className="sc-kstrdz sc-hBEYos sc-jDEoIF gFMnBB dWjUC kSrRDV">
                <div aria-live="polite" data-functional-selector="report-feedback-empty-overlay" className="sc-eMFZPI borMIB">
                    <h4 className="sc-jLNGcK kZHRQr">You didn't receive feedback for this Brainaly</h4>
                    <p className="sc-gEsCcL bWesNN">
                        To receive feedback for your next kahoot, select 'Get feedback' when it finishes. Players will be prompted to rate the game and tell you how the kahoot made them feel. They'll also be asked if they learned something and if they
                        would recommend this kahoot.
                    </p>
                </div>
              </div>
          </div>
      </div>
    </Page>
  );
};

export default QuestionsList;
