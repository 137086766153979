/* eslint-disable */
import React from 'react';
import { Link as RouterLink, useNavigate, usenavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText
} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Flip from 'react-reveal/Flip';
import {
  setSocket,
  emitEvent,
  emitVolatileEvent,
  onMessageReceived,
  offEvent,
  disconnect,
} from 'src/utils/socket';
import { signUp, playedGame, testDownTime } from 'src/utils/Api';
import cogoToast from 'cogo-toast';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import Page from 'src/components/Page';
import Loading from 'src/components/Loading';
import global from 'src/utils/global';
import CountDown from 'src/components/CountDown';
import Connecting from 'src/components/Connecting';
import EndGameSection from './endgame';
import Players from './Players';
import Unchecked from './answers/unchecked';
import Checked from './answers/checked';
import UncheckedCom from './answersCom/uncheckedCom';
import CheckedCom from './answersCom/checkedCom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    // overflow: 'hidden',
    background: '#4e54c8',
  },
  header: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 50,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 35,
    color: 'white'
  },
  modal: {
    width: '100vw',
    padding: 20
  },
  cardMedia: {
    height: 600
  },
  boxContainer: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: 100
  },
  titleInput: {
    marginTop: 30
  },
  titleContainer: {
    marginTop: 40
  },
  contentContainer: {
    marginTop: 40
  },
  buttonContainer: {
    marginTop: 40
  },
  avatar: {
    width: 150,
    height: 150
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  answerContainer: {
    marginTop: 10,
    width: '90%',
    marginLeft: '5%',
    padding: 10,
    position: 'fixed',
    bottom: 50,
    left: 'calc(2% / 2)'
  },
  answerResultContainer: {
    marginTop: 10,
    width: '90%',
    marginLeft: '5%',
    padding: 10,
    position: 'fixed',
    top: 250,
    left: 'calc(2% / 2)'
  },
  answerSector: {
    padding: 10,
    fontSize: 30,
    color: 'white!important',
  },
  answerResultSector1: {
    padding: 10,
    fontSize: 30,
    color: 'white!important',
    display: 'flex',
    backgroundColor: '#e21b3c',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  answerResultSector2: {
    padding: 10,
    fontSize: 30,
    color: 'white!important',
    display: 'flex',
    backgroundColor: '#1368ce',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  answerResultSector3: {
    padding: 10,
    fontSize: 30,
    color: 'white!important',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#d89e00',
    alignItems: 'center',
    flexDirection: 'column'
  },
  answerResultSector4: {
    padding: 10,
    fontSize: 30,
    color: 'white!important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#26890c',
    flexDirection: 'column'
  },
  subanswerResult: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  answerHeader: {
    marginBottom: 30,
    color: 'white',
    fontSize: 36
  }

}));

const GamePanel = () => {
  // setInterval(function(){

  //   emitEvent('testing_emit')
  // }, 3000);

  const classes = useStyles();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [gamePin, setGamePin] = React.useState('');
  const [playerInfo, setPlayerInfo] = React.useState({});
  const [players, setPlayers] = React.useState([]);
  const [midUserVal, setmidUserVal] = React.useState(0); //use for navigate test to index
  const [gamePinModal, setGamePinModal] = React.useState(false);
  const [gameInfo, setGameInfo] = React.useState({});
  const [nickName, setNickName] = React.useState('');
  const [nickNameModal, setNickNameModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [timeInterval, setTimeInterval] = React.useState(false);
  const [answerResult, setAnswerResult] = React.useState([]);
  const [answerGraphic, setAnswerGraphic] = React.useState(<div className={classes.answerContainer}>{' '}</div>);
  const [downTime, setDownTime] = React.useState(0);
  const [cQuizNum, setCurrentQuizNum] = React.useState(-1);
  const [visibleDownCounter, setVisibleDownCounter] = React.useState(false);
  const [visibleConnecting, setConnecting] = React.useState(false);
  const [uploadAnswerRequest, setUploadAnswerValueRequest] = React.useState(0);
  const [answerResultView, setAnswerResultView] = React.useState(<div className={classes.answerContainer}>{' '}</div>);
  const timeIsUp = () => {
    console.log('time_is up');
    var { currentNum } = playerInfo;
    var quizType = gameInfo?.gameContent ? gameInfo?.gameContent[currentNum]?.quizType : 3;
    if (quizType === 1) {
      const answers = gameInfo?.gameContent[currentNum]?.quizAnswer;
      setAnswerResult([0, 0, 0, 0]);
      setAnswerGraphic(
        <Grid container className={classes.answerContainer}>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[0] === 'undefined' ? null : <UncheckedCom answer={answers[0].answer} order={0} check={answers[0].sel} updateAnswer={() => { }} cAnswer={answerResult} timeCount={downTime} />}</Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[1] === 'undefined' ? null : <UncheckedCom answer={answers[1].answer} order={1} check={answers[1].sel} updateAnswer={() => { }} cAnswer={answerResult} timeCount={downTime} />}</Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[2] === 'undefined' ? null : <UncheckedCom answer={answers[2].answer} order={2} check={answers[2].sel} updateAnswer={() => { }} cAnswer={answerResult} timeCount={downTime} />}</Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[3] === 'undefined' ? null : <UncheckedCom answer={answers[3].answer} order={3} check={answers[3].sel} updateAnswer={() => { }} cAnswer={answerResult} timeCount={downTime} />}</Grid>
        </Grid>
      );
    } else if (quizType === 2) {
      setAnswerResult([0, 0]);
      const answers = gameInfo?.gameContent[currentNum]?.quizAnswer;
      setAnswerGraphic(
        <Grid container className={classes.answerContainer}>
          {typeof answers === 'undefined' ? null : <CheckedCom order={1} check={answers === 0 ? 1 : 0} updateAnswer={() => { }} cAnswer={answerResult} timeCount={downTime} />}
          {/* <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{ typeof answers === 'undefined' ? null : <Checked order={1} check={answers === 0 ? 1 : 0} updateAnswer={setAnswerResultcall} cAnswer={answerResult} /> }</Grid> */}
        </Grid>
      );
    }
  };

  React.useEffect(() => {
    return function cleanUP() {
      //localStorage.removeItem('setCheckBox');
    };
  }, []);
  

  React.useEffect(() => {

    window.addEventListener('load', (event) => {
      console.log('page is fully loaded');
      localStorage.setItem('refresh', 1);
      
    });

    if (performance.navigation.type === 1) {
      console.log('This page is reloaded');
      localStorage.setItem('refresh', 1);
      console.log('go home page');
    } 
    else {
      console.log('This page is not reloaded');
    }
  }, []);
  function updateAnswerView(uInfo) {
    console.log('updateAnswerView', uInfo)
    console.log('updateAnswerView ', gameInfo)
    var size = Object.keys(gameInfo).length;
    console.log('size_update ', size)
    var gameCorrectAnswers = '';
    if (size > 0) {
      gameCorrectAnswers = gameInfo ? gameInfo?.gameContent[uInfo.currentNum] : '';

    }

    if (gameCorrectAnswers?.length == 0) {
      // navigate('/');
    }
    console.log(gameCorrectAnswers, uInfo.currentNum, uInfo.userAnswers, 'display answer result');
    if (gameCorrectAnswers.quizType === 1) {
      var countAnswer = [0, 0, 0, 0];
    } else {
      var countAnswer = [0, 0];
    }
    players.map((p) => {
      console.log(p);
      var pAnswer = p.userAnswers[uInfo.currentNum];
      pAnswer.map((ans, index) => {
        countAnswer[index] += ans;
      });
    });
    var userAnswer = uInfo.userAnswers[uInfo.currentNum];
    if (playerInfo.userId == gameInfo.ownerId) {
      if (gameCorrectAnswers.quizType === 1) {
        setAnswerResultView(
          <Grid container className={classes.answerResultContainer}>
            <Grid item sm={12} className={classes.answerHeader}>
              <h1>{gameInfo?.gameContent ? gameInfo?.gameContent[playerInfo.currentNum].title : null}</h1>
            </Grid>
            <div className={['host-boxes']}>
              <Grid item sm={12} className={classes.subanswerResult}>
                <Grid item md={3} sm={3} xs={3} className={classes.answerResultSector1}>
                  <div className="answerResultContain">
                    <div className="answer-item">
                      {gameCorrectAnswers.quizAnswer[0].sel ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                      {gameCorrectAnswers.quizAnswer[0].answer}
                    </div>
                    <div>
                      {countAnswer[0]}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} sm={3} xs={3} className={classes.answerResultSector2}>
                  <div className="answerResultContain">
                    <div className="answer-item">
                      {gameCorrectAnswers.quizAnswer[1].sel ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                      {gameCorrectAnswers.quizAnswer[1].answer}
                    </div>
                    <div>
                      {countAnswer[1]}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item sm={12} className={classes.subanswerResult}>
                <Grid item md={3} sm={3} xs={3} className={classes.answerResultSector3}>
                  <div className="answerResultContain">
                    <div className="answer-item">
                      {gameCorrectAnswers.quizAnswer[2].sel ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                      {gameCorrectAnswers.quizAnswer[2].answer}
                    </div>
                    <div>
                      {countAnswer[2]}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} sm={3} xs={3} className={classes.answerResultSector4}>
                  <div className="answerResultContain">
                    <div className="answer-item">
                      {gameCorrectAnswers.quizAnswer[3].sel ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                      {gameCorrectAnswers.quizAnswer[3].answer}
                    </div>
                    <div>
                      {countAnswer[3]}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        );
      } else if (gameCorrectAnswers.quizType === 2) {
        if (gameCorrectAnswers.quizAnswer == 1) {
          var correctAnswer = [0, 1];
        } else {
          var correctAnswer = [1, 0];
        }
        setAnswerResultView(
          <Grid container className={classes.answerResultContainer}>
            <Grid item sm={12} className={classes.answerHeader}>
              <h1>{gameInfo?.gameContent ? gameInfo?.gameContent[playerInfo.currentNum].title : null}</h1>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.answerResultSector1}>
              <div className="answerResultContain">
                <div className="answer-item">
                  {correctAnswer[1] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                </div>
                <div>
                  {countAnswer[0]}
                </div>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.answerResultSector2}>
              <div className="answerResultContain">
                <div className="answer-item">
                  {correctAnswer[0] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                </div>
                <div>
                  {countAnswer[1]}
                </div>
              </div>
            </Grid>
          </Grid>
        );
      }
    } else {
      if (gameCorrectAnswers.quizType === 1) {
        // console.clear();
        console.log(userAnswer, 'user answer and correct answer');
        console.log(gameCorrectAnswers.quizAnswer, 'quizAnswer');
        console.log(userAnswer, 'user answer');
        console.log('countAnswer', countAnswer);
        const first = countAnswer[0] ? 'yes' : 'no';
        const second = countAnswer[1] ? 'yes' : 'no';
        const third = countAnswer[2] ? 'yes' : 'no';
        const fourth = countAnswer[3] ? 'yes' : 'no';
        // console.log('first', gameCorrectAnswers.quizAnswer[0].sel, countAnswer[0]);
        // console.log('second', gameCorrectAnswers.quizAnswer[1].sel, countAnswer[1]);
        // console.log('third', gameCorrectAnswers.quizAnswer[2].sel, countAnswer[2]);
        // console.log('fourth', gameCorrectAnswers.quizAnswer[3].sel, countAnswer[3]);
        // console.log('dicvde');
        // console.log('first', first);
        // console.log('second', second);
        // console.log('third', third);
        // console.log('fourth', fourth);

        setAnswerResultView(
          <Grid container className={[classes.answerResultContainer, 'outer-div']}>
            <Grid item sm={12} className={classes.answerHeader}>
              <h1>{gameInfo?.gameContent ? gameInfo?.gameContent[playerInfo.currentNum].title : null}</h1>
            </Grid>
            <Grid item sm={12} className={classes.subanswerResult}>
              <Grid item md={3} sm={3} xs={3} className={[classes.answerResultSector1, 'first-row-first']}>
                <div className="answerResultContain">
                  <div className="answer-item vijay-checking-1">
                    {userAnswer[0] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                    {gameCorrectAnswers.quizAnswer[0].answer}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} sm={3} xs={3} className={[classes.answerResultSector2, 'first-row-second']}>
                <div className="answerResultContain">
                  <div className="answer-item vijay-checking-2">
                    {userAnswer[1] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                    {gameCorrectAnswers.quizAnswer[1].answer}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={12} className={classes.subanswerResult}>
              <Grid item md={3} sm={3} xs={3} className={[classes.answerResultSector3, 'second-row-first']}>
                <div className="answerResultContain">
                  <div className="answer-item vijay-checking-3">
                    {userAnswer[2] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                    {gameCorrectAnswers.quizAnswer[2].answer}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} sm={3} xs={3} className={[classes.answerResultSector4, 'second-row-second']}>
                <div className="answerResultContain">
                  <div className="answer-item vijay-checking-4">
                    {userAnswer[3] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                    {gameCorrectAnswers.quizAnswer[3].answer}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      } else if (gameCorrectAnswers.quizType === 2) {
        if (gameCorrectAnswers.quizAnswer == 1) {
          var correctAnswer = [1, 0];
        } else {
          var correctAnswer = [0, 1];
        }
        console.log(userAnswer, correctAnswer, 'user answer and correct answer');
        setAnswerResultView(
          <Grid container className={classes.answerResultContainer}>
            <Grid item sm={12} className={classes.answerHeader}>
              <h1>{gameInfo?.gameContent ? gameInfo?.gameContent[playerInfo.currentNum].title : null}</h1>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.answerResultSector1}>
              <div className="answerResultContain">
                <div className="answer-item">
                  {userAnswer[0] == correctAnswer[0] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                </div>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.answerResultSector2}>
              <div className="answerResultContain">
                <div className="answer-item">
                  {userAnswer[1] == correctAnswer[1] ? <CheckCircleIcon fontSize="large" className={classes.icons} /> : <ClearIcon fontSize="large" className={classes.icons} />}
                </div>
              </div>
            </Grid>
          </Grid>
        );
      }
    }
  }
  function togglePinModal() {
    setGamePinModal(!gamePinModal);
  }
  // function countDown() {
  //   setDownTime((downTime) => downTime - 1);
  // }
  React.useEffect(() => {
    console.log(downTime, 'downtime');
    localStorage.setItem('downTime', downTime);
    if (downTime <= 0) {
      setDownTime(0)
      timeIsUp();
      // if (gameInfo?.gameContent) {
      // }
      // for (var i = 0; i < 1000; i++) {
      //   window.clearInterval(i);
      // }
    }
  }, [downTime]);
  function toMidGame() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
    emitEvent('toMidState', {
      playerInfo,
      answerResult
    });
  }
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function setAnswerResultcall(upData, timer) {

    console.log(upData, 'on index new', timer);
    // emitEvent('updateAnswer', {
    //   playerInfo,
    //   upData,
    //   timer
    // }); // This is comment bsc after refrssh is not fire event then we use below axios for hit

    console.log('event Fire++++')
    if(timer){
      testDownTime({
        playerInfo:playerInfo,
        upData:upData,
        timer:timer
      })
    }
  
    
    setAnswerResult([...upData], timer);
    console.log(gameInfo, 'setAnswerResultcall func++++ gameinfo update');
    var { currentNum } = playerInfo;
    // if (playerInfo?.currentNum >= 0) {
    //   var currentNum = playerInfo?.currentNum;
    //   if (currentNum < 0) currentNum = 0;
    // } else {
    //   var currentNum = 0;
    // }
    // if (currentNum >= gameInfo.gameQuizNum) {
    //   currentNum = gameInfo.gameQuizNum - 1;
    // }
    setCurrentQuizNum(currentNum);
    var quizType = gameInfo?.gameContent ? gameInfo?.gameContent[currentNum]?.quizType : 3;
    if (quizType === 1) {
      const answers = gameInfo?.gameContent[currentNum]?.quizAnswer;
      setAnswerResult([0, 0, 0, 0]);
      setTimeout(() => {
        setAnswerGraphic(
          <Grid container className={classes.answerContainer}>
            <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[0] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[0].answer} order={0} check={answers[0].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
            <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[1] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[1].answer} order={1} check={answers[1].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
            <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[2] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[2].answer} order={2} check={answers[2].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
            <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[3] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[3].answer} order={3} check={answers[3].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
          </Grid>
        );
      }, 300);
    } else if (quizType === 2) {
      setAnswerResult([0, 0]);
      const answers = gameInfo?.gameContent[currentNum]?.quizAnswer;
      setTimeout(() => {
        setAnswerGraphic(
          <Grid container className={classes.answerContainer}>
            {typeof answers === 'undefined' ? null : <Checked owner={playerInfo.userId == gameInfo.ownerId} order={1} check={answers === 0 ? 1 : 0} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={downTime} />}
            {/* <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{ typeof answers === 'undefined' ? null : <Checked order={1} check={answers === 0 ? 1 : 0} updateAnswer={setAnswerResultcall} cAnswer={answerResult} /> }</Grid> */}
          </Grid>
        );
      }, 300);
    }
  }


  React.useEffect( () => {

    console.log(gameInfo, 'gameinfo update useEffect+++++');
    console.log(playerInfo, 'playerInfo++++++ useEffect+++++')
    var { currentNum } = playerInfo;
    console.log(currentNum, 'currentNum++++++ useEffect+++++')

    // if (playerInfo?.currentNum >= 0) {
    //   var currentNum = playerInfo?.currentNum;
    //   if (currentNum < 0) currentNum = 0;
    // } else {
    //   var currentNum = 0;
    // }
    // if (currentNum >= gameInfo.gameQuizNum) {
    //   currentNum = gameInfo.gameQuizNum - 1;
    // }

    console.log('useEffect+++++ mygmeinfo+++', gameInfo)

    setCurrentQuizNum(currentNum);
    var quizType = gameInfo?.gameContent ? gameInfo?.gameContent[currentNum]?.quizType : 3;
    console.log('checkquizType', quizType)

    if (quizType === 1) {
      console.log('tryy1', quizType)
      const answers = gameInfo?.gameContent[currentNum]?.quizAnswer;
      setAnswerResult([0, 0, 0, 0]);
      setAnswerGraphic(
        <Grid container className={classes.answerContainer}>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[0] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[0].answer} order={0} check={answers[0].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[1] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[1].answer} order={1} check={answers[1].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[2] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[2].answer} order={2} check={answers[2].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{typeof answers[3] === 'undefined' ? null : <Unchecked owner={playerInfo.userId == gameInfo.ownerId} answer={answers[3].answer} order={3} check={answers[3].sel} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={localStorage.getItem('downTime')} />}</Grid>
        </Grid>
      );
    } else if (quizType === 2) {
      console.log('tryy2', quizType)

      setAnswerResult([0, 0]);
      const answers = gameInfo?.gameContent[currentNum]?.quizAnswer;
      setAnswerGraphic(
        <Grid container className={classes.answerContainer}>
          {typeof answers === 'undefined' ? null : <Checked owner={playerInfo.userId == gameInfo.ownerId} order={1} check={answers === 0 ? 1 : 0} updateAnswer={setAnswerResultcall} cAnswer={answerResult} timeCount={downTime} />}
          {/* <Grid item md={6} sm={6} xs={12} className={classes.answerSector}>{ typeof answers === 'undefined' ? null : <Checked order={1} check={answers === 0 ? 1 : 0} updateAnswer={setAnswerResultcall} cAnswer={answerResult} /> }</Grid> */}
        </Grid>
      );
    }
    if (gameInfo.gameStatus == 'playing' && !timeInterval) {

      console.log('check interval');
      for (var i = 0; i < 1000; i++) {
        window.clearInterval(i);
      }


      // if (playerInfo?.currentNum >= 0) {
      //   var currentNum = playerInfo?.currentNum;
      //   if (currentNum < 0) currentNum = 0;
      // } else {
      //   var currentNum = 0;
      // }

      console.log('playing downTime', downTime);
      var refresh = localStorage.getItem('refresh');
      var againrefreshval = localStorage.getItem('againrefreshval')      
     //setVisibleDownCounter(true) //default loader starting time for user 3-2-1
     // setDownTime(gameInfo.gameContent[currentNum].quizTime); //default starting time for all

       //mid  Condition by Host
      if (playerInfo.userId == gameInfo.ownerId && downTime != 0) {
        console.log('when user refresh in the mid its host console')
        setVisibleDownCounter(false);
        //console.log('host!====0')
        (async function () {
          for (var i = downTime; i >= 0; --i) { // downTime get in the mid of refresh game by Host
            setDownTime(i) //update downtime
            await sleep(1000)
            console.log('hosttimer-mid', i)
            emitVolatileEvent('setservertime', i) //event fire in mid 
            localStorage.setItem('againrefreshval', i)

          }
        })();
      }
      //Refresh Condition by Host Page

      else if (playerInfo.userId == gameInfo.ownerId && refresh == 1) { // user page between game
        console.log('Santos+++++++Host DownTime', downTime)
        console.log('Santos+++++++againrefreshval', againrefreshval)
       
        setConnecting(true)
        setTimeout(() => {
            (async function () {
              for (var i = againrefreshval; i >= 0; --i) {
                setDownTime(i) //update time value
                await sleep(1000)
                emitVolatileEvent('setservertime', i) //event fire
                console.log('host_refresh_time', i)
                localStorage.setItem('againrefreshval', i)
              }
            })();
        }, 50)

      }
      //Starting 1st Time Condition by Host
      else if (playerInfo.userId == gameInfo.ownerId) { //starting game time
        console.log('starting time host', downTime)

        //setDownTime(gameInfo.gameContent[currentNum].quizTime) //set intial value of downTime

        console.log('host+++againrefreshval', againrefreshval)

        setTimeout(() => {
          (async function () {
            for (var i = againrefreshval?againrefreshval : gameInfo.gameContent[currentNum].quizTime; i >= 0; --i) {
              setDownTime(i) //update time value
              await sleep(1000)
              console.log('hosttimer-start', i)
              emitVolatileEvent('setservertime', i) //event fire
              localStorage.setItem('againrefreshval', i)
            }
          })();
        }, 4500)

      } 
       //End Starting 1st Time Condition by Host

      //Refresh in the mid of game condition
      else if (downTime != 0) { // user page between game
        console.log('refresh user timerr')
        if (playerInfo.userId == gameInfo.ownerId) { //host page 
          console.log('el1111 ifffff Host')
          setVisibleDownCounter(false); //For Host

         } 
         
          //  else { //user page
          console.log('Default Particular ReferWithout USer+++++')
         // setConnecting(true)
        //   setVisibleDownCounter(false);
       //   setConnecting(false)

              if (refresh == 1) { // between game user refresh

                console.log('Particular User Refer+++++')
                setVisibleDownCounter(false); // Particular User Refresh
                setConnecting(true) // Particular Users Refresh
                setTimeout(()=>{   
                     localStorage.removeItem('refresh');
                 },1500)
              }
        //}
        
      } 
       //End Refresh in the mid of game condition
      else if (downTime == 0) {
       // setVisibleDownCounter(false)
        console.log('all downtime+++ 0000')

      }
      setTimeout(() => {
        var tInterval = setInterval(() => {
          // countDown();
          // setVisibleDownCounter(false);

        }, 1000);
        setVisibleDownCounter(false);
      }, 4500);

      //Set Connecting Loader Timeout
      setTimeout(() => { setConnecting(false) }, 2000)

    }
    if (gameInfo.gameStatus == 'mid') {
      localStorage.removeItem('refresh');
      localStorage.removeItem('againrefreshval');

    }
    if (gameInfo.gameStatus == 'end') {
      localStorage.removeItem('brainaly_game');
      localStorage.removeItem('refresh');
      localStorage.removeItem('againrefreshval');
      localStorage.removeItem('setCheckBox');


    }
  }, [playerInfo]);

  React.useEffect(() => {
    if(midUserVal == 1){
      console.log('re-render val')
  
      navigate('/test')
  }
    console.log(players, 'players update santos');
    if (playerInfo.gameId === players[0]?.gameId) {
      players.map((d) => {
        if (d.userId == playerInfo.userId) {
          console.log('userInfo list', d);
          var size = Object.keys(d).length;
          setPlayerInfo(d);
          if (d.userStatus == 'mid') {
            setTimeout(() => {
              updateAnswerView(d);
            }, 1000);
          }
          localStorage.setItem('brainaly_game', JSON.stringify(d));
        }
      });
    }
  }, [players, midUserVal]);
  function enterNickName() {
    emitEvent('checkPinCode', gamePin);
    return true;
  }
  function toggleNickModal() {
    setNickNameModal(!nickNameModal);
  }
  function sum(arr) {
    var i = 0;

    for (var index = 0; index < arr.length; index++) {
      i += arr[index];
    }

    return i;
  }

  function joinGame() {
    emitEvent('checkNameExist', nickName);

    if (!nickName) {
      cogoToast.warn('Please Insert Nick Name', { position: 'top-right' });
      return false;
    }
    setGamePinModal(false);
    var date = new Date();
    const gameUser = {
      userId: Math.random().toString(16).slice(-4) + date.getHours() + date.getMinutes() + date.getSeconds(),
      userNickName: nickName,
      currentNum: 0,
      userScore: 0,
      timeSec: [],
      timeTaken: [],
      userStatus: 'ready',
      gameId: gamePin,
      userAnswers: []
    };
    console.log(gameUser, 'userinfo before use join game');
    localStorage.setItem('brainaly_game', JSON.stringify(gameUser));
    setPlayerInfo(gameUser);
    setNickNameModal(false);
    emitEvent('joinUser', gameUser);
    emitEvent('joingame', gameUser);

    localStorage.removeItem('refresh');
    localStorage.removeItem('againrefreshval');
    localStorage.removeItem('setCheckBox');

    return true;
  }
  function okPinCode() {
    console.log('okPincode');
    setTimeout(() => {
      setLoading(false);
      togglePinModal();
      toggleNickModal();
    }, 1000);
  }
  function okNickName() {
    console.log('okNickName');
    setTimeout(() => {
      setLoading(false);
      // togglePinModal();
      // toggleNickModal();
    }, 1000);
  }
  function noPinCode() {
    console.log('noPincode');
    setTimeout(() => {
      setGamePinModal(true);
      setNickNameModal(false);
      cogoToast.warn('PinCode is invalid', { position: 'top-right' });
      setLoading(false);
    }, 1000);
  }
  function noNickName() {
    console.log('noNickName');
    setTimeout(() => {
      setNickNameModal(true);
      cogoToast.warn('Sorry, That nickname is already taken.', { position: 'top-right' });
      setLoading(false);
    }, 1000);
  }
  function checkPinCode() {
    if (gamePin.length !== 4) {
      cogoToast.warn('Please insert correct Game Pin', { position: 'top-right' });
      return false;
    } if (!gamePin) {
      cogoToast.warn('Please insert Game Pin', { position: 'top-right' });
      return false;
    }
    setLoading(true);
    emitEvent('checkPinCode', gamePin);
    setTimeout(() => {
      if (loading) {
        setLoading(false);
        cogoToast.warn('Network Error! Please try again', { position: 'top-right' });
      }
    }, 3000);
    return true;
  }
  function animationBackground() {
    // Some random colors
    const colors = ['#3CC157', '#2AA7FF', '#1B1B1B', '#FCBC0F', '#F85F36'];

    const numBalls = 50;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      const ball = document.createElement('div');
      ball.classList.add('ball');
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;

      balls.push(ball);
      document.body.append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      const to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      const anim = el.animate(
        [
          { transform: 'translate(0, 0)' },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 2000, // random duration
          direction: 'alternate',
          fill: 'both',
          iterations: Infinity,
          easing: 'ease-in-out'
        }
      );
    });
  }

  function updatePlayers(data) {

    console.log('userInfo updated', data);
    // if (data.length <= 0) {
    //   togglePinModal();
    // }
    const currentUser = JSON.parse(localStorage.getItem('brainaly_game'));
    if (data && currentUser) {
      if (currentUser.gameId === data[0]?.gameId) {
        setPlayers(data);
        data.map((d) => {
          if (d.userId == playerInfo.userId) {
            console.log('userInfo');
            setPlayerInfo(d);
            localStorage.setItem('brainaly_game', JSON.stringify(d));
          }
        });
      }
    }
  }
  function getserverTime(data) {

    // console.log('getalltimer', data.countdown)
    setDownTime(data.countdown)
    if(data.countdown <= 0){
      console.log('servertime is up')
      setDownTime(0)
    }

    localStorage.setItem('downTime', data.countdown);

  } 
  function midUserJoin(val){
    console.log('listen emit midUSer')
    setmidUserVal(val)
  }
  function loaderstartQuiz(data){

    console.log('loader 1st start quiz')
    setVisibleDownCounter(true)

  }
  function loaderNext(data){
    console.log(data, 'next loader quiz')
    setVisibleDownCounter(true)
    if(data.data < data.totalQuest){
      console.log('next loader false')
      setVisibleDownCounter(false)

    }else{
      console.log('next loader true')

      setVisibleDownCounter(true)

    }


  }
  function updatedGameInfo(data) {

    console.log('updateGameInfo func++', data);
    if(data.gameStatus == 'end'){
      console.log('op')
      setVisibleDownCounter(false)
    }
    const currentUser = JSON.parse(localStorage.getItem('brainaly_game'));
    if (currentUser) {
      if (currentUser.gameId === data.gameId) {
        setGameInfo(data);
      }
    } else {
      //navigate('/');
    }
    // navigate('/');
  }

  function StartGame() {
    setVisibleDownCounter(true)

    console.log('start game');
    playedGame({
      gameId: gameInfo.sourceId
    });
    localStorage.removeItem('refresh');
    localStorage.removeItem('againrefreshval');
    localStorage.removeItem('setCheckBox');
    console.log('start value', gameInfo.sourceId, playerInfo);
    emitEvent('startQuiz', gameInfo, playerInfo);
  }
  function reJoinGame() {
    console.log('reeejoinnnn func');
    const cUser = JSON.parse(localStorage.getItem('brainaly_game'));
    console.log('rejoin', cUser);
    emitEvent('Rjoingame', cUser);

  }
  // React.useEffect(() => {
  //   if (performance.navigation.type === 1) {
  //     console.log('This page is reloaded');
  //     //localStorage.removeItem('brainaly_game');
  //     console.log(downTime, 'downTime')
  //     // navigate('/');
  //     console.log('go home page');
  //     const cUser = JSON.parse(localStorage.getItem('brainaly_game'));
  //     console.log('rejoinreffff', cUser);
  //     emitEvent('Rjoingame', cUser);
  //   } else {
  //     console.log('This page is not reloaded');
  //   }
  // }, []);
  // function uploadAnswer() {
  //   setUploadAnswerValueRequest(Math.random().toString(16).slice(-4));
  // }
  // React.useEffect(() => {
  //   console.log(answerResult.length);
  //   if (answerResult.length) {
  //     console.log('upload answer by server request', answerResult);
  //     emitEvent('updateAnswer', {
  //       playerInfo,
  //       answerResult
  //     });
  //   }
  // }, [setUploadAnswerValueRequest]);
  function endGame() {
    navigate('/');
    emitEvent('endGame', gameInfo.gameId);
  }
  function completeGame() {
    localStorage.removeItem('brainaly_game');
    localStorage.removeItem('refresh');
    localStorage.removeItem('againrefreshval');
    localStorage.removeItem('setCheckBox');

    setGamePinModal(true);
    setGameInfo({});
    setPlayers([]);
    console.log('endGame');
  }
  React.useEffect(() => {

    setSocket();
    // animationBackground();
    offEvent('updatedGameInfo');
    offEvent('newUserJoined');
    offEvent('sendtimer');
    offEvent('nexteventLoader');
    offEvent('startQuizEvent');
    offEvent('midUserJoin');

    offEvent('rejoinGame');
    // offEvent('sendSerAnswer');
    offEvent('okPinCode');
    offEvent('okNickName');
    offEvent('noPinCode');
    offEvent('noNickName');
    offEvent('endedGame');
    onMessageReceived('rejoinGame', reJoinGame);
    onMessageReceived('midUserJoin', midUserJoin);
    onMessageReceived('newUserJoined', updatePlayers);
    onMessageReceived('sendtimer', getserverTime); 
    onMessageReceived('nexteventLoader', loaderNext); 
    onMessageReceived('startQuizEvent', loaderstartQuiz); 
    onMessageReceived('updatedGameInfo', updatedGameInfo);
    onMessageReceived('okPinCode', okPinCode);
    onMessageReceived('okNickName', okNickName);
    onMessageReceived('endedGame', completeGame);
    // onMessageReceived('sendSerAnswer', uploadAnswer);
    onMessageReceived('noPinCode', noPinCode);
    onMessageReceived('noNickName', noNickName);
    const currentUser = JSON.parse(localStorage.getItem('brainaly_game'));
    const currentOwner = JSON.parse(localStorage.getItem('brainaly_user'));
    if (!currentUser) {
      console.log('testttttttttt---------')
      setGamePinModal(true);
    } else {
      console.log('Host refresh Yo')
      setPlayerInfo(currentUser);
      emitEvent('Rjoingame', currentUser);
      emitEvent('joingame', currentUser);
      emitEvent('getGameInfo', currentUser);

      // if(currentOwner?.userId == currentUser.userId){
      //   console.log('khali Host Refresh--------', playerInfo)
      //   setPlayerInfo(currentUser);
      //   emitEvent('joingame', currentUser);
      //   emitEvent('getGameInfo', currentUser);
      // }else{
      //   console.log('khali Refresh Elseee--------', playerInfo)
      //   setPlayerInfo(currentUser);
      //   emitEvent('getGameInfo', currentUser);

      // }
    }
  }, []);

  function NextGame() {
    setVisibleDownCounter(true)
    setmidUserVal(0)
    emitEvent('loaderEvent', {data:1, totalQuest:gameInfo.gameQuizNum});
    localStorage.removeItem('againrefreshval');
    localStorage.removeItem('setCheckBox');
    console.log('next game', gameInfo, playerInfo);

    emitEvent('nextQuiz', gameInfo);
  }
  return (
    <Page
      className={classes.root}
    >
      <div className="area">
        <ul className="circles">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
      </div>
      <Loading visible={loading} />
      {/*  modals start */}
      <Dialog
        open={gamePinModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        key="hostModal"
        className={classes.modal}
      >
        <DialogTitle id="alert-dialog-title">Insert Game Pin</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              placeholder="Pin Code"
              className={classes.titleInput}
              inputProps={{ style: { fontSize: 28, textAlign: 'center' } }}
              onChange={(e) => setGamePin(e.target.value)}
              value={gamePin}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="game-ok" onClick={checkPinCode}>O K</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={nickNameModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        key="nickNameModal"
        className={classes.modal}
      >
        <Flip>
          <DialogTitle id="alert-dialog-title">Insert your NickName</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                placeholder="Nick Name"
                className={classes.titleInput}
                inputProps={{ style: { fontSize: 28, textAlign: 'center' } }}
                onChange={(e) => setNickName(e.target.value)}
                value={nickName}
              />
            </DialogContentText>
          </DialogContent>
        </Flip>
        <DialogActions>
          <button className="game-ok" onClick={joinGame}>Join</button>
        </DialogActions>
      </Dialog>
      {/* modals end */}

      {/* top title */}
      {
        (gameInfo.gameStatus === 'ready') ? (
          <Grid
            container
            spacing={3}
            justify="space-around"
            className={classes.header}
          >
            <Grid
              item
            >
              <h1>Waiting for Users</h1>
            </Grid>
          </Grid>
        ) : null
      }

      {/* start button */}
      {(gameInfo.gameStatus === 'ready' && gameInfo.ownerId === playerInfo.userId)
        ? playerInfo.currentNum
          ? <button className="game-ok next" onClick={StartGame}>Next</button> : <button className="game-ok start" onClick={StartGame}>Start</button>
        : null}
      {
        (gameInfo.gameStatus === 'ready' || gameInfo.gameStatus === 'mid') ? <Players gameContent={gameInfo} playersData={players} userInfo={playerInfo} /> : null
      }
      {/* game plaing */}

      {
        playerInfo.userStatus == 'playing' ? (
          <Grid
            container
            spacing={3}
            justify="space-around"
            className={classes.header}
          >
            <Grid
              item
            >
              <>
                <h1>{gameInfo?.gameContent ? gameInfo?.gameContent[playerInfo.currentNum].title : null}</h1>
                <img src={`${global.serverUrl}upload/${gameInfo?.gameContent ? gameInfo?.gameContent[playerInfo.currentNum].image : null}`} className="quiz-image" />
              </>
            </Grid>
          </Grid>
        ) : null
      }
      {
        playerInfo.userStatus == 'playing'
          ? (
            answerGraphic
          ) : null
      }

      {/* question number display */}
      {
        (typeof gameInfo.gameQuizNum != 'undefined')
          ? (
            (playerInfo.currentNum + 1 <= gameInfo.gameQuizNum)
              ? <div className="question-num-container">{`${playerInfo.currentNum + 1}/${gameInfo.gameQuizNum}`}</div> : null
          ) : null
      }

      {/* quztion time display */}
      {
        (gameInfo.gameStatus == 'playing' && playerInfo.userStatus == 'playing')
          ? <div className="timer">{`${downTime} s`}</div> : null
      }

      {/* mid status */}
      {
        (playerInfo.userId == gameInfo.ownerId && gameInfo.gameStatus == 'mid') ? <button className="game-ok next click-next" onClick={NextGame}><span className="santos">Next</span></button> : null
      }
      {
        (playerInfo.userId == gameInfo.ownerId && gameInfo.gameStatus == 'playing' && downTime == 0) ? <button className="game-ok next" onClick={toMidGame}>View Mid Result</button> : null
      }
      {
        (playerInfo.userId == gameInfo.ownerId && gameInfo.gameStatus == 'end') ? <button className="game-ok next" onClick={endGame}>End</button> : null
      }
      {
        playerInfo.userStatus == 'mid' ? answerResultView : null
      }

      {/* end game show */}
      {
        gameInfo.gameStatus == 'end'
          ? <EndGameSection game={gameInfo} playersInfo={players} userInfo={playerInfo} /> : null
      }
      <CountDown visible={visibleDownCounter} />
      <Connecting visible={visibleConnecting} />
    </Page>
  );
};

export default GamePanel;
