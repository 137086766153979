/*eslint-disable*/
import React, { Fragment } from 'react';
import styles from "./pricing.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Col, Container, Row, ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import { adminEditPlan, membershipUpgradeApi} from 'src/utils/Api';
import { Link as RouterLink, useNavigate, useParams, useLocation} from 'react-router-dom';
import Page from 'src/components/Page';
import authChecker from 'src/utils/authHelper';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import global from 'src/utils/global';
import cogoToast from 'cogo-toast';

const PriceSubscription = () => {
    const [subsData, setSubsData] = React.useState([])
    const navigate = useNavigate();
    const params = useParams();
    const stripePromise = loadStripe(global.stripePKey);
    const [profile, setProfile] = React.useState(JSON.parse(localStorage.getItem('brainaly_user')));
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!authChecker('authCheck')) {
            localStorage.setItem('brainaly_subscription', params.id)
            navigate('/signin', { replace: true });
            return;
          }
        adminEditPlan({ id: params.id }).then((res) => {
            console.log('subscription data', res[0])
            setSubsData(res[0])
        }).catch((err) => {
            console.log('catch', err)
        })
    }, [])
    const userBadgeCal = (score) => {
        if (score < 30) {
          return 'bronze';
        } if (score < 60) {
          return 'silver';
        }
        return 'gold';
      };
    const payNow = (info) => {
        console.log('payNow call pricesubscription', info);
        setLoading(true);
        const payInfo = {
          token: info.token,
          ...profile,
          plan_id:params.id,
          payType: subsData.plan_title,
          
        };
    
        console.log(payInfo, 'payInfooooo Data');
        membershipUpgradeApi(payInfo).then((res) => {
          console.log(res, 'recieve api data upgrade');
          const userData = {
            userId: res.userInfo.u_id,
            userEmail: res.userInfo.u_email,
            userName: res.userInfo.u_name,
            userAvatar: res.userInfo.u_avatar,
            user_birth: res.userInfo.u_birthday,
            userType: res.userInfo.u_type,
            userSchool: res.userInfo.u_school,
            userPhone: res.userInfo.u_phonenumber,
            userMembership: res.userInfo.u_membership_type,
            userMemberdate: res.userInfo.u_expire_date,
            userBadge: userBadgeCal(res.userInfo.u_score)
          };
          // console.log(setProfile(userData));
          setTimeout(() => {
            setProfile(userData);
            setLoading(false);
          }, 100);
          localStorage.setItem('brainaly_user', JSON.stringify(userData));
          localStorage.removeItem('brainaly_subscription');
          setProfile(userData, 'userProfile');
          cogoToast.success('Your membership is created successfully', { position: 'top-right' });
          if (res.userInfo.u_type === 'teacher') {
            navigate('/teacher/home', { replace: true });
          } else if (res.userInfo.u_type === 'student') {
            navigate('/student/home', { replace: true });
          } else {
          // navigate('/', { replace: true });
          }
        }).catch((err) => {
            console.log('catcherr', err)
          setTimeout(() => {
            setLoading(false);
          }, 100);
          cogoToast.warn('There was a error, Please try again or contact admin', { position: 'top-right' });
        });
      };
    
    return (
        <Page
            title="Subscription"
        >
            <Fragment >
                <section>
                    <div className='row w-100 m-0'>
                        <div className={`col-md-6 ${styles.pay_card1} pt-4`}>
                            <div className={`${styles.inner_pay_card1}`}>
                                <h1>Subscribe to Brainaly</h1>

                                <h2>${subsData.plan_amount} <span>/{subsData.plan_month} days</span></h2>
                              
                                <ul  className={`${styles.list_card } text-start p-0`}>
                                    <li className={`${styles.list_card_item }`}><span>✓</span>  {`${subsData.plan_quiz} Quizes`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.plan_classes} Classes`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.plan_collection} Collections`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.no_of_virtual_participant} Class Participants`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.class_duration} Class Duration in Minutes`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.no_of_recordings} Recordings in Minutes`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.data_transfer} Mb/s Data Trasfer`}</li>
                                    <li className={`${styles.list_card_item }`}><span>✓</span> {`${subsData.storage} GB Storage`}</li></ul>
                            </div>
                        </div>
                        <div className={`col-md-6 ${styles.pay_card2}`}>
                            <div className={`${styles.form_card}`}>
                                <h3 className='mb-5 text-start'>Pay with card</h3>

                                            <Elements stripe={stripePromise}  >
                                                <CheckoutForm payinfo={payNow} isLoading={loading} />
                                            </Elements>
                                <p>By confirming your subscription, you allow Brainaly.com to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.</p>
                            </div>
                        </div>
                    </div>
                </section>

            </Fragment>
        </Page>
    );
}

export default PriceSubscription;
